.portfolio {
    width: 100%;
}

.portfolio .header {
    padding-top: 0;
    position: relative;
}

.portfolio .header.bg-img {
    width: 100%;
    height: 50rem;
    background-image: url('../img//portfolio/banner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolio .header .carousel-header {
    position: absolute;
    top: 18%;
    width: 35%;
    height: 500px;
}

.portfolio .header .title {
    position: relative;
    top: 40%;
    padding-bottom: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolio .header .title h1 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 0rem;
    margin-bottom: 0.2rem;
    color: #535355;
}

.portfolio .header .title h1.span {
    color: #00add9;
}

@media (max-width: 992px) {
    .portfolio .header .title {
        margin-top: 0rem;
    }
}

@media (max-width: 767px) {
    .portfolio .header.bg-img {
        background-image: url('../img//portfolio/Banner-movil.png');
        height: 42rem;
    }

    .portfolio .header .carousel-header {
        top: 17%;
        width: 50%;
        height: 420px;
    }

    .portfolio .header .title {
        padding-top: 1rem;
    }

    .portfolio .header .title {
        margin-top: 0rem;
        text-align: center;
        padding-left: 2%;
        padding-right: 2%;
    }
}

/* feed animation fb */
.portfolio .feedFB {
    width: 100%;
    padding-bottom: 3rem;
}

.portfolio .feedFB .fade-in {
    opacity: 1;
    transition: all 0.1s ease-in !important;
}

.portfolio .feedFB .fade-in.fade-out {
    transition: all 0.1 ease-out !important;
    opacity: 0;
}

.portfolio .feedFB .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    padding-top: 3rem;
}

.portfolio .feedFB .title h1 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    color: #00add9;
}

@media (max-width: 992px) {
    .portfolio .feedFB {
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 3rem;
    }
}

@media (max-width: 767px) {}

/* slider */
.slider-social {
    padding-top: 0rem;
    padding-bottom: 5rem;
}

.slider-1 {
    padding-top: 1rem;
    margin-bottom: 2rem;
}

.slider-1 .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    padding-top: 0rem;
}

.slider-1 .title h1 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    color: #00add9;
    text-align: center;
}

.slider-1 .box {
    background-color: transparent;
    padding: 1rem;
}

.slider-1 .box img {
    width: 100%;
    height: auto;
    margin: 0 auto;
    cursor: pointer;
}

.slider-1 .slick-dots li button:before {
    font-size: 12px;
}

.slider-1 .slick-dots li.slick-active button:before {
    color: #00add9 !important;
}

.slider-1 .slick-prev:before,
.slider-1 .slick-next:before {
    color: #00add9 !important;
}

@media (max-width: 767px) {
    .slider-1 .title {
        padding-bottom: 1rem;
    }

    .slider-1 .box {
        padding: 0rem;
    }
}

/* slide social */
.slider-social {
    width: 100%;
}

.slider-social-bg {
    width: 100%;
    height: auto;
    background-color: #F7F9FB;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.slider-social .banner img {
    width: 50%;
    margin: 0 auto;
    height: auto;
}

.slider-social .thumbnail {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

.slider-social .slick-dots {
    position: absolute;
    bottom: -65px;
}

.slider-social .slick-thumb li {
    height: 55px;
    width: 100px;
}

.slider-social .slick-thumb li.slick-active img {
    filter: grayscale(0);
}

.slider-social .slick-thumb li img {
    max-width: 100%;
    height: auto;
    filter: grayscale(100%);
}

@media (max-width: 767px) {
    .slider-social .slick-thumb li {
        height: 55px;
        width: 50px;
    }

    .slider-social .slick-thumb li.slick-active img {
        filter: grayscale(0);
    }

    .slider-social .slick-thumb li img {
        max-width: 100%;
        height: auto;
        filter: grayscale(100%);
    }

    .slider-social .banner img {
        width: 100%;
    }
}

/* REELS */
.slider-reels {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 4rem;
}

.slider-reels-bg {
    background-color: #1B2D45;
    background-image: url('../img//portfolio/banner-reel.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

.slider-reels .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 4rem;
}

.slider-reels .title h1 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 0rem;
    margin-bottom: 0.2rem;
    color: #00add9;
}

.slider-reels .box {
    background-color: transparent;
    padding: 1rem;
    position: relative;
}

.slider-reels .box a img {
    width: 80%;
    height: auto;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
}

.slider-reels .box .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slider-reels .box .play i {
    font-weight: 100;
    font-size: 3rem;
    color: #00add9;
    transition: .3s;
}

.slider-reels .box a:hover i {
    transition: .3s;
    transform: scale(1.4);
}

.slider-reels .slick-dots li.slick-active button:before {
    color: #00add9 !important;
}

.slider-reels .slick-dots li button:before {
    font-size: 12px;
}

.slider-reels .slick-prev:before,
.slider-reels .slick-next:before {
    color: #00add9 !important;
}

/* video reel */
.video-reel {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.video-reel video {
    width: 65%;
    max-width: 100%;
    border-radius: 40px;
    border: 2px solid #ffffff
}

@media (max-width: 767px) {
    .video-reel video {
        width: 100%;
    }

    .slider-reels .title {
        padding-bottom: 1rem;
    }
}

/* Identity  */
.slider-identity {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.slider-identity-bg {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.slider-identity .box {
    width: 100%;
    padding-top: 1rem;
}

.slider-identity .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 3rem;
    padding-top: 0rem;
}

.slider-identity .title h1 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    color: #00add9;
}

.slider-identity .title p {
    color: #606060;
    font-family: Reboto-Ligth !important;
    font-size: 1rem !important;
    width: 80%;
    line-height: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.slider-identity .box img {
    width: 80%;
    height: auto;
}

.slider-identity .slick-dots li.slick-active button:before {
    color: #00add9 !important;
}

.slider-identity .slick-dots li button:before {
    font-size: 12px;
}

.slider-identity .slick-prev:before,
.slider-identity .slick-next:before {
    color: #00add9 !important;
}

@media (max-width: 992px) {}

@media (max-width: 767px) {
    .slider-identity .title p {
        width: 100%;
    }

    .slider-identity .box img {
        width: 100%;
    }

    .slider-identity .title p {
        text-align: justify;
    }

    .slider-identity .title {
        padding-bottom: 1rem;
    }

    .slider-identity .title h1 {
        text-align: center;
    }
}

.slider-portfolio-bg {
    width: 100%;
    height: auto;
    background-color: #F7F9FB;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

/* Identity  */
.slider-portfolio {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 3rem;
}

.slider-portfolio .box {
    width: 100%;
    padding-top: 0rem;
}

.slider-portfolio .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0rem;
    padding-top: 2rem;
    position: relative;
}

.slider-portfolio .title h1 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
    margin-bottom: 0rem;
    color: #00add9;
}

.slider-portfolio .title p {
    color: #606060;
    font-family: Reboto-Ligth !important;
    font-size: 1rem !important;
    width: 80%;
    line-height: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.slider-portfolio .box img {
    width: 88%;
    height: auto;
}

.slider-portfolio .slick-dots li.slick-active button:before {
    color: #00add9 !important;
}

.slider-portfolio .slick-dots li button:before {
    font-size: 12px;
}

.slider-portfolio .slick-prev:before,
.slider-portfolio .slick-next:before {
    color: #00add9 !important;
}

@media (max-width: 767px) {
    .slider-portfolio {
        width: 100%;
        padding-top: 0rem;
        padding-bottom: 1rem;
    }

    .slider-portfolio .box img {
        width: 100%;
        height: auto;
    }

    .slider-portfolio .title {
        padding-top: 1rem;
    }
}

/* @media (max-width: 992px) {}
@media (max-width: 767px) {} */