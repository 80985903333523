.epixpro {
	width: 100%;
	height: 35rem;
	background-color: #1F7D93;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-image: url('../img/epixpro/backgound.webp');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 90%;
}

.epixpro .view-app {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.epixpro .view-app .box-images {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.epixpro .view-app .box-images img {
	position: absolute;
	top: 0;
	left: 0;
}

.epixpro .description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	padding: 1rem;
}

.epixpro .description .logo {
	width: 50%;
}

.epixpro .description p {
	font-family: Montserrat;
	font-size: 1rem !important;
	color: #fff;
}

.epixpro .description p span {
	font-weight: 900;
}

.epixpro .description .title {
	padding-top: 0.1rem;
	padding-bottom: 1rem;
}

.epixpro .description button {
	background-color: #ff214f;
	margin: 1rem 0rem;
	height: 25px;
	font-size: 0.8rem;
	padding: 1.2rem 1.8rem;
	line-height: 0;
}

.epixpro .description .download-epixpro {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 1rem;
}

.epixpro .description .download-epixpro img {
	width: 30%;
	margin-right: 0.5rem;
	cursor: pointer;
}

.epixpro .description .download-epixpro p {
	font-size: 0.9rem !important;
}

@media (max-width: 992px) {}

@media (max-width: 768px) {
	.epixpro {
		width: 100%;
		height: 55rem;
	}

	.epixpro .view-app {
		margin-top: 2rem;
		height: 20rem;
	}

	.epixpro .description {
		padding: 0rem 0.5rem;
		height: 30rem;
	}

	.epixpro .description .logo {
		width: 65%;
		margin: 0 auto;
	}
}