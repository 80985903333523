.assistant {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 10;
}

.assistant .container {
    position: relative;
    top: 0;
    left: 0;
}

.assistant .btn-assistant {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.assistant .btn-assistant.disabled {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
}

.assistant .btn-assistant.enabled {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.assistant .btn-assistant button {
    background-color: initial;
    padding: 0;
    transition: transform .3s ease;
    border: none;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
    z-index: 9;
}

.assistant .btn-assistant button img {
    width: 100%;
    height: 100%;
}

.assistant .content-assistant {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 9;
    width: 30rem;
    height: 80dvh;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    display: block;
    overflow: hidden;
}

.assistant .content-assistant.enabled {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.assistant .content-assistant.disabled {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.assistant .content-assistant .title {
    background-color: #00acd8;
    width: 100%;
    height: 13%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.assistant .content-assistant .title .btn-back {
    background-color: initial;
    border: none;
}

.assistant .content-assistant .title .btn-back svg {
    color: white;
    stroke-width: 2;
    width: 2rem;
    height: 2rem;
}

.assistant .content-assistant .title h5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-bottom: 0;
}

.assistant .content-assistant .content-chat {
    width: 100%;
    height: 72%;
    overflow-y: scroll;
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background: #ffffff;
    padding: 1rem 1rem;
    background-color: #fff;
    background-image: url('../img//assistant/Fondo.webp');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Mensajes del usuario */
.assistant .content-assistant .content-chat .message.user-bot {
    align-self: flex-end;
    background: #61a4b2;
    color: white;
    padding: 8px 12px;
    border-radius: 10px;
    max-width: 70%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-family: Montserrat;
    font-size: 0.9rem !important;
    padding-bottom: 0.5rem;
    width: 100%;
}

.assistant .content-assistant .content-chat .message.user-bot audio {
    width: 100%;
}

.assistant .content-assistant .content-chat .message.user-bot:after,
.assistant .content-assistant .content-chat .message.user-bot:before {
    top: 0%;
    left: 100%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.assistant .content-assistant .content-chat .message.user-bot:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #61a4b2;
    border-width: 8px;
    margin-left: -8px;
}

/* Mensajes del asistente */
.assistant .content-assistant .content-chat .assistant-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    position: relative;
    background-color: transparent;
}

.assistant .content-assistant .content-chat .assistant-box img {
    width: 12%;
    height: auto;
    margin-right: 3%;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot {
    align-self: flex-start;
    background-color: #F5F6FB;
    color: black;
    padding: 8px 12px;
    border-radius: 10px;
    max-width: 80%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
}

.assistant .content-assistant .content-chat .assistant-bot audio {
    width: 100%;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot:after,
.assistant .content-assistant .content-chat .assistant-box .assistant-bot:before {
    top: 0%;
    right: 96%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #F5F6FB;
    border-width: 13px;
    margin-left: -13px;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot h1,
.assistant .content-assistant .content-chat .assistant-box .assistant-bot h2,
.assistant .content-assistant .content-chat .assistant-box .assistant-bot h3,
.assistant .content-assistant .content-chat .assistant-box .assistant-bot h4,
.assistant .content-assistant .content-chat .assistant-box .assistant-bot h5 {
    color: #00add9;
    text-transform: capitalize;
    font-family: Montserrat;
    font-size: 1rem !important;
    font-weight: 800;
    text-align: justify;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot strong {
    font-family: Montserrat-Semibold;
    font-size: 1rem;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot p {
    font-family: Montserrat;
    font-size: 0.9rem !important;
    padding-bottom: 0.5rem;
    color: #606060;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot a {
    color: #ff214f;
    cursor: pointer;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot p strong,
.assistant .content-assistant .content-chat .assistant-box .assistant-bot p span {
    color: #00add9;
    font-size: 0.9rem;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot ul {
    padding-bottom: 0.5rem;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot ul li {
    font-family: Montserrat;
    font-size: 0.9rem !important;
    padding-bottom: 0.5rem;
    color: #606060;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot ul li strong {
    color: hwb(0 14% 86%);
    font-size: 0.9rem;
}

.assistant .content-assistant .content-chat .assistant-box .assistant-bot ul li span {
    color: #00add9;
}

.assistant .content-assistant .loader-assistant {
    color: #000;
    font-family: Consolas, Menlo, Monaco, monospace;
    font-weight: bold;
    font-size: 30px;
    opacity: 0.8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.assistant .content-assistant .loader-assistant:before {
    content: "{";
    display: inline-block;
    animation: pulse 0.4s alternate infinite ease-in-out;
}

.assistant .content-assistant .loader-assistant:after {
    content: "}";
    display: inline-block;
    animation: pulse 0.4s 0.3s alternate infinite ease-in-out;
}

@keyframes pulse {
    to {
        transform: scale(0.8);
        opacity: 0.5;
    }
}

.assistant .content-assistant .input-message {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    background-color: #F5F6FB;
    z-index: 10;
}

.assistant .content-assistant .input-message input {
    background-color: white;
    border-radius: 10px;
    border: none;
    width: 70%;
    height: 60%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 0rem 0.5rem;
    font-family: Montserrat;
    font-size: 1rem !important;
    color: #606060;
}

.assistant .content-assistant .input-message .actions {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1011;
}

.assistant .content-assistant .input-message .actions .send-assistant {
    background-color: #00acd8;
    border-radius: 10px;
    border: none;
    padding: 0rem 0.5rem;
    height: 60%;
}

.assistant .content-assistant .input-message .actions .send-assistant svg {
    width: 2rem;
    fill: white;
}

.assistant .content-assistant .input-message .actions .send-audio {
    background-color: transparent;
    border-radius: 10px;
    border: none;
    padding: 0rem 0.5rem;
    height: 60%;
}

.assistant .content-assistant .input-message .actions .send-audio svg {
    width: 2rem;
    fill: #99a5b2;
}

.assistant .content-assistant .input-message .actions .send-audio svg.recording {
    width: 2rem;
    fill: red;
}

@media (max-width: 992px) {
    .assistant {
        width: 100%;
        right: 0;
        z-index: 10;
    }

    .assistant .content-assistant {
        width: 80%;
        height: 80dvh;
    }
}

@media (max-width: 768px) {
    .assistant .content-assistant {
        width: 100%;
        height: 80dvh;
    }
}