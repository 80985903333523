.features-product {
	width: 100%;
	height: 15rem;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.features-product .title {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.features-product .title img {
	position: absolute;
	bottom: -8%;
	left: 50%;
	width: 8%;
}

.features-product .title h2 {
	font-family: "Montserrat", sans-serif !important;
	color: #00add9 !important;
	text-transform: capitalize !important;
	font-size: 1.8rem;
	font-weight: 500;
	line-height: 54px;
}

.features-product p {
	padding-top: 1rem;
	margin: 0 auto;
	width: 80%;
	text-align: center;
	font-family: Montserrat;
	font-size: 1.1rem !important;
	color: #000;
}

.features-product p span {
	color: #00add9;
	font-weight: 600;
}

@media (max-width: 992px) {
	.features-product {
		width: 100%;
		height: 20rem;
	}
}

@media (max-width: 768px) {
	.features-product {
		width: 100%;
		height: 25rem;
	}

	.features-product .title img {
		width: 20%;
	}

	.features-product p {
		width: 100%;
	}
}