            /* Large Devices, Wide Screens :1200px
        ====================================*/
            html,
            body {
                margin: 0;
                padding: 0;
            }

            .plr-150 {
                padding-left: 150px;
                padding-right: 150px;
            }

            .mobi-align-img {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }

            .mobi-align-img img {
                width: 4rem;
            }

            @media screen and (max-width: 992px) {
                .phone {
                    position: relative !important;
                    top: 0 !important;
                    margin-bottom: -50% !important;
                    width: 20rem !important;
                    left: 80% !important;
                }

                .phone img {
                    margin-top: -50%;
                }

                #infoareaheading {
                    text-align: right;
                    margin-bottom: 0%;
                    margin-top: 15vw;
                }

                .app-dev-phone {
                    margin-top: 5% !important;
                }

                .mobi-align-img {
                    padding-top: 1rem !important;
                    padding-bottom: 0rem !important;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                }

                .mobi-align-img img {
                    width: 4rem;
                }

                .size-dev {
                    text-align: center;
                }

                #infoareaheading {
                    text-align: center !important;
                    margin-bottom: 0%;
                }

                #tpr-services-div {
                    width: 100% !important;
                    text-align: center !important;
                    margin: 0 auto !important;
                }

                #tpr-shape img {
                    padding-left: 60vh;
                }

                .rest-top {
                    margin-top: 0px !important;
                }

                .rest-top .RSBtn {
                    background-color: #ff214f;
                    width: 15vw;
                    padding: 0px;
                    margin-top: 5%;
                }

                .center-img-tpr {
                    display: flex !important;
                    align-items: center !important;
                    text-align: center !important;
                }

                .tpr-main-img {
                    width: 30vw;
                    text-align: center !important;
                    margin: 0 auto !important;
                }
            }

            @media screen and (max-width: 768px) {
                #relative-div {
                    width: 100vw !important;
                }

                .phone {
                    position: relative !important;
                    top: 0 !important;
                    margin-bottom: -50% !important;
                    width: 20rem !important;
                    left: 30% !important;
                }

                .phone img {
                    margin-top: -50%;
                }

                #infoareaheading {
                    text-align: center !important;
                    margin-bottom: 0%;
                    margin-top: 24vw !important;
                }

                #tpr-shape img {
                    padding-left: 20vh;
                }
            }

            @media screen and (max-width: 576px) {
                .phone {
                    position: relative !important;
                    top: 0 !important;
                    margin-bottom: -30% !important;
                    width: 20rem !important;
                    left: 10% !important;
                }

                .phone img {
                    margin-top: -50%;
                }

                #infoareaheading {
                    text-align: center !important;
                    margin-bottom: 0%;
                    margin-top: 35vw !important;
                }
            }

            @media (min-width: 1200px) and (max-width: 1500px) {

                .plr-260,
                .plr-150 {
                    padding-left: 60px;
                    padding-right: 60px;
                }

                .style1 .about-text {
                    margin-right: -40px;
                    padding-left: 20px;
                }

                .project-title h6 br {
                    display: none;
                }

                .project-details.pt-65 {
                    padding-top: 0;
                }

                .gridderGallery {
                    margin-right: 0;
                }

                .single-service .text {
                    padding: 60px 20px;
                }

                .style1.brand-logo {
                    margin: 0;
                }

                /* .style1.brand-logo ul>li {
        /*margin-right: 145px;*/
            }

            .portfolio-five .percent-25 {
                width: 33.33%;
            }

            .wrapper .side-menu {
                padding-left: 50px;
                width: 270px;
            }

            .wrapper .portfolio-five {
                padding-left: 270px;
            }

            .side-menu .mainmenu {
                line-height: 40px;
                padding: 140px 0 120px 0;
            }

            .side-menu .dropdown ul li:last-child .sub-menu {
                top: -150px;
            }

            .services-area.style2 .row {
                margin: 0 -15px;
            }

            .equal-style.height-600 {
                height: 480px;
            }

            .equal-style {
                padding: 0;
            }

            .contact-info-area .equal-style {
                padding: 0px;
                width: 100%;
            }

            .info-box.mb-135 {
                margin-bottom: 60px;
            }

            /* Normal desktop :992px
        ====================================*/
            @media (min-width: 992px) and (max-width: 1200px) {

                .plr-260,
                .plr-150 {
                    padding-left: 50px;
                    padding-right: 50px;
                }

                .mb-85 {
                    margin-bottom: 60px;
                }

                .style1 .about-text {
                    margin-right: -15px;
                    padding-left: 0px;
                    padding-top: 0;
                }

                .style2.slide-caption h1 br,
                .project-title h6 br,
                .about-text h1 br {
                    display: none;
                }

                .portfolio-item.percent-25 {
                    width: 33.33333%;
                }

                .gridder-padding {
                    padding: 100px 0;
                }

                .gridderGallery {
                    margin-right: 0;
                }

                .project-details.pt-65.pl-35 {
                    padding-top: 0;
                    padding-left: 0;
                }

                .gridder-grid .project-details p {
                    width: 350px;
                }

                .portfolio-three .grid-item {
                    margin-bottom: 30px;
                    padding: 0 15px;
                }

                .portfolio-three .portfolio-grid {
                    margin: 0 -15px -30px;
                }

                .single-service .text {
                    padding: 40px 10px;
                }

                .style1.brand-logo {
                    margin: 0;
                }

                /* .style1.brand-logo ul>li {
        /*margin-right: 95px;*/
            }

            .style1 .blog-post .content {
                padding: 30px 15px 25px 25px;
            }

            .portfolio-five .percent-25 {
                width: 33.33%;
            }

            .wrapper .side-menu {
                padding-left: 50px;
                width: 270px;
            }

            .wrapper .portfolio-five {
                padding-left: 270px;
            }

            .side-menu .mainmenu {
                line-height: 40px;
                padding: 140px 0 100px 0;
            }

            .side-menu .dropdown ul li:last-child .sub-menu {
                top: -150px;
            }

            /* Pages */
            .pagetitle.height-545 {
                height: 445px;
            }

            .services-area.style2 .row {
                margin: 0 -30px;
            }

            .services-area.style2 .single-service .text {
                padding: 15px;
            }

            .cta-area.section-padding {
                padding: 100px 0;
            }

            .client-logos {
                margin-left: -60px;
            }

            .testimonial-area .col-md-offset-5 {
                margin-left: 20.667%;
                width: 60%;
            }

            .equal-style.height-600 {
                height: 450px;
            }

            .equal-style {
                padding: 0;
            }

            .contact-info-area .equal-style {
                padding: 0;
                width: 100%;
            }

            .info-box.mb-135 {
                margin-bottom: 40px;
            }

            .info-box-contact p a,
            .info-box-contact p {
                font-size: 15px;
            }

            .sidebar.pl-50 {
                padding-left: 0;
            }

            .list-blog .blog-post .content {
                margin-left: -25px;
                padding-top: 0;
            }

            .blog-post.on-bg .content {
                height: 140%;
            }

            .column-3.wide .hover-style2 .project-title {
                left: 40px;
            }

            .column-5.wide .percent-20 {
                width: 25%;
            }

            .project-details .skill-progress {
                width: 350px;
            }

            .standard .project-details .experience {
                margin-right: -85px;
            }

            .on-side .project-details .experience {
                padding-top: 0;
            }

            .project-details.pl-20 {
                padding-left: 0;
            }

            @media only screen and (min-width: 992px) {
                .services-area .row.flex-center {
                    display: flex;
                    justify-content: center;
                }
            }

            /* Tablet desktop :768px
        ===================================*/
            @media (min-width: 768px) and (max-width: 991px) {
                .section-padding {
                    padding: 120px 0;
                }

                .section-pt {
                    padding-top: 120px;
                }

                .section-pb {
                    padding-bottom: 120px;
                }

                .plr-260,
                .plr-150 {
                    padding-left: 50px;
                    padding-right: 50px;
                }

                .ptb-200 {
                    padding-bottom: 120px;
                    padding-top: 120px;
                }

                .tab-mb-30 {
                    margin-bottom: 30px;
                }

                .tab-mb-50 {
                    margin-bottom: 50px;
                }

                .style2.slide-caption h1 br,
                p br {
                    display: none;
                }

                .text-center .heading>p br {
                    display: block;
                }

                .tablet.row.pb-80 {
                    padding-bottom: 0;
                }

                .navbar-header {
                    float: none;
                }

                .navbar-toggle {
                    display: block;
                }

                .navbar-collapse.collapse {
                    /*   display: none !important; */
                }

                .navbar-collapse.collapse.in {
                    display: block !important;
                }

                .collapsing {
                    overflow: hidden !important;
                }

                .navbar-toggle {
                    top: 30px;
                    right: 60px;
                    z-index: 9999;
                }

                /* 
                .mainmenu .navbar-collapse {
                           background: #000000 none repeat scroll 0 0;
        display: block;
        opacity: 1;
        padding: 10px 0;
        position: absolute;
        right: -15px;
        top: 110%;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        visibility: visible;
        width: 290px !important;
        padding-bottom: 2rem;
                }
 */
                .is-sticky .mainmenu .navbar-collapse {
                    top: 57px;
                }

                #active-sticky.is-sticky {
                    padding: 0;
                }

                .is-sticky .navbar-toggle {
                    top: 15px;
                }

                .style1 .about-text {
                    margin-right: 0;
                    padding-left: 0;
                }

                .portfolio-item.percent-25 {
                    width: 50%;
                }

                .gridder-list.percent-25 {
                    width: 33.33333%;
                }

                .gridder-padding {
                    padding: 80px 0;
                }

                .gridderGallery {
                    margin-right: 0;
                }

                .gridder-grid .project-details p {
                    width: auto;
                }

                .project-details.pt-65 {
                    padding-top: 40px;
                }

                .portfolio-menu .mb-70 {
                    margin-bottom: 40px;
                }

                .portfolio-three .grid-item {
                    margin-bottom: 20px;
                    padding: 0 10px;
                }

                .portfolio-three .portfolio-grid {
                    margin: 0 -10px -20px;
                }

                .single-facts .icon {
                    margin-right: 10px;
                    font-size: 50px;
                }

                .single-facts .text>h1 {
                    font-size: 36px;
                }

                .style1.brand-logo {
                    margin: 0;
                }

                .style1.brand-logo ul>li {
                    margin-right: 40px;
                }

                .style4.slide-caption h1 {
                    font-size: 80px;
                    line-height: 90px;
                }

                .portfolio-five .percent-25 {
                    width: 50%;
                }

                .portfolio-five .grid-item {
                    margin-bottom: 30px;
                }

                /* Pages */
                .pagetitle.height-545 {
                    height: 345px;
                }

                .my-info.pt-80 {
                    padding-top: 0;
                }

                .services-area.style2 .row {
                    margin: 0 -15px;
                }

                .client-logos {
                    margin-left: 0;
                    margin-top: 30px;
                }

                .equal-style {
                    padding: 100px 30px;
                }

                .info-box p br {
                    display: block;
                }

                .sidebar .widget {
                    padding: 30px;
                }

                .image-blog .blog-post .content {
                    bottom: 20px;
                    left: 20px;
                }

                .image-blog .blog-post .content h4 {
                    font-size: 16px;
                }

                .blog-post .content h4 br {
                    display: none;
                }

                .column-5.wide .percent-20 {
                    width: 33.33333%;
                }

                .project-details .skill-progress {
                    width: 100%;
                }

                .thumb.mb-90 {
                    margin-bottom: 60px;
                }

                .portfolio-details .project-info li {
                    margin-left: 35px;
                    padding-left: 35px;
                }

                .project-info.mb-90.mt-90 {
                    margin: 50px 0;
                }
            }

            @media (min-width: 768px) {
                .blog-post .post-title {
                    display: flex;
                    align-items: flex-end;
                }

                .services-area .brand-logo ul {
                    justify-content: center;
                    display: flex;
                }
            }

            @media (max-width: 479px) {
                body#blog .blog-area .blog-post .content {
                    padding: 0px 20px 20px 20px;
                }
            }

            /* Small Mobile :320px.
        ====================================*/
            @media (max-width: 767px) {
                img.logos {
                    max-width: 100%;
                }

                .container {
                    width: 330px;
                }

                .mobi-mb-30 {
                    margin-bottom: 30px;
                }

                .mobi-mb-50 {
                    margin-bottom: 50px;
                }

                .pl-60 {
                    padding-left: 25px;
                }

                h1>br,
                h2>br,
                .project-title h6 br,
                .about-text h1 br,
                p>br {
                    display: none;
                }

                p {
                    font-size: 16px;
                    line-height: 30px;
                }

                .heading>h3 {
                    font-size: 20px;
                    margin-bottom: 8px;
                }

                .heading>h1 {
                    font-size: 25px;
                }

                .style1 .about-text {
                    margin-right: 0;
                    padding-left: 0;
                }

                .about-text h1 {
                    line-height: 40px;
                }

                .about-text.pl-30 {
                    padding-left: 0;
                }

                .about-area .heading>div.shape {
                    display: none;
                }

                .slick-dots {
                    margin-top: 30px;
                }

                .testimonial .item>p {
                    font-size: 20px;
                    line-height: 35px;
                }

                .team-member h3 {
                    font-size: 24px;
                    margin-top: 20px;
                }

                .social-link li {
                    margin-right: 10px;
                    float: none;
                }

                footer .social-link {
                    text-align: left;
                }

                .copyright.text-right {
                    text-align: center;
                    margin-top: 15px;
                }

                .portfolio-three .grid-item {
                    margin-bottom: 30px;
                }

                .portfolio-three .portfolio-grid {
                    margin-bottom: -30px;
                }

                #four-item .slick-dots {
                    margin-top: 30px;
                }

                #four-item .slick-list {
                    margin: 0 -5px;
                }

                #four-item .slick-slide {
                    margin: 0 5px;
                }

                .single-facts .text>h1 {
                    font-size: 36px;
                }

                .style1.brand-logo {
                    overflow: hidden;
                    margin: 0 0 -20px 0;
                }

                .style1.brand-logo ul>li {
                    height: auto;
                    margin-bottom: 20px;
                    margin-right: 0px;
                    text-align: center;
                    width: 50%;
                }

                .my-info.pt-80 {
                    padding-top: 0;
                }

                .my-info>h1 {
                    line-height: 40px;
                }

                .skill-progress {
                    width: 100%;
                }

                .services-area.style2 .row {
                    margin: 0 -15px;
                }

                .clients-text h2 {
                    line-height: 38px;
                    font-size: 26px;
                }

                .client-logos {
                    margin-left: 0;
                }

                .client-logos>ul {
                    padding: 0px 0;
                }

                .testimonial-box.style2 .item>p {
                    font-size: 16px;
                    line-height: 28px;
                }

                .testimonial-box .item {
                    padding: 30px 20px 0 20px;
                }

                .client-info .name {
                    padding-left: 10px;
                }

                .style2 .client-info .name h4 {
                    font-size: 18px;
                }

                .cta-text>h1 {
                    line-height: 38px;
                    margin-bottom: 30px;
                }

                .style2 .team-member .thumb {
                    margin-bottom: 20px;
                }

                .custom-tab .nav-tabs>li>a {
                    font-size: 13px;
                }

                .equal-style {
                    padding: 90px 0;
                }

                .featured-service .thumb {
                    display: inline-block;
                    margin-bottom: 20px;
                    width: 100%;
                }

                .featured-service .thumb img {
                    width: 100%;
                }

                .custom-input input,
                .custom-input textarea {
                    margin-bottom: 15px;
                }

                .standard .blog-post .content h4 {
                    font-size: 22px;
                }

                .audio-post.mb-40,
                .blog-post .mb-50 {
                    margin-bottom: 30px;
                }

                .sidebar .widget {
                    padding: 30px 20px;
                }

                .tag-share .pull-left,
                .tag-share .pull-right {
                    float: none !important;
                }

                .tag-share .pull-right>strong {
                    float: left !important;
                }

                .commenter-img,
                .admin-img {
                    float: none !important;
                    margin-bottom: 15px;
                }

                .comment-list li.reply {
                    margin-left: 25px;
                }

                .carusel-type .single-portfolio {
                    margin: 0 5px;
                }

                .project-details .thumb.mb-90 {
                    margin-bottom: 30px;
                }

                .portfolio-details .project-details h1 {
                    font-size: 26px;
                    margin-bottom: 10px;
                }

                .portfolio-details .project-details p {
                    font-size: 16px;
                    line-height: 28px;
                }

                .portfolio-details .project-info li {
                    border-left: 0;
                    float: none;
                    margin-bottom: 25px;
                    margin-left: 0;
                    padding-left: 0;
                }

                .project-info.mb-90.mt-90 {
                    margin: 30px 0 10px 0;
                }

                .blog-area .blog-post .post-title .title-date {
                    display: block;
                }

                .blog-area .blog-post .post-title .title-date h5 {
                    display: inline-block;
                    vertical-align: middle;
                }

                .blog-area .blog-post .post-title .title-date h5:nth-child(1) {
                    border-right: 1px solid #dddddd;
                    padding-right: 10px;
                    margin-right: 5px;
                }
            }

            @media only screen and (min-width: 480px) and (max-width: 767px) {
                .container {
                    width: 480px;
                }

                .percent-66,
                .percent-33,
                .percent-25 {
                    width: 50%;
                }

                .portfolio-area .portfolio-grid {
                    margin: 0 -7px -15px;
                }

                .portfolio-area .portfolio-item {
                    margin-bottom: 15px;
                    padding: 0 7px;
                }

                .project-title {
                    bottom: 25px;
                    left: 20px;
                }

                .portfolio-three .grid-item {
                    margin-bottom: 20px;
                    padding: 0 10px;
                }

                .portfolio-three .portfolio-grid {
                    margin: 0 -10px -20px;
                }

                .fun-facts-area.style1 .col-xs-12 {
                    width: 50%;
                }

                .fun-facts-area.style1 .mobi-mb-30:nth-child(3) {
                    margin-bottom: 0;
                }

                .blog-post .thumb img {
                    width: 100%;
                }

                .portfolio-five .portfolio-grid {
                    margin: 0;
                }

                .portfolio-five .grid-item {
                    padding: 0 7px;
                }

                .custom-tab .nav-tabs>li>a {
                    font-size: 16px;
                }

                .info-box p br {
                    display: block;
                }

                .blog-post p.hidden-xs {
                    display: block !important;
                }

                .gridder-type .gridder-show .plr-50 {
                    padding: 0 35px;
                }

                .column-5.wide .percent-20,
                .portfolio-area.column-4.wide .col-xs-12,
                .portfolio-area.column-4 .col-xs-12,
                .pinterest-type .col-xs-12 {
                    width: 50%;
                }
            }

            /* Carlos Michel Changes Below */
            @media only screen and (max-device-width: 500px) and (orientation: portrait) {

                /* * {
                    background: #000 !important;
                    color: #0f0 !important;
                    outline: solid #f00 1px !important;
                } */
                .bg-img-21 {
                    background-image: url("../img/app-development/Banner-Web-Services.webp");
                    background-size: 100vh;
                    background-attachment: scroll;
                    background-repeat: no-repeat;
                    background-position: 50% 100%;
                }

                .slider-area.height-100 {
                    height: 50vh;
                }

                #awd-slider.slider-area.height-100 {
                    height: 40vh;
                }

                button.navbar-toggle {
                    margin-right: 0px;
                }

                .phone {
                    position: relative;
                    top: -45rem;
                    margin-bottom: -30rem;
                    width: 60vw;
                    left: 20vw;
                }

                .fix {
                    overflow: hidden;
                }

                .equal-style {
                    padding: 0;
                }

                .row-eq-height {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: block;
                }

                #infoareaheading {
                    margin-top: 200px;
                }

                .heading>div.shape {
                    margin: auto;
                    width: 50%;
                    display: block !important;
                    position: absolute;
                    padding: 35px;
                    height: 10px;
                    left: 0px;
                    z-index: -1;
                }

                div#resinfoarea {
                    padding: 0;
                    width: 100%;
                    height: 75%;
                }

                .heading>h3 {
                    font-family: "Montserrat", sans-serif;
                    text-transform: capitalize;
                    text-align: center;
                    margin-bottom: 0;
                }

                .awdiv>p {
                    font-size: xx-small;
                    margin-top: 50px;
                }

                .info-box {
                    margin-left: 35%;
                    text-align: center;
                }

                .info-box>img {
                    margin-top: 0%;
                    display: flex;
                    flex-direction: row;
                    float: left;
                    margin-left: 5%;
                    width: 15%;
                }

                #infoareaheading>hr {
                    width: 60% !important;
                    border-top: 1px solid #606060 !important;
                }

                #row-flex {
                    display: flex;
                    flex-direction: column-reverse;
                }

                .flex-hybrid {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .d-table-changes {
                    width: 100%;
                    height: 100%;
                    padding-right: 90px;
                }

                #resinforight {
                    margin-top: 10%;
                }

                #infodiv-landscape {
                    padding-left: 0vw;
                    margin-bottom: 3vh;
                }

                #info-div {
                    margin-left: 0px;
                    padding-left: 0;
                    margin-left: 0;
                }

                #info-div {
                    display: inline-block;
                    vertical-align: top;
                    padding-left: 1.2em;
                    margin-right: 0px;
                    overflow-wrap: break-word;
                }

                #mobi-mb-25 {
                    margin-bottom: 0vw;
                    margin-left: 0em;
                }

                #mobi-align-image {
                    display: inline-block;
                    align-self: center;
                    padding-right: 0px;
                    width: auto;
                    padding-left: 0;
                }

                .text-mobi {
                    display: block;
                    text-align: center;
                }

                .text-mobi img {
                    width: 5rem !important;
                    padding-bottom: 1rem;
                }

                .sub-img {
                    float: left;
                    width: auto;
                    height: auto;
                    font-weight: bold;
                }

                #sub-description {
                    padding: 25px 50px 25px 50px;
                }

                .RSBtn-div {
                    display: none;
                }

                .image.relative>img {
                    position: relative;
                    float: left;
                }

                .text-relative {
                    position: relative;
                }

                #awd-sp.section-padding {
                    padding: 75px 0px 0px 0px;
                }

                .container {
                    padding-left: 0px;
                    padding-right: 0px;
                    padding-top: 0px;
                }

                div.featured-areaawd {
                    text-align: center;
                    padding: 50px;
                    position: relative;
                }

                .shape-awd {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                #about-areapad {
                    padding-top: 5% !important;
                    margin-bottom: 10% !important;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 50% !important;
                }

                .awd {
                    overflow-x: hidden;
                }
            }

            @media only screen and (max-device-height:731px) and (max-device-width:414px) {
                .bg-img-21 {
                    background-image: url("../img/app-development/Banner-Web-Services.webp");
                    background-size: 100vh;
                    background-attachment: scroll;
                    background-repeat: no-repeat;
                    background-position: 55% 40%;
                }

                .slider-area.height-100 {
                    height: 31vh;
                }

                button.navbar-toggle {
                    margin-right: 0px;
                }

                .phone {
                    position: relative;
                    top: -70rem;
                    margin-bottom: -30rem;
                    width: 60vw;
                    left: 20vw;
                }

                .fix {
                    overflow: hidden;
                }

                .equal-style {
                    padding: 0;
                }

                .row-eq-height {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: block;
                }

                #infoareaheading {
                    margin-top: 200px;
                }

                .heading>div.shape {
                    margin: auto;
                    width: 50%;
                    display: block !important;
                    position: absolute;
                    padding: 35px;
                    height: 10px;
                    left: 0px;
                    z-index: -1;
                }

                div#resinfoarea {
                    padding: 0;
                    width: 100%;
                    height: 75%;
                }

                .heading>h3 {
                    font-family: "Montserrat", sans-serif;
                    text-transform: capitalize;
                    text-align: center;
                    margin-bottom: 0;
                }

                .awdiv>p {
                    font-size: xx-small;
                    margin-top: 50px;
                }

                .info-box {
                    margin-left: 35%;
                    text-align: center;
                }

                .info-box>img {
                    margin-top: 0%;
                    display: flex;
                    flex-direction: row;
                    float: left;
                    margin-left: 5%;
                    width: 15%;
                }

                #infoareaheading>hr {
                    width: 60% !important;
                    border-top: 1px solid #606060 !important;
                }

                #row-flex {
                    display: flex;
                    flex-direction: column-reverse;
                }

                .flex-hybrid {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .d-table-changes {
                    width: 100%;
                    height: 100%;
                    padding-right: 90px;
                }

                #resinforight {
                    margin-top: 10%;
                }

                #infodiv-landscape {
                    padding-left: 20vw;
                    margin-bottom: 5vh;
                }

                #info-div {
                    margin-left: 0px;
                    padding-left: 0;
                    margin-left: 0;
                }

                #info-div {
                    display: inline-block;
                    vertical-align: top;
                    padding-left: 1.2em;
                    margin-right: 0px;
                    overflow-wrap: break-word;
                }

                #mobi-mb-25 {
                    margin-bottom: 0vw;
                    margin-left: 0em;
                }

                #mobi-align-image {
                    display: inline-block;
                    align-self: center;
                    padding-right: 0px;
                    width: auto;
                    padding-left: 0;
                }

                .sub-img {
                    float: left;
                    width: auto;
                    height: 25px;
                    font-weight: bold;
                }

                #sub-description {
                    padding: 25px 50px 25px 50px;
                }

                .RSBtn-div {
                    display: none;
                }

                .image.relative>img {
                    position: relative;
                    float: left;
                }

                .text-relative {
                    position: relative;
                }

                #awd-sp.section-padding {
                    padding: 75px 0px 0px 0px;
                }

                .container {
                    padding-left: 0px;
                    padding-right: 0px;
                    padding-top: 0px;
                }

                div.featured-areaawd {
                    text-align: center;
                    padding: 50px;
                    position: relative;
                }

                #about-areapad {
                    padding-top: 5% !important;
                    margin-bottom: 10% !important;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 50%;
                    padding: 0px;
                    display: none;
                    /* margin-top: 10%; */
                }

                .awd {
                    overflow-x: hidden;
                }
            }

            @media only screen and (min-width:500px) and (max-width: 700px) {
                .bg-img-21 {
                    background-image: url("../img/app-development/Banner-Web-Services.webp");
                    /* background-size: vh; */
                    background-attachment: scroll;
                    background-repeat: no-repeat;
                    background-position: -25vh 0vh;
                }

                #awd-slider {
                    height: 51vh;
                }

                button.navbar-toggle {
                    margin-right: 0px;
                }

                .phone {
                    position: relative;
                    top: -60rem;
                    margin-bottom: -30rem;
                    width: 20rem;
                    left: 20vw;
                }

                .fix {
                    overflow: hidden;
                }

                .equal-style {
                    padding: 0;
                }

                .row-eq-height {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: block;
                }

                #infoareaheading {
                    margin-top: 30vw;
                }

                #infoareaheading>hr {
                    width: 70vw;
                }

                .heading>div.shape {
                    margin: auto;
                    width: 30%;
                    display: block !important;
                    position: absolute;
                    padding: 35px;
                    height: 10px;
                    left: 0px;
                    z-index: -1;
                }

                div#resinfoarea {
                    padding: 10vw;
                    width: 100%;
                    height: 75%;
                    margin-bottom: 7%;
                    margin-top: 7%;
                    overflow-x: hidden;
                }

                .heading>h3 {
                    font-family: "Montserrat", sans-serif;
                    text-transform: capitalize;
                    text-align: center;
                }

                .awdiv>p {
                    font-size: xx-small;
                    margin-top: 50px;
                }

                .info-box {
                    margin-left: 28%;
                    text-align: center;
                    padding-right: 5%;
                }

                .info-box>img {
                    margin-top: 0%;
                    display: flex;
                    flex-direction: row;
                    float: left;
                    margin-left: 15%;
                }

                #row-flex {
                    display: flex;
                    flex-direction: row;
                }

                .d-table {
                    width: 100%;
                    height: 100%;
                    padding-right: 90px;
                }

                #info-div {
                    margin-left: 0px;
                    padding-left: 0;
                    margin-left: 0;
                }

                #info-div {
                    display: inline-block;
                    vertical-align: middle;
                    padding-left: 0px;
                }

                #mobi-mb-25 {
                    margin-bottom: 0vw;
                    margin-left: 1.5em;
                }

                .text-mobi {
                    display: block;
                    text-align: center;
                }

                .text-mobi img {
                    width: 5rem !important;
                    padding-bottom: 1rem;
                }

                #mobi-align-image {
                    display: inline-block;
                    align-self: center;
                    padding-right: 0px;
                    width: auto;
                }

                .sub-img {
                    float: left;
                    width: 100%;
                    height: auto;
                    font-weight: bold;
                }

                #sub-description {
                    padding: 50px 25px 50px 75px;
                }

                .image.relative>img {
                    position: relative;
                    float: left;
                }

                .text-relative {
                    position: relative;
                }

                .flex-hybrid {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                #no-pad {
                    padding-left: 0%;
                    padding-right: 0%;
                    margin-left: 0% !important;
                    margin-right: 0% !important;
                }

                #text-relativebox>.shape-awd {
                    display: flex;
                    justify-content: center;
                    margin-top: 1em;
                }

                #text-relativebox>.shape>img {
                    width: 4em;
                }

                #text-relativebox>hr {
                    width: 10vw;
                    border-top: 1px solid #00acd8;
                }

                #text-relativebox>h4 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 2em !important;
                    font-weight: 600;
                    color: #606060;
                    margin-bottom: 5%;
                    text-align: center;
                    margin-top: 5%;
                }

                #text-relativebox>p {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.2em !important;
                    color: #606060;
                    font-weight: 400;
                    text-align: center;
                }

                #awd-sp {
                    padding: 0;
                }

                .container {
                    padding-left: 0px;
                    padding-right: 0px;
                    padding-top: 0px;
                }

                #awd div.featured-area {
                    text-align: center;
                    padding: 50px;
                    position: relative;
                }

                #about-areapad {
                    padding-top: 5% !important;
                    margin-bottom: 10% !important;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 25%;
                    padding: 0px;
                    margin-top: 7%;
                    margin-bottom: 7%;
                }

                html,
                body {
                    overflow-x: hidden;
                }
            }

            @media only screen and (min-width:701px) and (max-width: 900px) {
                .bg-img-21 {
                    background-image: url("../img/app-development/Banner-Web-Services.webp");
                    background-size: 100vw;
                    background-attachment: scroll;
                    background-repeat: no-repeat;
                    background-position: 100% 100%;
                }

                #awd-slider {
                    height: 40vh;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 15vw;
                    padding: 0px;
                    margin-top: 10vh;
                }

                #relative-div {
                    width: 40vw;
                }

                /* .navmenubar {
        transition: background 0.4s ease-in-out !important;
        }

        .is-hidden {
        opacity: 0;
        transition: transform 0.4s, opacity 0.2s;
        }
        .is-visable {
        opacity: 1;
        transition: transform 0.4s, opacity 0.2s;
        }
        .navmenubar-hide {
        pointer-events: none;
        opacity: 0;
        } */
                button.navbar-toggle {
                    margin-right: 0px;
                }

                .phone {
                    position: relative;
                    top: -15rem;
                    margin-bottom: 1rem;
                    width: 100%;
                    left: -5vw;
                }

                .mt-100 {
                    margin-top: 50px;
                }

                .fix {
                    overflow: hidden;
                }

                .equal-style {
                    padding: 0;
                }

                .row-eq-height {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: block;
                }

                #infoareaheading {
                    margin-top: 15vw;
                }

                #infoareaheading>hr {
                    width: 70vw;
                }

                .heading>div.shape {
                    margin: auto;
                    width: 30%;
                    display: block !important;
                    position: absolute;
                    padding: 35px;
                    height: 10px;
                    left: 0px;
                    z-index: -1;
                }

                div#resinfoarea {
                    padding: 10vw;
                    width: 100%;
                    height: 75%;
                    margin-bottom: 7%;
                    margin-top: 7%;
                    overflow-x: hidden;
                }

                #awd-sp {
                    padding: 0;
                }

                #no-pad.col-sm-6 {
                    padding: 0;
                    width: 100%;
                }

                .heading>h3 {
                    font-family: "Montserrat", sans-serif;
                    text-transform: capitalize;
                    text-align: center;
                }

                .awdiv>p {
                    font-size: xx-small;
                    margin-top: 50px;
                }

                .info-box {
                    margin-left: 28%;
                    text-align: center;
                    padding-right: 5%;
                }

                .info-box>img {
                    margin-top: 0%;
                    display: flex;
                    flex-direction: row;
                    float: left;
                    margin-left: 15%;
                    width: 3rem;
                }

                #row-flex {
                    display: flex;
                    flex-direction: row;
                }

                .d-table {
                    width: 100%;
                    height: 100%;
                    padding-right: 90px;
                }

                #infodiv-landscape {
                    padding-left: 0;
                    padding-right: 0;
                    margin-top: 1em;
                }

                #info-div {
                    margin-left: 0px;
                    padding-left: 0;
                    margin-left: 0;
                }

                #info-div {
                    display: inline-block;
                    vertical-align: middle;
                    padding-left: 10vw;
                }

                #info-div>p {
                    padding-right: 10%;
                }

                #mobi-mb-25 {
                    margin-bottom: 0vw;
                    margin-left: 1.5em;
                }

                #mobi-align-image {
                    display: inline-block;
                    align-self: center;
                    padding-right: 0px;
                    margin-left: 30px;
                    width: auto;
                }

                .sub-img {
                    float: left;
                    width: auto;
                    height: 55px;
                    font-weight: bold;
                }

                #sub-description {
                    padding: 50px 25px 50px 75px;
                }

                .image.relative>img {
                    position: relative;
                    float: left;
                }

                .text-relative {
                    position: relative;
                }

                .flex-hybrid {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                #no-pad {
                    padding-left: 0%;
                    padding-right: 0%;
                    margin-left: 0% !important;
                    margin-right: 0% !important;
                }

                #text-relativebox>.shape-awd {
                    display: flex;
                    justify-content: center;
                    margin-top: 2em;
                }

                #text-relativebox>.shape>img {
                    width: 4em;
                }

                #text-relativebox>hr {
                    width: 10vw;
                    border-top: 1px solid #00acd8;
                }

                #text-relativebox>h4 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 2em !important;
                    font-weight: 600;
                    color: #606060;
                    margin-bottom: 5%;
                    text-align: center;
                    margin-top: 5%;
                }

                #text-relativebox>p {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.2em !important;
                    color: #606060;
                    font-weight: 400;
                    text-align: center;
                }

                .section-padding {
                    padding: 75px 50px 0px 20px;
                }

                .container {
                    padding-left: 0px;
                    padding-right: 0px;
                    padding-top: 0px;
                }

                #awd div.featured-area {
                    text-align: center;
                    padding: 50px;
                    position: relative;
                }

                #about-areapad {
                    padding-top: 5% !important;
                    margin-bottom: 10% !important;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 20%;
                    padding: 0px;
                    margin-top: 0%;
                    margin-bottom: 7%;
                }

                html,
                body {
                    overflow-x: hidden;
                }
            }

            @media only screen and (min-width:901px) and (max-width: 1200px) {
                .bg-img-21 {
                    background-image: url("../img/app-development/Banner-Web-Services.webp");
                    background-size: 100vw;
                    background-attachment: scroll;
                    background-repeat: no-repeat;
                    background-position: 100% 100%;
                }

                #awd-slider {
                    height: 40vh;
                }

                .phone {
                    position: relative;
                    top: -5rem;
                    margin-bottom: -40rem;
                    width: 100%;
                    right: 0;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 15vw;
                    padding: 0px;
                    margin-top: 20%;
                }

                .ui .RSBtn {
                    background-color: #ff214f;
                    width: 50%;
                    padding: 0px;
                    margin-top: 0rem;
                    margin-bottom: 3%;
                }

                #resinfoarea {
                    margin-top: 25%;
                    margin-left: auto;
                    padding-right: 5%;
                    padding-left: 0%;
                    display: flex;
                    justify-content: space-between;
                    vertical-align: middle;
                }

                #resinforight {
                    margin-top: 10%;
                    margin-left: auto;
                    padding-left: 0;
                    display: flex;
                    justify-content: space-between;
                }

                #info-div>br {
                    display: none;
                }

                #info-div>hr {
                    display: none;
                }

                #margin-control {
                    margin-left: 0%;
                    margin-right: 0%;
                }

                #infodiv-landscape {
                    margin-bottom: 0;
                }

                .heading>div.shape {
                    margin: auto;
                    width: 90%;
                    display: block !important;
                    position: absolute;
                    padding: 7%;
                    height: 20%;
                    left: 16%;
                    z-index: -1;
                }

                .info-box {
                    margin-left: 3%;
                    padding-right: 0.5em;
                    text-align: right;
                    padding-left: 30%;
                    margin-top: 0%;
                }

                .info-box>img {
                    margin-left: 0%;
                    display: flex;
                    flex-direction: row;
                    float: left;
                    margin-left: 6%;
                    width: 3rem;
                }

                #infoareaheading {
                    text-align: right;
                    margin-bottom: 0%;
                }

                .heading>h1 {
                    margin-bottom: 10%;
                    font-family: "Montserrat", sans-serif;
                    text-transform: capitalize !important;
                    font-size: 2.13em;
                    font-weight: 800;
                }

                .heading>h3 {
                    font-family: "Montserrat", sans-serif;
                    text-transform: capitalize !important;
                    font-size: 2.13em;
                    font-weight: 400;
                }

                p {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.1em !important;
                    color: #606060;
                    font-weight: 300;
                }

                #no-pad {
                    padding-left: 0%;
                    padding-right: 0%;
                    margin-left: 0% !important;
                    margin-right: 0% !important;
                }

                #text-relativebox>.shape-awd {
                    display: flex;
                    justify-content: center;
                    margin-top: 10%;
                }

                #text-relativebox>hr {
                    width: 10%;
                    border-top: 1px solid #00acd8;
                }

                #text-relativebox>h4 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 2em !important;
                    font-weight: 600;
                    color: #606060;
                    margin-bottom: 5%;
                    text-align: center;
                    margin-top: 5%;
                }

                #text-relativebox>p {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1em !important;
                    color: #606060;
                    font-weight: 400;
                    text-align: center;
                    padding-left: 5vw;
                    padding-right: 5vw;
                    line-height: normal;
                }

                #awd div.featured-area {
                    padding: 100px 100px;
                    position: absolute;
                    text-align: center;
                    padding-bottom: 250px;
                }

                .about-text {
                    margin-bottom: 10%;
                    margin-top: 5%;
                }

                .about-text>h1.theme-color {
                    font-weight: 400;
                    text-transform: none;
                }
            }

            @media only screen and (min-width: 902px) and (max-width: 1111px) {
                #text-relativebox>.shape-awd {
                    margin-top: 5%;
                }

                .shape-awd>img {
                    width: 5vw;
                }

                #text-relativebox {
                    height: 25vw;
                }

                #text-relativebox>p {
                    line-height: normal;
                    padding-left: 5vw;
                    padding-right: 5vw;
                }
            }

            @media only screen and (min-width: 1201px) {
                .bg-img-21 {
                    background-image: url("../img/app-development/Banner-Web-Services.webp");
                    background-size: 100vw;
                    background-attachment: scroll;
                    background-repeat: no-repeat;
                    background-position: 100% 100%;
                }

                #awd-slider {
                    height: 50vh;
                }

                .phone {
                    position: relative;
                    top: -10rem;
                    margin-bottom: -40rem;
                    width: 100%;
                    right: 0;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 20vw;
                    padding: 0px;
                    margin-top: 30%;
                }

                .ui .RSBtn {
                    background-color: #ff214f;
                    width: 50%;
                    padding: 0px;
                    margin-top: 0rem;
                    margin-bottom: 3%;
                }

                #resinfoarea {
                    margin-top: 15%;
                    margin-left: auto;
                    padding-right: 5%;
                    display: flex;
                    justify-content: space-between;
                    vertical-align: middle;
                }

                #resinforight {
                    margin-top: 10%;
                    margin-left: auto;
                    padding-left: 0;
                    display: flex;
                    justify-content: space-between;
                }

                #info-div>br {
                    display: none;
                }

                #infodiv-landscape {
                    margin-bottom: 0;
                }

                .heading>div.shape {
                    margin: auto;
                    display: block !important;
                    position: absolute;
                    padding: 7%;
                    height: 20%;
                    left: 16%;
                    z-index: -1;
                }

                .info-box {
                    margin-left: 10%;
                    text-align: right;
                    padding-left: 40%;
                    margin-top: 0%;
                }

                .info-box>img {
                    margin-top: 0%;
                    display: flex;
                    flex-direction: row;
                    float: left;
                    margin-left: 15%;
                }

                #infoareaheading {
                    text-align: right;
                    margin-bottom: 0%;
                }

                .heading>h1 {
                    margin-bottom: 2%;
                    font-family: Montserrat;
                    text-transform: capitalize !important;
                    font-size: 2rem;
                    font-weight: 800;
                }

                .heading>h3 {
                    font-family: Montserrat;
                    text-transform: capitalize !important;
                    font-size: 1.8rem;
                    font-weight: 400;
                }

                p {
                    font-family: Montserrat;
                    font-size: 1rem !important;
                    color: #606060;
                    font-weight: 300;
                }

                #no-pad {
                    padding-left: 0%;
                    padding-right: 0%;
                    margin-left: 0% !important;
                    margin-right: 0% !important;
                }

                #text-relativebox>.shape-awd {
                    display: flex;
                    justify-content: center;
                    margin-top: 10%;
                }

                #text-relativebox>hr {
                    width: 10%;
                    border-top: 1px solid #00acd8;
                }

                #text-relativebox>h4 {
                    font-family: Montserrat;
                    font-size: 1.9rem !important;
                    font-weight: 600;
                    color: #606060;
                    margin-bottom: 5%;
                    text-align: center;
                    margin-top: 5%;
                }

                #text-relativebox>p {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.1em !important;
                    color: #606060;
                    font-weight: 400;
                    text-align: center;
                    padding-left: 5em;
                    padding-right: 5em;
                    line-height: normal;
                }

                #awd div.featured-area {
                    padding: 100px 100px;
                    position: absolute;
                    text-align: center;
                    padding-bottom: 250px;
                }

                .about-text {
                    margin-bottom: 10%;
                    margin-top: 5%;
                }

                .about-text>h1.theme-color {
                    font-weight: 400;
                    text-transform: none;
                }
            }

            @media only screen and (min-width: 1501px) {
                .bg-img-21 {
                    background-image: url("../img/app-development/Banner-Web-Services.webp");
                    background-size: 100vw;
                    background-attachment: scroll;
                    background-repeat: no-repeat;
                    background-position: 100% 100%;
                }

                #awd-slider {
                    height: 50vh;
                }

                .phone {
                    position: relative;
                    top: -10rem;
                    margin-bottom: -40rem;
                    width: 100%;
                    right: 0;
                }

                #resinfoarea {
                    margin-top: 10vh;
                    display: flex;
                    justify-content: center;
                    vertical-align: middle;
                    padding-left: 10vw;
                }

                #resinforight {
                    margin-top: 7vh;
                    padding-right: 0vw;
                    padding-left: 0vw;
                    display: flex;
                    justify-content: space-between;
                }

                #infodiv-landscape {
                    margin-bottom: 0;
                }

                .heading>div.shape {
                    margin: auto;
                    display: block !important;
                    position: absolute;
                    padding: 7%;
                    height: 20%;
                    left: 16%;
                    z-index: -1;
                }

                .info-box {
                    text-align: right;
                    padding-left: 40%;
                }

                .info-box>img {
                    display: flex;
                    flex-direction: row;
                    float: left;
                    margin-left: 15%;
                }

                #infoareaheading {
                    text-align: right;
                }

                .heading>h1 {
                    margin-bottom: 10%;
                    text-transform: none;
                }

                .heading>h3 {
                    font-family: "Montserrat", sans-serif;
                    text-transform: capitalize !important;
                    font-size: 1.8em;
                    font-weight: 600;
                }

                p {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.1em !important;
                    color: #606060;
                    font-weight: 400;
                }

                #featured-areaawd {
                    padding: 10% 10%;
                }

                #text-relativebox>.shape-awd {
                    display: flex;
                    justify-content: center;
                }

                #text-relativebox>hr {
                    width: 10%;
                    border-top: 1px solid #00acd8;
                }

                #text-relativebox>h4 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 2em !important;
                    font-weight: 600;
                    color: #606060;
                    margin-bottom: 5%;
                    text-align: center;
                    margin-top: 5%;
                }

                #text-relativebox>p {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.5em !important;
                    color: #606060;
                    font-weight: 400;
                    text-align: center;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 20vw;
                    padding: 0px;
                    margin-top: 30%;
                }

                .about-text {
                    margin-bottom: 10%;
                    margin-top: 5%;
                }

                .about-text>h1.theme-color {
                    font-weight: 400;
                    text-transform: none;
                }

                #no-pad {
                    padding-left: 0%;
                    padding-right: 0%;
                    margin-left: 0% !important;
                    margin-right: 0% !important;
                }

                #text-relativebox>.shape-awd {
                    display: flex;
                    justify-content: center;
                    margin-top: 10%;
                }

                #text-relativebox>hr {
                    width: 10%;
                    border-top: 1px solid #00acd8;
                }

                #text-relativebox>h4 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 2em !important;
                    font-weight: 600;
                    color: #606060;
                    margin-bottom: 5%;
                    text-align: center;
                    margin-top: 5%;
                }

                #text-relativebox>p {
                    font-family: "Montserrat", sans-serif;
                    font-size: 1.4em !important;
                    color: #606060;
                    font-weight: 400;
                    text-align: center;
                }

                #awd div.featured-area {
                    padding: 100px 100px;
                    position: absolute;
                    text-align: center;
                    padding-bottom: 250px;
                }

                .about-text {
                    margin-bottom: 10%;
                    margin-top: 5%;
                }

                .about-text>h1.theme-color {
                    font-weight: 400;
                    text-transform: none;
                }
            }

            /* Marketing and Strategy */
            #mas-container {
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                padding-top: 10vh;
                margin-bottom: 20vh;
            }

            #mas-main-img {
                width: 70vw;
                text-align: right;
            }

            #mas-services-div {
                width: 30vw;
                text-align: left;
                margin-top: 5%;
                margin-left: 10vw;
            }

            #mas-shape {
                position: absolute;
            }

            #mas-rsbutton {
                width: 50%;
            }

            .mas-btn {
                text-align: left;
            }

            .mas-videos {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            .mas-videos>video {
                width: 100%;
                box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
            }

            .mas-videos-2 {
                display: none;
            }

            .mas-mid-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10vh;
                margin-bottom: 20vh;
            }

            .mas-mid-description {
                padding: 5vw;
                text-align: center;
            }

            #mas-bottom-description {
                padding: 2% 20% 5% 20%;
            }

            .mas-bottom-title-section>div {
                width: 40vw;
                margin: 0 auto;
            }

            .mas-bottom-div {
                display: flex;
                align-content: space-around;
                padding-left: 15vh;
                padding-right: 15vh;
            }

            .mas-bottom-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .mas-bottom-title-bar {
                display: flex;
                align-items: center;
                margin-bottom: 1vh;
            }

            .mas-bottom-title-bar>h3 {
                text-align: center;
                margin-left: 1vh;
            }

            .mas-bottom-item>p {
                padding-right: 2.5vh;
                padding-left: 2.5vh;
            }

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
                #mas-main-img {
                    width: 50vw;
                }

                .mas-mid-description {
                    padding: 0vw;
                    padding-left: 5vw;
                }

                .mas-videos {
                    width: 100%;
                    margin-left: auto;
                }

                .mas-bottom-div {
                    padding-left: 0vh;
                    padding-right: 0vh;
                    margin-top: 2.5vh;
                }
            }

            @media only screen and (min-device-width: 1024px) and (max-device-width: 1199px) {
                #mas-container {
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10vh;
                }

                #mas-services-div {
                    width: 40vw;
                    text-align: left;
                    margin-top: 5%;
                    margin-left: 10vw;
                }

                #mas-main-img {
                    width: 50vw;
                }

                .mas-mid-description {
                    padding: 0vw;
                    padding-left: 5vw;
                }

                .mas-videos {
                    width: 100%;
                    margin-left: auto;
                }

                .mas-bottom-title-bar>h3 {
                    text-align: inherit;
                    margin-left: 1vh;
                }

                .mas-bottom-div {
                    padding-left: 0vh;
                    padding-right: 0vh;
                    margin-top: 2.5vh;
                }
            }

            @media only screen and (min-device-width:769px) and (max-device-width: 1023px) {
                #mas-container {
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10vh;
                }

                #mas-services-div {
                    width: 40vw;
                    text-align: left;
                    margin-top: 5%;
                    margin-left: 10vw;
                }

                #mas-main-img {
                    width: 50vw;
                }

                .mas-mid-description {
                    padding: 0vw;
                    padding-left: 5vw;
                }

                .mas-videos {
                    width: 100%;
                    margin-left: auto;
                }

                .mas-bottom-title-bar>h3 {
                    text-align: inherit;
                    margin-left: 1vh;
                }

                .mas-bottom-div {
                    padding-left: 0vh;
                    padding-right: 0vh;
                    margin-top: 2.5vh;
                }
            }

            @media only screen and (max-device-width: 768px) {
                #mas-container {
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 2.5vw;
                }

                #mas-main-img {
                    text-align: center;
                }

                #mas-services-div {
                    width: 50vw;
                    text-align: center;
                    margin-top: 10vw;
                    margin-left: 0vw;
                }

                #mas-shape {
                    position: absolute;
                    left: -2vw;
                }

                #mas-rsbutton {
                    width: 100%;
                }

                .mas-mid-container {
                    flex-direction: column;
                    text-align: center;
                    margin-top: 2.5vh;
                    margin-bottom: 20vh;
                }

                .mas-videos {
                    flex-direction: column;
                    width: 100%;
                }

                .mas-bottom-title-section>div {
                    width: 50vw;
                    margin: 0 auto;
                }

                #mas-bottom-description {
                    padding: 1% 10% 2.5% 10%;
                }

                .mas-bottom-title-section {
                    margin-bottom: 10vw;
                }

                .mas-bottom-div {
                    flex-direction: column;
                    padding-left: 0vh;
                    padding-right: 0vh;
                    text-align: center;
                }

                .mas-bottom-item {
                    display: flex;
                    width: 50%;
                    margin: 0 auto;
                    text-align: center;
                    flex-direction: column;
                    align-items: inherit;
                    margin-bottom: 2vw;
                }

                .mas-bottom-title-bar {
                    display: flex;
                    flex-direction: column;
                }

                .mas-bottom-title-bar>h3 {
                    text-align: center;
                    margin-left: 1vh;
                }

                .mas-bottom-item>p {
                    padding-right: 0vh;
                    padding-left: 0vh;
                }
            }

            @media only screen and (max-device-width: 500px) {

                /* html,
                body {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    padding: 0;
                    overflow-x: hidden;
                } */
                #mas-main-img {
                    width: 100vw;
                }

                #mas-services-div {
                    width: 100vw;
                    text-align: center;
                    margin-top: 10vw;
                    margin-left: 0vw;
                }

                #mas-shape {
                    position: absolute;
                    left: 30vw;
                }

                #mas-rsbutton {
                    margin: 0 auto;
                    width: 50%;
                }

                #mas-rsbutton.RSBtn {
                    display: block;
                    background-color: #ff214f;
                }

                .mas-videos {
                    display: none;
                }

                .mas-videos-2 {
                    display: inline-block;
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }

                .mas-videos-2>video {
                    width: 100%;
                    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
                }

                .mas-mid-container {
                    margin-bottom: 5vh;
                }

                .mas-bottom-title-section>div {
                    width: 75vw;
                    text-align: center;
                }

                #mas-bottom-description {
                    padding: 0% 5% 0% 5%;
                }

                .mas-bottom-title-section {
                    margin-bottom: 10vw;
                }

                .mas-bottom-div {
                    flex-direction: column;
                    padding-left: 0vh;
                    padding-right: 0vh;
                    text-align: center;
                }

                .mas-bottom-item {
                    display: flex;
                    width: 100%;
                    margin: 0 auto;
                    text-align: center;
                    flex-direction: column;
                    align-items: inherit;
                    margin-bottom: 5vw;
                }

                .mas-bottom-title-bar {
                    display: flex;
                    flex-direction: column;
                }

                .mas-bottom-title-bar>h3 {
                    text-align: center;
                    margin-left: 1vh;
                }

                .mas-bottom-item>p {
                    padding-right: 0vh;
                    padding-left: 0vh;
                }
            }

            /* Technical&Product Research */
            #tpr-container {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-evenly;
                padding-top: 7.5vw;
            }

            #tpr-main-img {
                width: 30vw;
                text-align: start;
            }

            #tpr-services-div {
                width: 40vw;
                text-align: start;
            }

            #tpr-shape {
                position: absolute;
            }

            #tpr-rsbutton {
                width: 30%;
            }

            .tpr-info-div {
                padding-right: 10vw;
                padding-left: 12vw;
                margin-bottom: 10vw;
                margin-top: 5vw;
                display: flex;
                justify-content: space-between;
            }

            .tpr-item {
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 20vw;
            }

            .tpr-item-text>p {
                font-size: 1.15em !important;
                line-height: 110%;
                text-align: center;
            }

            .tpr-hr-lines>hr {
                height: 0px;
                border: none;
                border-top: 1px solid black;
            }

            #tpr-mid-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 0;
                padding: 0;
                width: 100%;
                overflow-x: hidden;
            }

            .tpr-mid-image-padding {
                padding-left: 0px;
                padding-right: 0px;
                margin-right: -20px;
                margin-left: -5px;
            }

            .tpr-mid-text-padding {
                background-color: #00acd8;
                color: white !important;
                padding-top: 5vw;
                margin-top: 1.85vw;
            }

            .tpr-info-icons {
                display: flex;
                justify-content: space-evenly;
                margin-top: 7vw;
                margin-bottom: 4.95vw;
                padding-left: 5vw;
                padding-right: 5vw;
            }

            .tpr-pad-control {
                margin-right: -25px;
            }

            .position-tpr {
                background-color: #00acd8;
                text-align: center;
                align-items: center;
                color: white !important;
                padding-top: 5vw;
            }

            .tpr-mid-text-padding>h1 {
                color: white !important;
                margin-bottom: 20px !important;
                width: 50%;
                margin: auto;
                text-align: center;
            }

            .tpr-mid-text-padding>p {
                color: white !important;
                width: 50%;
                margin: auto;
                text-align: center;
                font-weight: 600;
            }

            .tpr-mid-text-padding>hr {
                width: 10%;
                margin-bottom: 50px;
            }

            .tpr-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            #tpr-bottom-description {
                padding: 2% 20% 5% 20%;
            }

            .img-container-tpr>img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 75vw;
            }

            .tpr-info-icons-bottom {
                position: relative;
                margin-bottom: 100px;
            }

            /* #tpr-lower-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
            } */
            .tpr-button-bottom-div {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .tpr-button-bottom-div>div {
                width: 50%;
                display: flex;
                justify-content: center;
            }

            .tpr-bottom-button {
                margin-left: 6vw;
                margin-top: 2vw;
                width: 10vw !important;
            }

            .tpr-contact-heading>p {
                padding-left: 10vw;
                padding-right: 10vw;
            }

            .tpr-contact-heading>h3 {
                margin-bottom: 2.5vw;
                font-weight: 600;
            }

            .tpr-low-img-2 {
                display: none;
            }

            .tpr-bottom-item-div {
                display: none;
            }

            @media only screen and (min-device-width: 1501px) {}

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
                #tpr-main-img {
                    margin-top: 5vw;
                    width: 75vh;
                }

                #tpr-services-div {
                    margin-top: 5vw;
                    width: 50vh;
                }

                #tpr-rsbutton {
                    width: 40%;
                }
            }

            @media only screen and (min-device-width: 1000px) and (max-device-width: 1199px) {
                .tpr-info-div {
                    padding-right: 0vw;
                    padding-left: 0vw;
                    margin-bottom: 10vw;
                    margin-top: 5vw;
                    display: flex;
                    justify-content: space-between;
                }

                #tpr-main-img {
                    margin-top: 5vw;
                    width: 60vh;
                }

                #tpr-services-div {
                    margin-top: 5vw;
                    width: 50vh;
                }

                #tpr-rsbutton {
                    width: 40%;
                }

                .tpr-item {
                    width: 30vw;
                }
            }

            @media only screen and (min-device-width: 769px) and (max-device-width: 999px) {
                #tpr-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-evenly;
                    padding-top: 10vw;
                }

                #tpr-main-img {
                    margin-top: 13vw;
                    width: 50vh;
                }

                #tpr-services-div {
                    margin-top: 10vw;
                    text-align: center;
                    width: 50vh;
                }

                #tpr-rsbutton {
                    width: 40%;
                }

                .tpr-info-div {
                    display: flex;
                    margin-bottom: 0;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }

                .tpr-item {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 5vw;
                    width: 50vw;
                }

                .tpr-item-text>p {
                    font-size: 1.3em !important;
                    line-height: 110%;
                    text-align: center;
                }

                .tpr-hr-lines>hr {
                    height: 0px;
                    border: none;
                    border-top: 1px solid black;
                }

                .tpr-mid-text-padding {
                    background-color: #00acd8;
                    color: white !important;
                    padding-top: 5vw;
                    margin-top: -1.85vw;
                }

                .tpr-info-icons {
                    margin-bottom: 10vw;
                }

                .tpr-low-img {
                    display: none;
                }

                .tpr-low-img-2 {
                    width: 60vh;
                    display: block;
                    margin: 0 auto;
                }

                .tpr-bottom-item {
                    display: flex;
                    margin-top: 10vh;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .bottom-item-text {
                    text-align: center;
                }

                .tpr-bottom-item-div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                #tpr-lower-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }

                #tpr-rsbutton {
                    margin-top: 10vh;
                    width: 50vw !important;
                }
            }

            @media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
                #tpr-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 0;
                    padding-top: 0vw;
                }

                #tpr-main-img {
                    margin-top: 13vw;
                    width: 100vw;
                }

                #tpr-services-div {
                    margin-top: 5vw;
                    width: 90vw;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    justify-content: center;
                }

                #tpr-shape {
                    position: absolute;
                    top: 0;
                    left: 15vw;
                }

                #tpr-rsbutton {
                    width: 50%;
                    margin: 10% auto;
                    display: block !important;
                    background-color: #ff214f;
                }

                #tpr-mid-container {
                    width: 103%;
                }

                .tpr-bottom-div {
                    margin-top: 5vw;
                }

                .tpr-info-div {
                    display: flex;
                    margin-bottom: 10vw;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }

                .tpr-item {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 5vw;
                    width: 90vw;
                }

                .tpr-item-text>p {
                    font-size: 1.3em !important;
                    line-height: 110%;
                    text-align: center;
                }

                .tpr-hr-lines>hr {
                    height: 0px;
                    border: none;
                    border-top: 1px solid black;
                }

                .tpr-mid-text-padding {
                    background-color: #00acd8;
                    color: white !important;
                    padding-top: 5vw;
                    margin-right: -15px;
                    margin-left: -500px;
                    margin-top: -1.85vw;
                }

                .tpr-info-icons {
                    margin-bottom: 10vw;
                }

                #tpr-bottom-description {
                    padding: 0;
                    margin-bottom: 5vw;
                }

                .tpr-low-img {
                    display: none;
                }

                .tpr-low-img-2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .tpr-bottom-item-div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .tpr-bottom-item {
                    display: flex;
                    margin-top: 10vh;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .bottom-item-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                }

                #tpr-rsbutton {
                    margin-top: 10vh;
                    width: 50vw !important;
                }

                .img-container-tpr>img {
                    display: inline-flex;
                    margin-left: 0;
                    margin-right: 0;
                    width: 60vw;
                }

                .tpr-info-div {
                    display: flex;
                    margin-bottom: 10vw;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }

                .tpr-item {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 5vw;
                    width: 90vw;
                }

                .tpr-item-text>p {
                    font-size: 1.3em !important;
                    line-height: 110%;
                    text-align: center;
                }

                .tpr-hr-lines>hr {
                    height: 0px;
                    border: none;
                    border-top: 1px solid black;
                }

                hr.tpr-mobi-hide {
                    border-top: 0px !important;
                }

                .tpr-mid-text-padding {
                    background-color: #00acd8;
                    color: white !important;
                    padding-top: 5vw;
                    margin-top: -1.85vw;
                }

                .tpr-info-icons {
                    margin-bottom: 10vw;
                }
            }

            @media only screen and (max-device-width: 480px) {
                #tpr-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 7.5vw;
                }

                #tpr-main-img {
                    margin-top: 13vw;
                    width: 100vw;
                }

                #tpr-services-div {
                    margin-top: 13vw;
                    width: 100vw;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    justify-content: center;
                }

                #tpr-shape {
                    position: absolute;
                    top: 0;
                    left: 15vw;
                }

                #tpr-rsbutton {
                    width: 50%;
                    margin: 10% auto;
                    display: block !important;
                    background-color: #ff214f;
                }

                .tpr-info-div {
                    display: flex;
                    margin-bottom: 10vw;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }

                .tpr-item {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 5vw;
                    width: 90vw;
                }

                .tpr-item-text>p {
                    font-size: 1.3em !important;
                    line-height: 110%;
                    text-align: center;
                }

                .tpr-hr-lines>hr {
                    height: 0px;
                    border: none;
                    border-top: 1px solid black;
                }

                hr.tpr-mobi-hide {
                    border-top: 0px !important;
                }

                .tpr-mid-text-padding {
                    background-color: #00acd8;
                    color: white !important;
                    padding-top: 5vw;
                    margin-right: -0px;
                    margin-left: -0px;
                    margin-top: -1.85vw;
                }

                .tpr-info-icons {
                    margin-bottom: 10vw;
                }

                #tpr-bottom-description {
                    padding: 0;
                    margin-bottom: 5vw;
                }

                .tpr-low-img {
                    display: none;
                }

                .tpr-low-img-2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .tpr-bottom-item-div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .tpr-bottom-item {
                    display: flex;
                    margin-top: 10vh;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .bottom-item-text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                }

                #tpr-rsbutton {
                    margin-top: 10vh;
                    width: 50vw !important;
                }

                .img-container-tpr {
                    width: 100vw;
                }

                .img-container-tpr>img {
                    display: inline-flex;
                    margin-left: 0;
                    margin-right: 0;
                    width: 100vw;
                }

                .tpr-info-div {
                    display: flex;
                    margin-bottom: 10vw;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                }

                .tpr-item {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 5vw;
                    width: 90vw;
                }

                .tpr-item-text>p {
                    font-size: 1.3em !important;
                    line-height: 110%;
                    text-align: center;
                }

                .tpr-hr-lines>hr {
                    height: 0px;
                    border: none;
                    border-top: 1px solid black;
                }

                hr.tpr-mobi-hide {
                    border-top: 0px !important;
                }

                .tpr-mid-text-padding {
                    background-color: #00acd8;
                    color: white !important;
                    padding-top: 5vw;
                    margin-top: -1.85vw;
                }
            }

            /* UI UX DESIGN */
            #ui-top-container {
                padding-top: 15vh;
            }

            #ui-shape {
                position: absolute;
                left: 50vw;
                top: -5vh;
            }

            #ui-main-description {
                width: 50vw;
                text-align: center;
                margin: 0 auto;
            }

            #ui-main-img {
                display: flex;
                width: 100vw;
                justify-content: center;
            }

            .ui-bot-center {
                margin-left: 33vw;
                padding-top: 15vh;
            }

            .ui-bot-links {
                display: flex;
                width: 50%;
                justify-content: space-around;
                margin-bottom: 10%;
            }

            .ui-bot-links>button {
                background: none;
                border: none;
                padding: 2% 5%;
                font-size: medium;
                text-align: center;
            }

            .ui-bot-links>button:hover {
                background-color: #00acd8;
                padding: 2% 5%;
                color: white;
            }

            .ui-grid-image-wrapper {
                display: grid;
                justify-content: center;
                grid-template-columns: 311px 311px 311px;
                grid-template-rows: 228px 228px 228px;
                grid-gap: 15px 15px;
                grid-template-areas: "first second third" "first fourth third" "fifth sixth seventh";
            }

            #ui-item1 {
                grid-area: first;
            }

            #ui-item2 {
                width: max-content;
                grid-area: second;
            }

            #ui-item3 {
                grid-area: third;
            }

            #ui-item4 {
                grid-area: fourth;
                margin: 0 auto;
                text-align: center;
                width: 100%;
                padding: 5% 0%;
            }

            #ui-item4>img {
                width: 5vh;
            }

            #ui-item4>h3 {
                margin-top: 2vh;
            }

            #ui-item4>p {
                width: 100%;
                font-size: 1em !important;
                line-height: normal;
                margin: 0;
                padding: 0;
            }

            #ui-item5 {
                grid-area: fifth;
            }

            #ui-item6 {
                width: max-content;
                grid-area: sixth;
            }

            #ui-item7 {
                grid-area: seventh;
            }

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {}

            @media only screen and (min-device-width: 1024px) and (max-device-width: 1199px) {
                #ui-main-description {
                    width: 100%;
                }
            }

            @media only screen and (min-device-width: 769px) and (max-device-width: 1023px) {
                #ui-main-description {
                    width: 100%;
                }
            }

            @media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
                #ui-main-description {
                    width: 100%;
                }

                .ui-grid-image-wrapper {
                    grid-template-columns: 311px 311px;
                    grid-template-rows: 228px 228px;
                    grid-template-areas: "first third" "first third" "fourth fourth" "second fifth" "sixth seventh";
                }
            }

            @media only screen and (max-device-width: 480px) {
                #ui-top-container {
                    padding-top: 30%;
                }

                #ui-shape {
                    position: absolute;
                    left: 50%;
                    top: -10%;
                }

                #ui-main-description {
                    width: 100%;
                    text-align: center;
                    margin: 0 auto;
                }

                #ui-main-img {
                    margin: 0 auto;
                    width: 100%;
                }

                #ui-sub-info-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    margin-left: 8%;
                    margin-right: 0;
                    max-width: 100%;
                }

                .ui-sub-text-div {
                    overflow: hidden;
                }

                .ui-sub-img {
                    float: left;
                    margin-right: 10%;
                }

                .ui-bot-center {
                    margin-left: 35%;
                    padding-top: 15%;
                }

                #mobi-ui-hidden {
                    display: none;
                }

                #ui-main-description {
                    margin-bottom: 10%;
                }

                .ui-bot-links {
                    display: flex;
                    width: 50%;
                    justify-content: space-around;
                    margin-bottom: 10%;
                }

                .ui-bot-links>button {
                    background: none;
                    border: none;
                    padding: 2% 5%;
                    font-size: medium;
                    text-align: center;
                }

                .ui-bot-links>button:hover {
                    background-color: #00acd8;
                    padding: 2% 5%;
                    color: white;
                }

                .ui-grid-image-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                #ui-item1 {
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
                    order: 2;
                }

                #ui-item2 {
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
                    order: 5;
                }

                #ui-item3 {
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
                    order: 6;
                }

                #ui-item4 {
                    order: 3;
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
                    padding: 5% 0%;
                }

                #ui-item4>p {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    order: 4;
                }

                #ui-item4 {
                    order: 4;
                }

                #ui-item5 {
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
                    order: 1;
                }

                #ui-item6 {
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
                    order: 3;
                }

                #ui-item7 {
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
                    order: 7;
                }
            }

            /* ux design dev */
            #ux-slider {
                height: 55vh;
            }

            .ux-image-container {
                position: relative;
                z-index: 10;
            }

            .ux-title {
                margin-top: 15vh;
                text-align: center;
            }

            .ux-title>#ui-shape {
                position: absolute;
                top: 65vh;
                left: 53vw;
            }

            .ux-title>h3 {
                font-size: 2.2em;
                font-weight: 600;
            }

            .ux-title>h1 {
                line-height: 75%;
            }

            .ux-title>p {
                padding-right: 15vw;
                padding-left: 15vw;
                margin-top: 10vh;
                font-size: 1.2em !important;
                line-height: 110%;
            }

            .ux-info-div {
                padding-right: 10vw;
                padding-left: 10vw;
                margin-top: 10vh;
                margin-bottom: 0vh;
                display: flex;
                justify-content: center;
            }

            .ux-item {
                display: flex;
                align-items: baseline;
            }

            .ux-item-image {
                margin-right: 5vh;
                width: 10vw;
            }

            .ux-item-text>h3 {
                font-size: 1.8em !important;
                text-transform: none;
                text-align: center !important;
            }

            .ux-item-text>p {
                font-size: 1.15em !important;
                line-height: 110%;
            }

            .ux-rsbtn {
                margin-left: 0vw;
            }

            #ux-sp {
                position: relative;
                margin-top: 7.5vh;
                margin-bottom: 7.5vh;
                margin-left: -15px;
                margin-right: -15px;
            }

            #ux-sp>img {
                width: 100%;
            }

            .ux-mid-img-text {
                position: absolute;
                color: white;
                top: 98%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .about-pad-control>.container {
                padding-left: 0;
                padding-right: 0;
            }

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {}

            @media only screen and (min-device-width: 1024px) and (max-device-width: 1199px) {
                .ux-item {
                    display: block;
                }

                .ux-item-image {
                    margin-right: auto;
                    margin-left: auto;
                    text-align: center;
                    width: 4rem;
                }

                .ux-item-text {
                    text-align: justify;
                }
            }

            @media only screen and (min-device-width: 769px) and (max-device-width: 1023px) {
                .ux-item {
                    display: block;
                }

                .ux-item-image {
                    margin-right: auto;
                    margin-left: auto;
                    text-align: center;
                }

                .ux-item-image img {
                    width: 4rem;
                    margin-top: 2rem;
                }

                .ux-item-text {
                    text-align: center;
                }

                .ux-item-text>p {
                    font-size: 1.1em !important;
                    text-align: justify;
                }

                .ux-title>#ui-shape {
                    display: none;
                }
            }

            @media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
                .ux-info-div {
                    flex-direction: column;
                }

                .ux-item-logo {
                    max-width: none;
                }

                .ux-item-text>p {
                    font-size: 1em !important;
                }

                .ux-title>#ui-shape {
                    display: none;
                }

                .ux-mid-img-text {
                    width: 100%;
                    text-align: center;
                }
            }

            @media only screen and (max-device-width: 480px) {
                #ux-slider {
                    height: 40vh;
                }

                .ux-info-div {
                    flex-direction: column;
                }

                .ux-item-logo {
                    max-width: none;
                }

                .ux-item-text>p {
                    font-size: 1em !important;
                }

                .ux-title>#ui-shape {
                    display: none;
                }

                .ux-title {
                    margin-top: 7.5vh;
                }

                .ux-mid-img-text {
                    display: none;
                }
            }

            /* web dev */
            #web-slider {
                height: 70vh;
            }

            .web-image-container {
                position: relative;
                z-index: 10;
            }

            .web-laptop {
                position: relative;
                top: -135vh;
                margin-bottom: -225vh;
                width: 50vw;
                left: 24vw;
            }

            .web-title {
                margin-top: 15vh;
                text-align: center;
            }

            .web-title>div.shape {
                margin: auto;
                width: 100%;
                display: block !important;
                position: absolute;
                height: auto;
                left: -5%;
                top: 80vh;
                z-index: -1;
            }

            .web-title>h3 {
                font-size: 1.8em;
                font-weight: 600;
            }

            .web-title>h1 {
                line-height: 75%;
            }

            .web-title>p {
                padding-right: 25vw;
                padding-left: 25vw;
                margin-top: 7vh;
                font-size: 1.1em !important;
                line-height: 110%;
            }

            .web-icon-container {
                margin-top: 10vh;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            .web-icon-container>img {
                margin-left: 2vw;
                width: 3rem;
            }

            .web-info-div {
                padding-right: 10vw;
                padding-left: 12vw;
                margin-top: 20vh;
                margin-bottom: 10vh;
                display: flex;
                justify-content: center;
            }

            .web-item {
                display: flex;
                align-items: baseline;
            }

            .web-item-image {
                margin-right: 5vh;
            }

            .web-item-text {
                align-items: center;
                text-align: center;
            }

            .web-item-text>h3 {
                font-size: 1.6em !important;
                text-transform: none;
            }

            .web-item-text>p {
                font-size: 1.1em !important;
                line-height: 110%;
            }

            .web-item-text img {
                width: 3rem;
                padding-bottom: 1rem;
            }

            .web-rsbtn {
                margin-left: 0vw;
            }

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
                #web-slider {
                    height: 60vh;
                }

                .web-laptop {
                    position: relative;
                    top: -130vh;
                    margin-bottom: -225vh;
                    width: 50vw;
                    left: 24vw;
                }

                .web-title>div.shape {
                    left: -5%;
                    top: 75vh;
                }
            }

            @media only screen and (min-device-width: 1024px) and (max-device-width: 1199px) {
                #web-slider {
                    height: 55vh;
                }

                .web-laptop {
                    position: relative;
                    top: -125vh;
                    margin-bottom: -225vh;
                    width: 50vw;
                    left: 24vw;
                }

                .web-title>div.shape {
                    left: -5%;
                    top: 70vh;
                }

                .web-info-div {
                    padding-right: 5vw;
                    padding-left: 5vw;
                    justify-content: space-between;
                }

                .web-item {
                    display: block;
                    text-align: center;
                }

                .web-item-image {
                    width: 25%;
                    margin: 0 auto;
                }

                .web-item-text {
                    margin-top: 5vh;
                }
            }

            @media only screen and (min-device-width: 769px) and (max-device-width: 1023px) {
                .web-dev-container {
                    padding-left: 0%;
                    padding-right: 0%;
                }

                #web-slider {
                    height: 50vh;
                }

                .web-laptop {
                    position: relative;
                    top: -125vh;
                    margin-bottom: -225vh;
                    width: 50vw;
                    left: 24vw;
                }

                .web-title>div.shape {
                    position: relative;
                    left: -5%;
                    top: 15vh;
                }

                .web-title {
                    margin-top: 0vh;
                    text-align: center;
                }

                .web-item {
                    display: block;
                    text-align: center;
                }

                .web-item-image {
                    width: 25%;
                    margin: 0 auto;
                }

                .web-item-text {
                    margin-top: 5vh;
                }
            }

            @media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
                .web-dev-container {
                    padding-left: 0%;
                    padding-right: 0%;
                }

                #web-slider {
                    height: 40vh;
                }

                .web-laptop {
                    position: relative;
                    top: -120vh;
                    margin-bottom: -225vh;
                    width: 60vw;
                    left: 18vw;
                }

                .web-title>div.shape {
                    position: relative;
                    left: -5%;
                    top: 15vh;
                }

                .web-title {
                    margin-top: 0vh;
                    text-align: center;
                }

                .web-title>p {
                    padding-left: 10vw;
                    padding-right: 10vw;
                }

                .web-info-div {
                    display: flex;
                    flex-direction: column;
                }

                .web-item {
                    display: flex;
                    align-items: baseline;
                }

                .web-item-image {
                    margin-right: 10px;
                }

                .web-item-logo {
                    width: 7vh;
                }

                .web-item-image {
                    width: 25%;
                    margin: 0 auto;
                }

                .web-item-text {
                    margin-top: 5vh;
                }
            }

            @media only screen and (max-device-width: 480px) {
                #web-slider {
                    height: 25vh;
                }

                .web-title {
                    margin-top: -15%;
                    text-align: center;
                }

                .web-icon-container {
                    margin-top: 0vh;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }

                .web-title>div.shape {
                    position: relative;
                    top: 15vh;
                }

                .web-title>p {
                    padding-left: 10vw;
                    padding-right: 10vw;
                }

                .web-laptop {
                    position: relative;
                    top: -120vh;
                    margin-bottom: -225vh;
                    width: 75vw;
                    left: 12vw;
                }

                .web-info-div {
                    display: flex;
                    margin-top: 0;
                    flex-direction: column;
                    justify-content: center;
                }

                .web-item {
                    display: block;
                    text-align: center;
                    margin-top: 3vh;
                }

                .web-item-image {
                    width: 25%;
                    margin: 0 auto;
                }

                .web-item-text {
                    margin-top: 0vh;
                }

                .web-item-text img {
                    margin-top: 2vh !important;
                    width: 3rem !important;
                }

                #no-pad {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            /* branding */
            #brand-top-container {
                padding-top: 15vh;
            }

            #brand-shape {
                position: absolute;
                top: -2vw;
                left: 50vw;
            }

            .brand-sub-text {
                font-size: 2.2em;
                font-weight: 500;
            }

            .weird-p-margin {
                margin-bottom: 52px;
            }

            #brand-main-img {
                display: flex;
                justify-content: center;
            }

            .brand-info-div {
                padding-right: 5vw;
                padding-left: 5vw;
                margin-top: 10vh;
                margin-bottom: 0vh;
                display: flex;
                justify-content: space-between;
            }

            .brand-item {
                display: block;
                align-items: center;
                text-align: center;
                padding: 0% 5% 0% 5%;
            }

            .brand-item-image {
                display: flex;
                justify-content: center;
                margin-bottom: 2vh;
            }

            .brand-item-text>p {
                font-size: 1em;
            }

            .brand-text-div {
                display: flex;
                flex-direction: column;
                padding-left: 10vh;
            }

            .brand-mid {
                margin-top: 12vh;
            }

            .brand-mid-symbol {
                align-self: flex-start;
            }

            .brand-text-button {
                margin-top: 5vh;
            }

            .brand-carousel-control-prev-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
            }

            .brand-carousel-control-prev-icon:Hover {
                text-shadow: 4px 4px 4px #000000 !important;
            }

            .brand-carousel-control-next-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
            }

            .brand-carousel-control-next-icon:Hover {
                text-shadow: 4px 4px 4px #000000 !important;
            }

            .carousel-indicators>li {
                border: 1px solid white;
            }

            .brand-bottom-title {
                font-weight: 400;
                text-transform: none;
            }

            .brand-bottom-div {
                margin-bottom: 10vw;
                margin-top: 10vh
            }

            .bottom-div-bottom-text {
                margin-top: 10%;
                margin-bottom: 0;
            }

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {}

            @media only screen and (min-device-width: 1024px) and (max-device-width: 1199px) {}

            @media only screen and (max-device-width: 990px) {
                .brand-text-div {
                    flex-direction: column-reverse;
                    padding-bottom: 0%;
                    padding-left: 0%;
                }

                .brand-mid-text {
                    text-align: center;
                    margin-bottom: 5vw;
                }

                .brand-text-button {
                    display: none;
                }

                .brand-mid-symbol {
                    margin-bottom: -5vh;
                    z-index: 10;
                    margin-left: 5vh;
                    width: 50%;
                }
            }

            @media only screen and (max-device-width: 480px) {
                .brand-mid-symbol {
                    width: 50%;
                }
            }

            /* services */
            .top-service-padding {
                padding: 0px 0px;
            }

            .card-container {
                margin-top: 10%;
                display: grid;
                justify-content: center;
                justify-items: center;
                grid-template-columns: 33% 33% 33%;
                grid-template-rows: 33% 33% 33%;
                grid-row-gap: 200px;
                grid-column-gap: 50px;
                grid-template-areas: "first second third" "fourth fifth sixth";
            }

            .card-1 {
                grid-area: first;
            }

            .card-2 {
                grid-area: second;
            }

            .card-3 {
                grid-area: third;
            }

            .card-4 {
                grid-area: fourth;
            }

            .card-5 {
                grid-area: fifth;
            }

            .card-6 {
                grid-area: sixth;
            }

            .card-7 {
                grid-area: seventh;
            }

            .card-8 {
                grid-area: eigth;
            }

            .service-button-1 {
                margin-top: 5vw;
            }

            .service-button-2 {
                margin-top: 3.5vw;
            }

            .service-button-3 {
                margin-top: 2vw;
            }

            .service-button-4 {
                margin-top: 6vw;
            }

            .service-button-5 {
                margin-top: 4.7vw;
            }

            .service-button-6 {
                margin-top: 2vw;
            }

            .service-button-7 {
                margin-top: 1.25vw;
            }

            .service-button-8 {
                margin-top: 4.5vw;
            }

            .bottom-card-container {
                margin-top: -175px;
                margin-bottom: 10vh;
                margin-left: 33%;
                display: grid;
                justify-content: center;
                justify-items: center;
                grid-template-columns: 33% 33%;
                grid-template-rows: 33% 33%;
                grid-row-gap: 50px;
                grid-column-gap: 300px;
                grid-template-areas: "seventh . eigth";
            }

            .services-top-shape {
                position: relative;
            }

            .services-top-shape>img {
                position: absolute;
                top: -5vh;
            }

            .services-title>h3 {
                line-height: 20%;
                font-size: 1.8rem;
                font-weight: 500;
            }

            .services-title>h1 {
                font-size: 2rem;
                font-weight: 800;
                line-height: 54px;
            }

            .card {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
                transition: 0.3s;
                border-radius: 5px 5px 30px 30px;
                word-wrap: break-word;
                width: 39vh;
                overflow: visible;
            }

            .card-image {
                height: 30vh;
                overflow: hidden;
            }

            .card img {
                transition: ease-in-out .5s;
            }

            .card img:hover {
                transform: scale(1.25);
            }

            .card:hover {
                box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.521);
            }

            .services-description-div {
                padding: 10% 2.5% 0% 1.3vw;
            }

            .services-description-div>h3 {
                text-transform: none;
            }

            .services-description-div>p {
                margin-top: 10%;
                margin-bottom: 5%;
            }

            .bottom-services-row {
                display: flex;
                justify-content: space-around;
            }

            .services-description-link:hover {
                color: #00acd8;
            }

            .services-description-div>h3 {
                font-size: 1.7em;
            }

            .services-description-div>p {
                font-size: 1.5em !important;
                font-weight: 400;
                line-height: 110%;
                letter-spacing: -.025em;
            }

            @media only screen and (min-device-width: 1501px) {
                .service-button-8 {
                    margin-top: 0vw;
                }

                .service-button-2 {
                    margin-top: 5vw;
                }
            }

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
                .card {
                    width: 40vh;
                }

                .card-container {
                    margin-top: 10%;
                    display: grid;
                    justify-content: center;
                    justify-items: center;
                    grid-template-columns: 33% 33% 33%;
                    grid-template-rows: 33% 33% 33%;
                    grid-row-gap: 150px;
                    grid-column-gap: 15px;
                    grid-template-areas: "first second third" "fourth fifth sixth";
                }

                .service-button-8 {
                    margin-top: 1vw;
                }
            }

            @media only screen and (min-device-width: 990px) and (max-device-width: 1199px) {
                .card-container {
                    margin-top: 10%;
                    display: grid;
                    justify-content: center;
                    justify-items: center;
                    grid-template-columns: 33% 33% 33%;
                    grid-template-rows: 33% 33% 33%;
                    grid-row-gap: 200px;
                    grid-column-gap: 50px;
                    grid-template-areas: "first second third" "fourth fifth sixth";
                }

                .card {
                    width: 35vh;
                }

                .service-button-8 {
                    margin-top: 0vw;
                }

                .service-button-4 {
                    margin-top: 9vw;
                }
            }

            @media only screen and (max-device-width: 990px) {
                .card-container {
                    display: grid;
                    justify-content: center;
                    justify-items: center;
                    align-items: center;
                    grid-template-columns: 50% 50%;
                    grid-template-rows: 588px 588px;
                    grid-row-gap: 5vw;
                    grid-column-gap: 10px;
                    grid-template-areas: "first second" "third fourth" "fifth sixth";
                }

                .bottom-card-container {
                    margin-top: 5vw;
                    margin-left: 15px;
                    margin-bottom: -50%;
                    display: grid;
                    justify-content: center;
                    justify-items: center;
                    grid-template-columns: 50% 50%;
                    grid-template-rows: 588px 588px;
                    grid-row-gap: 0px;
                    grid-column-gap: 20px;
                    grid-template-areas: "seventh eigth";
                }

                .service-button-7 {
                    margin-top: 2.5vw;
                }

                div#bottom-section-service-pad {
                    padding: 120px 0px;
                }
            }

            @media only screen and (min-device-width: 480px) and (max-device-width: 767px) {
                .card-container {
                    display: grid;
                    justify-items: center;
                    align-items: center;
                    grid-template-columns: 100%;
                    grid-template-rows: 588px;
                    grid-row-gap: 5vw;
                    grid-column-gap: 10px;
                    grid-template-areas: "first" "second" "third" "fourth" "fifth" "sixth";
                }

                .bottom-card-container {
                    margin-top: 5vw;
                    margin-bottom: 0%;
                    display: grid;
                    justify-content: center;
                    justify-items: center;
                    grid-template-columns: 100%;
                    grid-template-rows: 588px;
                    grid-row-gap: 5vw;
                    grid-column-gap: 12.5px;
                    grid-template-areas: "seventh" "eigth";
                }

                .service-button-7 {
                    margin-top: 5vw;
                }

                div#bottom-section-service-pad {
                    padding: 120px 0px;
                }
            }

            @media only screen and (max-device-width: 480px) {
                .card-container {
                    display: grid;
                    justify-items: center;
                    align-items: center;
                    grid-template-columns: 100%;
                    grid-template-rows: 588px;
                    grid-row-gap: 5vw;
                    grid-column-gap: 10px;
                    grid-template-areas: "first" "second" "third" "fourth" "fifth" "sixth";
                }

                .bottom-card-container {
                    margin-top: 5vw;
                    margin-bottom: 0%;
                    display: grid;
                    justify-content: center;
                    justify-items: center;
                    grid-template-columns: 100%;
                    grid-template-rows: 588px;
                    grid-row-gap: 5vw;
                    grid-column-gap: 12.5px;
                    grid-template-areas: "seventh" "eigth";
                }

                .service-button-7 {
                    margin-top: 5vw;
                }

                div#bottom-section-service-pad {
                    padding: 120px 0px;
                }
            }

            /* Product Support And Maintence */
            #psm-slider.slider-area.height-100 {
                height: 40vw;
            }

            .psm-title-div {
                text-align: center;
            }

            .psm-title-div>h1 {
                margin: 0 auto;
                margin-bottom: 2.5vw;
                width: 50%;
            }

            .psm-title-div>h1,
            .psm-title-div>p {
                color: white;
                text-shadow: 2px 2px rgba(0, 0, 0, 0.603);
            }

            .psm-main-img-div {
                text-align: center;
                margin: -10vw auto;
                margin-bottom: 10vw;
                width: 80vw;
            }

            .psm-main-img-div>img {
                width: 100%;
            }

            .psm-info-div {
                padding-right: 10vw;
                padding-left: 10vw;
                margin-top: 10vh;
                margin-bottom: 10vw;
                display: flex;
                justify-content: center;
            }

            .psm-item {
                display: flex;
                align-items: baseline;
            }

            .psm-item-imagei img {
                margin-right: 0vh;
                width: 5rem;
            }

            .psm-item-text>h3 {
                font-size: 1.8em !important;
                text-transform: none;
            }

            .psm-item-text>p {
                font-size: 1.1em !important;
                line-height: 110%;
            }

            .psm-mid-div {
                text-align: center;
                margin-bottom: 5vw;
            }

            .psm-mid-div>p {
                width: 50vw;
                margin: 0 auto;
                margin-top: 2.5vw;
            }

            .psm-mid-div>video {
                margin-top: 5vw;
            }

            .psm-bottom-div {
                background-color: #f4f4f4;
                background-image: url('../img/Product-Support-and-Maintenance-Services/PSM-Footer.png');
                background-repeat: no-repeat;
                background-position: center;
                z-index: 1;
                position: relative;
                background-attachment: scroll;
                background-size: contain;
                width: 100%;
                height: 30rem;
            }

            @media only screen and (min-device-width: 1501px) {}

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
                .psm-item {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: stretch;
                    text-align: center;
                }
            }

            @media only screen and (min-device-width: 990px) and (max-device-width: 1199px) {
                .psm-item {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                }

                .psm-main-img-div {
                    text-align: center;
                    margin: -6vw auto;
                    margin-bottom: 10vw;
                    width: 100vw;
                }
            }

            @media only screen and (max-device-width: 990px) {
                .psm-item {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                }

                .psm-main-img-div {
                    text-align: center;
                    margin: -5vw auto;
                    margin-bottom: 10vw;
                    width: 100vw;
                }

                .psm-title-div>h1 {
                    margin: 0 auto;
                    margin-bottom: 2.5vw;
                    width: 100%;
                }

                #psm-slider.slider-area.height-100 {
                    height: 63vw;
                }

                .psm-title-div {
                    text-align: center;
                    margin-top: 15vw;
                }

                .psm-title-button {
                    display: none;
                }
            }

            @media only screen and (min-device-width: 480px) and (max-device-width: 767px) {
                .psm-hide {
                    visibility: hidden;
                }

                .psm-main-img-div {
                    text-align: center;
                    margin: -10vw auto;
                    margin-bottom: 10vw;
                    width: 100vw;
                }

                .psm-info-div {
                    padding-right: 10vw;
                    padding-left: 10vw;
                    margin-top: 10vh;
                    margin-bottom: 10vw;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .psm-mid-div>p {
                    width: 80vw;
                    margin: 0 auto;
                    margin-top: 2.5vw;
                }

                video.psm-video {
                    width: 100% !important;
                    height: auto !important;
                }
            }

            @media only screen and (max-device-width: 480px) {
                .psm-hide {
                    visibility: hidden;
                }

                video.psm-video {
                    width: 100% !important;
                    height: auto !important;
                }

                .psm-info-div {
                    margin-right: 0;
                    margin-left: 0;
                    margin-top: 10vh;
                    margin-bottom: 10vw;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                }

                .psm-bottom-div {
                    background-color: #f4f4f4;
                    background-image: url('../img/Product-Support-and-Maintenance-Services/Componente-1-Movil.png');
                    background-attachment: scroll;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 100rem;
                    position: relative;
                    padding-top: 80vh;
                }

                #psm-rsbutton-bottom {
                    margin-left: 0%;
                }

                #psm-slider.slider-area.height-100 {
                    height: 55vw;
                }

                .psm-title-div {
                    text-align: center;
                    margin-top: 24vw;
                }
            }

            /* Blog */
            .internal-blog-title {
                position: relative;
                vertical-align: middle;
            }

            .internal-blog-title-img {
                position: absolute;
                top: -10vh;
                left: 0px;
            }

            @media only screen and (min-device-width: 1501px) {}

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {}

            @media only screen and (min-device-width: 990px) and (max-device-width: 1199px) {
                .internal-blog-title-img {
                    top: -5vh !important;
                }
            }

            @media only screen and (max-device-width: 990px) {
                .internal-blog-title-img {
                    top: 0vh !important;
                }

                .blog-gif {
                    width: 100vw;
                }
            }

            @media only screen and (min-device-width: 480px) and (max-device-width: 767px) {
                .blog-gif {
                    width: 100vw;
                }

                .internal-blog-title-img {
                    top: 5vh !important;
                }
            }

            @media only screen and (max-device-width: 480px) {
                .internal-blog-title-img {
                    top: 10vh !important;
                }
            }

            /* contact us */
            .contact-title {
                margin-top: 15vh;
                text-align: center;
            }

            .contact-title>div.shape {
                margin: auto;
                width: 100%;
                display: block !important;
                position: absolute;
                height: auto;
                left: -5%;
                top: 80vh;
                z-index: -1;
            }

            .contact-title>h3 {
                font-size: 2.2em;
                font-weight: 600;
            }

            .contact-title>h1 {
                line-height: 75%;
            }

            .contact-title>p {
                padding-right: 33vw;
                padding-left: 33vw;
                margin-top: 10vh;
                /* font-size: 1.3em !important; */
                line-height: 110%;
            }

            .contact-item-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                margin-top: 10vh;
                margin-bottom: 10vh;
            }

            .contact-shape {
                left: 15vw;
                position: absolute !important;
                right: 0;
                top: 110vh;
                z-index: -1;
            }

            .contact-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
            }

            .contact-item a {
                text-transform: lowercase;
            }

            .bottom-mid-contact-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 3vh;
                margin-bottom: 10vh;
                padding-top: 10vh;
                margin-bottom: 3vh;
            }

            .contact-card {
                border: 1px solid #A4A4A4;
                width: 50%;
                height: 12rem;
                padding: 1.2rem;
                margin: 0vh 5rem;
                position: relative;
            }

            .contact-card>a {
                position: absolute;
            }

            .contact-bg {
                background-color: #00add9;
            }

            .contact-equal {
                width: 100%;
                padding: 0;
            }

            .bottom-contact-container i {
                color: #ffffff;
                font-size: 45px;
                font-weight: 600;
                margin-bottom: 5vh;
            }

            .bottom-contact-container h2 {
                color: #ffffff;
                font-size: 45px;
                font-weight: 500;
            }

            .bottom-contact-container h3 {
                color: #ffffff;
                font-weight: 400;
                margin-bottom: 30px;
            }

            .contact-info-area .bottom-contact-container i {
                font-size: 90px;
                font-weight: normal;
            }

            @media only screen and (max-device-width: 1501px) {}

            @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {}

            @media only screen and (min-device-width: 990px) and (max-device-width: 1199px) {
                .contact-card {
                    height: 33vh;
                }
            }

            @media (max-width: 991px) {
                .contact-card {
                    height: 15rem;
                    margin-bottom: 1.3rem;
                }
            }

            @media (max-width: 768px) {
                .bottom-mid-contact-container {
                    display: flex;
                    flex-direction: column;
                }

                .contact-shape {
                    display: none;
                }

                .contact-item-container {
                    flex-direction: column;
                }

                .contact-item {
                    margin-bottom: 5vh;
                }

                .contact-title>p {
                    padding-right: 10vw;
                    padding-left: 10vw;
                    margin-top: 10vh;
                    font-size: 1.4em !important;
                    line-height: 110%;
                    margin-bottom: 10vh;
                }
            }

            @media only screen and (max-device-width: 990px) {
                .contact-card {
                    height: 30vh;
                    margin-bottom: 5vh;
                }

                .bottom-mid-contact-container {
                    display: flex;
                    flex-direction: column;
                }

                .contact-shape {
                    display: none;
                }

                .contact-item-container {
                    flex-direction: column;
                }

                .contact-item {
                    margin-bottom: 5vh;
                }

                .contact-title>p {
                    padding-right: 10vw;
                    padding-left: 10vw;
                    margin-top: 10vh;
                    font-size: 1.4em !important;
                    line-height: 110%;
                    margin-bottom: 10vh;
                }
            }

            @media only screen and (max-device-width: 900px) {
                .add-image-3 {
                    height: 50vh;
                }

                .contact-call-div {
                    height: 50vh;
                    padding-top: 0vh;
                    padding-bottom: 55vh;
                }

                .psm-item-image img {
                    margin-top: 2rem;
                    margin-bottom: 1rem;
                    width: 5rem;
                }

                .psm-item-image-hr {
                    width: 90%;
                }

                .psm-bottom-div {
                    background-color: #f4f4f4;
                    background-image: url('../img/Product-Support-and-Maintenance-Services/Componente-1-Movil.png');
                    background-attachment: scroll;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 100vh;
                    position: relative;
                    padding-top: 173vh;
                }

                .ux-item-text>h3 {
                    font-size: 1.8em !important;
                    text-transform: none;
                    text-align: center !important;
                }

                .ux-item-image {
                    margin-right: auto;
                    margin-left: auto;
                    text-align: center;
                }

                .ux-item-image img {
                    width: 4rem;
                    margin-top: 3rem;
                }

                .ux-item {
                    display: block;
                    align-items: baseline;
                }
            }

            @media only screen and (max-device-width: 900px) {
                .psm-bottom-div {
                    background-color: #f4f4f4;
                    background-image: url('../img/Product-Support-and-Maintenance-Services/Componente-1-Movil.png');
                    background-attachment: scroll;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 100vh;
                    position: relative;
                    padding-top: 400vh;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 40%;
                    padding: 0px;
                    margin-top: -170rem;
                    margin-bottom: 7%;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 40%;
                    padding: 0px;
                    margin-top: -80rem;
                    margin-bottom: 7%;
                }

                .ui .RSBtn {
                    background-color: #ff214f;
                    width: 50%;
                    padding: 0px;
                    margin-top: 0rem;
                    margin-bottom: 3%;
                }
            }

            @media only screen and (max-device-width: 576px) {
                .psm-bottom-div {
                    background-color: #f4f4f4;
                    background-image: url('../img/Product-Support-and-Maintenance-Services/Componente-1-Movil.png');
                    background-attachment: scroll;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: auto;
                    position: relative;
                    padding-top: 200vh;
                    width: 100%;
                }

                .RSBtn {
                    background-color: #ff214f;
                    width: 40%;
                    padding: 0px;
                    margin-top: -80rem;
                    margin-bottom: 7%;
                }

                .ui .RSBtn {
                    background-color: #ff214f;
                    width: 50%;
                    padding: 0px;
                    margin-top: 0rem;
                    margin-bottom: 3%;
                }
            }

            @media only screen and (min-device-width: 480px) and (max-device-width: 767px) {
                .contact-card {
                    width: 66vw;
                }

                .contact-call-div {
                    height: 50vh;
                    padding-top: 0vh;
                    padding-bottom: 55vh;
                }

                .contactus-button {
                    display: inherit !important;
                    margin: 0 auto;
                    background-color: #ff214f;
                }
            }

            @media only screen and (max-device-width: 480px) {
                .contact-card {
                    width: 80vw;
                }

                .contact-title>p {
                    padding-right: 3vw;
                    padding-left: 3vw;
                    margin-top: 10vh;
                    font-size: 1.4em !important;
                    line-height: 110%;
                    margin-bottom: 10vh;
                }

                .contact-call-div {
                    height: 100%;
                    padding-top: 0vh;
                    padding-bottom: 0vh;
                }

                .contactus-button {
                    display: inherit !important;
                    margin: 0 auto;
                    background-color: #ff214f;
                }
            }

            /* carlos end */
            /* carlos end */