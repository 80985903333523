/* modal */
.modal-content {
    padding: 1rem;
    background-color: transparent;
    border-radius: 0px;
    border: none;
}

.modal-backdrop.show {
    background-color: rgba(0, 172, 216, 0.9);
    backdrop-filter: blur(3px);
    opacity: 1;
    width: 100%;
}

.modal.show {
    width: 100%;
}

.modal-header {
    border-bottom: none;
    padding: 0rem;
}

.modal-header .modal-title {
    color: #3D87A1;
    font-family: Montserrat;
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.5rem;
    width: 100%;
    text-align: center;
}

.modal-body {
    padding: 0.5rem 1rem;
}

.modal-body p {
    color: #606060;
    font-family: Roboto-Light;
    font-size: 1rem;
    line-height: 1.2rem;
    text-align: justify;
}

.success-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
}

.success-animation i {
    color: green;
    font-size: 4em;
    margin-right: 10px;
    animation: successAnimation 0.5s ease forwards;
}

.success-animation p {
    font-size: 1.5em;
    opacity: 0;
    animation: successAnimation 0.5s ease forwards;
    animation-delay: 0.5s;
}

/* delete */
.modal-body .delete .title {
    padding-bottom: 1rem;
}

.modal-body .delete .title h3 {
    text-align: center;
    font-size: 1.4rem;
}

.modal-body .delete .decision {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-body .delete .decision button {
    margin: 0 5px;
}

.modal-body .delete .decision button.delete {
    font-family: Montserrat;
    background-color: #dc3545;
    color: #ffffff;
}

.modal-body .delete .decision button.close {
    font-family: AgencyFB;
    background-color: #a7a7a7;
    color: #ffffff;
}