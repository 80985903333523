@font-face {
    font-family: "Montserrat";
    font-style: normal;
    src: url("../font/Montserrat-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Regular";
    font-style: normal;
    src: url("../font/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Semibold";
    font-style: normal;
    src: url("../font/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    src: url("../font/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Medium";
    font-style: normal;
    src: url("../font/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins-Bold";
    font-style: normal;
    src: url("../font/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Myriad-Semibold";
    font-style: normal;
    src: url("../font/Myriad-Pro-Semibold.ttf") format("truetype");
}

@font-face {
    font-family: "Myriad-Regular";
    font-style: normal;
    src: url("../font/Myriad-Pro-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Myriad-Bold";
    font-style: normal;
    src: url("../font/Myriad-Pro-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Reboto-Ligth";
    font-style: normal;
    src: url("../font/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Reboto-Medium";
    font-style: normal;
    src: url("../font/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Reboto-Black";
    font-style: normal;
    src: url("../font/Roboto-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Reboto-Regular";
    font-style: normal;
    src: url("../font/Roboto-Regular.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&family=Raleway:wght@400;700&family=Roboto:wght@400;700&display=swap');

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
    font-family: "Montserrat", ;
    content: "Montserrat";
}

.ql-font-Montserrat {
    font-family: "Montserrat";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Montserrat-Semibold"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Montserrat-Semibold"]::before {
    font-family: "Montserrat-Semibold", ;
    content: "Montserrat-Semibold";
}

.ql-font-Montserrat-Semibold {
    font-family: "Montserrat-Semibold";
}

/* Set effect font-families */
body {
    font-family: Montserrat;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: none;
    }
}

.new-row-pd .row {
    --bs-gutter-x: 0rem !important;
}

#loading-wrap {
    background-color: #00add9;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 100000;
    top: 0;
    left: 0;
    transition: background-color 0.5s ease;
}

/* 
[ 2. Typography ]
==========================*/
p {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: #5c5c5c;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Montserrat;
    font-weight: 600;
    color: hwb(0 14% 86%);
    text-transform: capitalize;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

h1 {
    font-size: 2rem;
    font-weight: 800;
    font-family: Montserrat;
}

h2 {
    font-size: 30px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 15px;
    color: #ffffff;
}

h6 {
    font-size: 14px;
    font-weight: 400;
}

/* Link style
============== */
a {
    color: #232323;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1;
}

a,
a>* {
    outline: none;
    cursor: pointer;
    text-decoration: none;
}

a:focus,
a:hover {
    color: #fc345e;
    outline: none;
    text-decoration: none;
}

hr {
    color: inherit;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-top: 1px solid;
    opacity: .25;
    margin-top: 20px !important;
}

.nav-link:focus,
.nav-link:hover {
    color: white;
}

::selection {
    background: #ff214f;
    text-shadow: none;
    color: #ffffff;
}

.logo-menu a img {
    width: 9rem;
}

@media only screen and (max-width: 601px) {
    .container-menu {
        width: 90%;
    }
}

/* alert message */
.alert-message {
    font-size: 16px !important;
}

/* Transition
=============== */
a,
.navbar a,
.form-control {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.height-600 {
    height: 600px;
}

.gray-color {
    color: #757575;
}

/* CSS Customization
============================== */
ul,
ul li {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

/*Verticle Aligne Middle 
--------------------------*/
.d-table {
    display: table;
    width: 100%;
    height: 100%;
}

.dt-cell {
    display: table-cell;
    vertical-align: middle;
}

.v-align {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

/*padding-bottom
------------------*/
.pb-5 {
    padding-bottom: 5px;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-25 {
    padding-bottom: 25px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-35 {
    padding-bottom: 35px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-45 {
    padding-bottom: 45px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-80 {
    padding-bottom: 80px;
}

.row-eq-height {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

/*margin right
--------------*/
.mr-5 {
    margin-right: 5px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-25 {
    margin-right: 25px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-35 {
    margin-right: 35px;
}

.mr-40 {
    margin-right: 40px;
}

.mr-45 {
    margin-right: 45px;
}

.mr-50 {
    margin-right: 50px;
}

/*padding-top
--------------*/
.pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-35 {
    padding-top: 35px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-65 {
    padding-top: 65px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-170 {
    padding-top: 170px;
}

/*margin top
-------------*/
.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-90 {
    margin-top: 90px;
}

.mt-100 {
    margin-top: 100px;
}

/* percentage
--------------*/
.percent-20 {
    width: 20%;
}

.percent-25 {
    width: 25%;
}

.percent-33 {
    width: 33.333%;
}

.percent-50 {
    width: 50%;
}

.percent-66 {
    width: 66.666%;
}

.percent-100 {
    width: 100%;
}

.height-100 {
    height: 100%;
}

/* 
[ 3. Global Classes ]
====================================== */
.poppins {
    font-family: 'Poppins', sans-serif;
}

.montserrat {
    font-family: 'Montserrat', sans-serif;
}

.no-margin {
    margin: 0;
}

.no-gutter>[class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.no-padding {
    padding: 0;
}

.section-padding {
    padding: 150px 0;
}

.section-padding-1 {
    padding: 30px 0;
}

.section-padding-2 {
    padding: 2rem 0;
}

.section-pt {
    padding-top: 150px;
}

.section-pt-1 {
    padding-top: 30px;
}

.section-pt-2 {
    padding-top: 2rem;
}

.section-pb {
    padding-bottom: 150px;
}

.white-color {
    color: #ffffff;
}

.gray-color {
    color: #757575;
}

.theme-color {
    color: #00add9 !important;
}

.l-height {
    line-height: 1;
}

.mr-auto {
    margin-right: auto;
}

.justify-end {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.d-flex {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
}

.d-inblock {
    display: inline-block;
}

.column-center {
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

/*margin bottom
----------------*/
.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-45 {
    margin-bottom: 45px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-55 {
    margin-bottom: 55px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-65 {
    margin-bottom: 65px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-75 {
    margin-bottom: 75px;
}

.mb-85 {
    margin-bottom: 85px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-130 {
    margin-bottom: 130px;
}

/* colors */
.primary-color {
    color: #00add9 !important;
}

.primary-color-bg {
    background: #00add9 !important;
    color: #ffffff !important;
}

.white-color {
    color: #ffffff;
}

.gray-color {
    color: #757575;
}

.theme-color {
    color: #00add9 !important;
}

/* background color 
===============================*/
.white-bg {
    background-color: #ffffff;
}

.black-bg {
    background-color: #000000;
    /*background-color: #030d27;*/
}

.gray-bg {
    background-color: #f4f4f4;
}

.dark-gray-bg {
    background-color: #e4e4e4;
}

/* 
[ 6. Loding Bar ]
===================================*/
.loading-wrap {
    background-color: #00add9;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 100000;
    top: 0;
    left: 0;
    visibility: visible;
    opacity: 1;
}

.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 01s, opacity 01s linear;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
    opacity: 100;
}

.preloader .pre-container {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

.preloader .logo-circle {
    /*     position: relative;
    width: 256px;
    height: 256px;
    margin: 0 auto;
    border-radius: 50%;
    -webkit-animation: white-shadow 1s infinite;
    animation: white-shadow 1s infinite; */
}

.pre-container>img {
    margin-top: -52px;
    z-index: 5;
    position: relative;
}

/* Section Heading
----------------- */
.heading {
    -webkit-transform: perspective(1px) translateZ(0px);
    transform: perspective(1px) translateZ(0px);
}

.heading>h3 {
    font-family: 'Poppins', sans-serif;
    text-transform: lowercase;
}

.heading>div.shape {
    left: 120px;
    position: absolute !important;
    right: 0;
    top: -40px;
    z-index: -1;
    padding: 7%;
}

.top-header {
    padding: 150px 0px;
}

.heading {
    -webkit-transform: perspective(1px) translateZ(0px);
    transform: perspective(1px) translateZ(0px);
}

.login-create-help {
    font-size: 10px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.no-underline {
    text-decoration: none;
}

/* nav bar */
.nav-img-logo {
    width: 95%;
}

.fixed-nav {
    padding-top: 1rem;
    transition: all 0.4s ease 0s;
    position: fixed;
    width: 100%;
}

.fixed-nav-black {
    padding-top: 1rem;
    transition: all 0.4s ease 0s;
    position: fixed;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.scrolled {
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    -webkit-box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
    box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
    padding: 0;
    background-color: #111111 !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.navbar.fixed {
    left: 0;
    top: 0;
    width: 100%;
    z-index: 5;
    padding-top: 15px;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.mainmenu li a {
    display: block;
    font-size: 13px;
    font-weight: 800;
    line-height: inherit;
    padding: 6px 2px;
    letter-spacing: 0.40px;
    position: relative;
    text-transform: uppercase;
    color: #ffffff;
}

.mainmenu li a.active,
.mainmenu li:hover a {
    color: #ff214f !important
}

.btn-up {
    background-color: #ff214f;
    bottom: 32px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
    height: 40px;
    right: 8px;
    text-align: center;
    width: 35px;
    position: fixed;
    z-index: 2147483647;
    cursor: pointer;
    opacity: 1;
}

.btn-up:hover {
    background: #111111 none repeat scroll 0 0;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.btn-up i {
    color: #ffffff;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
}

.btn-up-no-visible {
    opacity: 0;
    transition: opacity 0.3s ease-out
}

@media (max-width: 991px) {
    .btn-up {
        background-color: #ff214f;
        bottom: 30px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
        height: 35px;
        right: 16px;
        text-align: center;
        width: 28px;
        position: fixed;
        z-index: 2147483647;
        cursor: pointer;
    }

    .btn-up i {
        color: #ffffff;
        font-size: 24px;
        line-height: 38px;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .btn-up {
        background-color: #ff214f;
        bottom: 30px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
        height: 30px;
        right: 16px;
        text-align: center;
        width: 24px;
        position: fixed;
        z-index: 2147483647;
        cursor: pointer;
    }

    .btn-up i {
        color: #ffffff;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
    }
}

.dropdown-menu {
    min-width: 3.56em !important;
}

dropdown-item:focus,
.dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: #1e2125;
}

.nav-link:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, 0);
}

navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: white;
}

/* end nav bar */
/* home banner */
.background-home-banneer {
    background-image: url("../img/bg/header_home.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

/* end home banner */
/* particles */
.bg-img-12 {
    background-image: url("../img/bg/header_home.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
}

.tspartilces {
    position: absolute;
    width: 100%;
    height: 45rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: 1;
}

.curve-bottom {
    left: 0;
    bottom: -2.8rem;
    height: 15rem;
    width: 100%;
    background-image: url("../img/shape/2.webp");
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    position: relative;
    background-attachment: scroll;
    background-size: cover;
}

@media screen and (min-width: 2001px) {
    .curve-bottom {
        bottom: 7rem;
        height: 25rem;
    }
}

@media (max-width: 991px) {
    .curve-bottom {
        bottom: -6rem;
        height: 15rem;
    }
}

@media (max-width: 900px) {
    .curve-bottom {
        bottom: -6rem;
        height: 15rem;
    }
}

@media screen and (max-width: 767px) {
    .curve-bottom {
        bottom: -0.8rem;
        height: 15rem;
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .curve-bottom {
        background-image: none;
    }

    .tspartilces {
        width: 100%;
        height: 35rem;
    }
}

@media screen and (max-width: 990px) {
    .slider-area.height-100 {
        height: 115%;
    }

    .slide-caption h1 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 15px;
        padding-top: 30px;
    }

    .style2.slide-caption h1 {
        font-size: 30px;
        line-height: 45px;
    }

    .style3.slide-caption h1 {
        font-size: 42px;
        line-height: 60px;
    }

    .slide-caption p {
        font-size: 15px;
        line-height: 26px;
    }

    .height-545 {
        height: 450px;
    }

    .pagetitle.height-545 {
        height: 250px;
    }

    .style4.slide-caption h1 {
        font-size: 40px;
        line-height: 50px;
        padding-top: 0;
        margin-bottom: 30px;
    }

    body#contact-us .curve-bottom {
        bottom: -3%;
    }
}

@media screen and (max-width: 767px) {
    .slider-area.height-100 {
        height: 115%;
    }

    .slide-caption h1 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 15px;
        padding-top: 30px;
    }

    .style2.slide-caption h1 {
        font-size: 30px;
        line-height: 45px;
    }

    .style3.slide-caption h1 {
        font-size: 42px;
        line-height: 60px;
    }

    .slide-caption p {
        font-size: 15px;
        line-height: 26px;
    }

    .height-545 {
        height: 450px;
    }

    .pagetitle.height-545 {
        height: 250px;
    }

    .style4.slide-caption h1 {
        font-size: 40px;
        line-height: 50px;
        padding-top: 0;
        margin-bottom: 30px;
    }

    body#contact-us .curve-bottom {
        bottom: -3%;
    }
}

.home-bg {
    margin-top: 13rem;
}

.home-bg h1 {
    line-height: 120px;
    font-weight: 800;
    font-size: 4rem;
    margin-bottom: 10px;
    color: #ffffff;
}

.home-bg p {
    line-height: 30px;
    font-weight: 300;
    font-size: 2rem;
    color: #ffffff;
    max-width: 55vw;
    margin: 20px auto;
    text-align: center
}

.home-bg .rt-btn {
    position: relative;
    background: #ff214f;
    border: 0 none;
    border-radius: 0px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    height: 48px;
    line-height: 48px;
    padding: 0 40px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: #ffffff;
    letter-spacing: 1px;
    z-index: 3;
}

.rt-btn {
    position: relative;
}

.rt-btn:before,
.rt-btn:after {
    position: absolute;
    width: 50%;
    height: 2px;
    background-color: #ff214f;
    content: "";
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.btn-slider:before {
    background-color: #ff214f;
}

.btn-slider:after {
    background-color: #ff214f;
}

.rt-btn:hover:before,
.rt-btn:hover:after {
    background-color: #ff214f;
    width: 100%;
}

.rt-btn:before {
    right: 0;
    top: -6px;
}

.rt-btn:after {
    left: 0;
    bottom: -6px;
}

@media (max-width: 1200px) {
    .home-bg h1 {
        font-size: 3rem;
    }
}

@media screen and (max-width: 991px) {
    .home-bg {
        margin-top: 13rem;
    }

    .home-bg h1 {
        line-height: 50px;
        font-weight: 800;
        font-size: 3rem;
        margin-bottom: 0px;
        color: #ffffff;
        text-align: center;
    }

    .home-bg p {
        line-height: 40px;
        font-weight: 300;
        font-size: 1.3rem;
        color: #ffffff;
        max-width: 80vw;
        margin: 30px auto;
        text-align: justify;
    }

    .home-bg .rt-btn {
        margin-top: 30px;
        position: relative;
        background: #ff214f;
        border: 0 none;
        border-radius: 0px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        height: 48px;
        line-height: 48px;
        padding: 0 40px;
        -webkit-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        color: #ffffff;
        letter-spacing: 1px;
        z-index: 3;
    }
}

@media screen and (max-width: 767px) {
    .home-bg {
        margin-top: 10rem;
    }

    .home-bg h1 {
        line-height: 90px;
        font-weight: 800;
        font-size: 3.8rem;
        margin-bottom: 0px;
        color: #ffffff;
        text-align: center;
    }

    .home-bg p {
        line-height: 30px;
        font-weight: 300;
        font-size: 1.3rem;
        color: #ffffff;
        max-width: 80vw;
        margin: 40px auto;
        text-align: justify;
    }

    .home-bg .rt-btn {
        margin-top: 30px;
        position: relative;
        background: #ff214f;
        border: 0 none;
        border-radius: 0px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        height: 48px;
        line-height: 48px;
        padding: 0 40px;
        -webkit-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        color: #ffffff;
        letter-spacing: 1px;
        z-index: 3;
    }
}

@media screen and (max-width: 480px) {
    .home-bg {
        margin-top: 10rem;
    }

    .home-bg h1 {
        line-height: 70px;
        font-weight: 800;
        font-size: 2.8rem;
        margin-bottom: 20px;
        color: #ffffff;
        text-align: center;
    }

    .home-bg p {
        line-height: 30px;
        font-weight: 300;
        font-size: 1.3rem;
        color: #ffffff;
        max-width: 80vw;
        margin: 30px auto;
        text-align: justify;
    }

    .home-bg .rt-btn {
        position: relative;
        background: #ff214f;
        border: 0 none;
        border-radius: 0px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        height: 48px;
        line-height: 48px;
        padding: 0 40px;
        -webkit-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        color: #ffffff;
        letter-spacing: 1px;
        z-index: 3;
    }
}

/* Home content */
.home {}

.home .about-home {
    margin-bottom: 1rem;
    margin-top: 5%;
}

.home .about-home-slide {
    margin-bottom: 5%;
    margin-top: 5%;
}

.home .about-home-slide h3 {
    font-family: "Montserrat", sans-serif;
    color: #00add9 !important;
    text-transform: capitalize !important;
    font-size: 2.2em;
    font-weight: 500;
}

.home .about-home-slide h1 {
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize !important;
    font-size: 2.13em;
    font-weight: 800;
}

.home .shape {
    margin: auto;
    width: 77%;
    display: block !important;
    position: absolute;
    height: 20%;
    left: 13%;
    margin-top: -.7rem;
}

.home .about-home h3 {
    font-family: "Montserrat", sans-serif;
    color: #00add9 !important;
    text-transform: capitalize !important;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 54px;
}

.home .about-home h1 {
    text-transform: capitalize !important;
    font-weight: 800;
    line-height: 54px;
}

.home .about-home span {
    color: #00add9 !important;
}

.home .card {
    width: 18rem;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
    padding: 1rem;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.07);
}

.home .card h4 {
    font-family: Poppins;
    font-weight: 600;
    color: black;
}

.home .card p {
    font-family: Montserrat;
    font-size: 1.2em !important;
    line-height: 30px;
}

.bg-img-13 {
    background-image: url("../img/bg/best_platforms.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.carousel-item img {
    padding: 3rem;
}

@media (max-width: 992px) {
    .carousel-item img {
        padding: 1rem;
    }
}

@media (max-width: 768px) {
    .integrate .carousel-item img {
        padding: 1rem !important;
        width: 10rem;
    }
}

.top-services .content {
    padding: 5rem;
    margin-top: 3rem !important;
}

/* services */
.top-service-padding {
    padding-top: 12rem !important;
    padding-bottom: 0rem !important;
}

.services-top-shape {
    position: relative;
}

.services-top-shape img {
    position: absolute;
    top: -5vh;
}

.services-title h3 {
    line-height: 20%;
    font-size: 1.8em;
    font-weight: 500;
    color: #00add9 !important;
    margin: 0 0 15px;
    text-transform: capitalize;
    transition: all 0.3s ease;
}

.services-title h1 {
    font-size: 2.75em;
    font-weight: 600;
    font-family: 'Montserrat';
}

.card-services-section {
    margin-top: 0%;
}

.card-services {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    transition: 0.3s;
    border-radius: 5px 5px 30px 30px;
    word-wrap: break-word;
    overflow: visible;
    padding-right: calc(var(--bs-gutter-x) * .0);
    padding-left: calc(var(--bs-gutter-x) * .0);
}

.card-services .card-image {
    height: 30vh;
    overflow: hidden;
}

.card-services .card-image img {
    transition: ease-in-out .5s;
}

.card-services .card-image img:hover {
    transform: scale(1.25);
}

.card-services .services-description {
    padding: 10% 2.5% 0% 1.3vw;
}

.card-services .services-description h3 {
    font-size: 1.4em;
}

.card-services .services-description p {
    font-size: 1.2em !important;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -.025em;
}

.card-services .services-description-button p {
    margin-top: 5vw;
    font-family: "Montserrat";
    color: #606060;
    font-weight: 300;
    margin-top: 10%;
    margin-bottom: 5%;
}

.card-services .services-description-button a {
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: #232323;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    line-height: 1;
}

.card-services .services-description-button a:hover {
    color: #00acd8;
}

.card-services .services-description-button b {
    font-weight: bold;
}

.card-services .services-description-button .fa {
    font-size: 1px !important;
}

/* NEW CARD SERVICES */
.new-cards-services .container .row {
    justify-content: center !important;
}

.new-cards-services .pd-box {
    padding: 2rem;
}

.new-cards-services .rounded {
    border-radius: 1.35rem !important;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.new-cards-services .box {
    overflow: hidden;
    position: relative;
    margin-top: 2rem;
}

.new-cards-services .box .image-container {
    position: relative;
    overflow: hidden;
}

.new-cards-services .box .image-container img {
    height: 28rem;
    width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    z-index: 1;
}

.new-cards-services .box:hover .image-container img {
    transform: scale(1.1);
}

.new-cards-services .box .image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    pointer-events: none;
    z-index: 2;
}

.new-cards-services .box .description {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
    padding-top: 18rem;
    z-index: 3;
    color: white;
}

.new-cards-services .box .description h5 {
    font-size: 1.1rem;
    text-align: left;
    font-family: Montserrat-Regular;
    font-weight: 100;
    position: absolute;
    bottom: 8.5rem;
    width: 90%;
}

.new-cards-services .box .description hr {
    color: white;
    opacity: 1;
}

.new-cards-services .box .description p {
    font-family: Reboto-Regular;
    font-size: .9rem !important;
    line-height: 1.4rem;
    color: white;
}

.new-cards-services .box .description a {
    font-family: Reboto-Medium !important;
    font-size: .8rem;
    float: right;
    padding-right: 20px;
    padding-top: 1rem;
    color: white;
}

.new-cards-services .box .description a:hover {
    color: #ff214f !important;
}

@media (min-width: 1200px) {
    .new-cards-services .box .image-container img {
        height: 35rem;
    }

    .new-cards-services .box .description {
        padding-top: 25rem;
    }

    .new-cards-services .box .description h5 {
        bottom: 9rem;
    }
}

@media (max-width: 992px) {
    .new-cards-services .pd-box {
        padding: 0em;
    }
}

.our-process {
    background-image: url("../img/bg/01.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0;
}

.process-service {
    padding-top: 4rem;
}

.our-process .process-title h3 {
    color: #ffffff !important;
}

.our-process .process-title h1 {
    color: #ffffff !important;
    font-weight: 800 !important;
    text-transform: capitalize !important;
    font-size: 2.13em !important;
}

.process-service .step-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10rem;
    padding-top: 7rem;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

.process-service .circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0px;
}

.process-service .circle {
    width: 80px;
    height: 80px;
    border: 1px solid #676766;
    color: #676766;
    font-family: Myriad-Regular;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
}

.process-service .step-text {
    text-align: center;
}

.process-service .step-text h5 {
    font-family: Reboto-Medium;
    color: #606060;
    font-size: 1rem;
    margin-top: 1.3rem;
}

.process-service .step-text p {
    font-family: Reboto-Ligth;
    color: #606060;
    font-size: .8rem !important;
    margin-top: .5rem !important;
    width: 12rem;
    line-height: 20px;
}

.process-service .dotted-line {
    flex-grow: 1;
    border-top: 1px dashed #676766;
    margin: 50px 10px;
}

.single-service-cicle {
    margin-left: .5rem;
    margin-right: .5rem;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}

.single-service-cicle img {
    width: 4rem;
}

@media (max-width: 991px) {
    .single-service-cicle {
        width: 100%;
        display: none !important;
    }

    .single-service-cicle img {
        width: 6rem;
        padding: 0rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media (max-width: 768px) {}

.single-service {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 5px 5px 3px rgba(0, 0, 0, 0.07);
    border-color: transparent;
}

.single-service .relative {
    padding: 1rem;
}

.single-service h4 {
    color: #ffffff;
    font-family: "Poppins";
}

.single-service .icon {
    width: 60px;
    float: left;
    margin-right: 15px;
}

@media (max-width: 991px) {
    .single-service {
        margin-top: 2rem;
        margin-left: 2rem;
    }
}

@media (max-width: 768px) {
    .single-service {
        margin-top: 2rem;
        margin-left: 0rem;
    }
}

.best-service-area {
    background-color: #ffffff;
    padding: 80px 0;
}

.best-service-area .shape {
    padding: 7%;
    height: 20%;
    left: 16%;
    z-index: -1;
    display: block !important;
    margin: auto;
}

.best-service-area .shape img {
    max-width: 100%;
}

.shape-title h1 {
    margin-bottom: 10%;
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize !important;
    font-size: 2rem;
    font-weight: 800;
}

.custom-tab .nav-tabs li a {
    border-radius: 0;
    margin: 0;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    line-height: 35px;
    padding: 0 10px;
    border-bottom: 2px solid #232323 !important;
    border: none;
    color: #000000;
}

.custom-tab .nav-tabs li a:active {
    background-color: transparent;
    border-color: #00add9;
    color: #00add9;
}

.custom-tab .nav-tabs li a:focus {
    border-bottom: 2px solid #ff214f;
}

.custom-tab .nav-tabs li button {
    border-radius: 0;
    margin: 0;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 600;
    line-height: 35px;
    padding: 0 10px;
    border-bottom: 2px solid #232323 !important;
    border: none;
    color: #000000;
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.custom-tab .nav-tabs li button:active {
    background-color: transparent;
    border-color: #00add9;
    color: #00add9;
}

.custom-tab .nav-tabs li button:focus {
    border-bottom: 2px solid #ff214f;
}

.custom-tab .nav-tabs .active {
    background-color: transparent;
    border-color: #00add9;
    color: #00add9;
    border-bottom: 2px solid #ff214f !important;
}

.tab-content p {
    line-height: 25px;
    margin-right: -20px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem !important;
    color: #606060;
    font-weight: 300;
}

.tab-container-img {
    padding: 3rem;
}

.tab-container-img .img06 {
    border-radius: 8px;
    box-shadow: 4px 4px 12px gray;
}

@media (max-width: 991px) {
    .img06 {
        padding: 0rem;
    }
}

@media (max-width: 768px) {
    .img06 {
        padding: 0rem;
        margin-bottom: 2rem;
    }
}

/* end services */
/* about */
.top-about-padding {
    padding: 200px 0px;
}

.about-top-shape {
    position: relative;
}

.about-top-shape img {
    position: absolute;
    top: -5vh;
    left: 12vh;
}

.about-title h3 {
    line-height: 20%;
    font-size: 1.8em;
    font-weight: 500;
    color: #00add9 !important;
    margin: 0 0 15px;
    text-transform: capitalize;
    transition: all 0.3s ease;
}

.about-title h1 {
    margin-bottom: 10%;
    font-family: "Montserrat";
    text-transform: capitalize !important;
    font-size: 1.8rem;
    font-weight: 800;
    line-height: 54px;
}

.about-title p {
    font-size: 1.3em !important;
    color: #606060;
    font-weight: 300;
    font-family: 'Montserrat';
    line-height: 32px;
}

.card-about-section {
    margin-top: 0%;
}

.bg-img-16 {
    padding: 150px 0;
    background-image: url("../img/bg/about.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-2 h1 {
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize !important;
    font-size: 2em;
    font-weight: 800;
}

.bg-img-17 {
    background-image: url("../img/bg/about05.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0;
}

.testimonial-about {
    border-radius: 8px;
    background-color: #ffffff;
    padding-bottom: 35px;
}

.testimonial-about .item {
    padding: 85px 50px 0 70px;
    display: block
}

.testimonial-about .item p {
    color: #313131;
    font-size: 18px;
    line-height: 31px !important;
    margin-top: 0;
    font-family: "Montserrat";
    font-size: 1.4em !important;
    font-weight: 300;
}

.testimonial-about-profile {
    padding: 50px 50px 0 70px;
}

.testimonial-about-profile img {
    border-radius: 50%;
    width: 80px;
}

.testimonial-about-profile .img-profile {
    width: 20%;
}

.testimonial-about-profile .name {
    display: block;
    width: 60%;
}

.testimonial-about-profile h4 {
    color: #2f2f2f;
    font-weight: 700;
    font-family: "Poppins";
    margin-bottom: 0px;
}

.testimonial-about-profile h6 {
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    color: #00add9 !important;
    margin-bottom: 0px;
}

.margin-about-profile {
    margin-bottom: 15px;
}

/* end about */
/* Blog */
.bg-img-25 {
    background-image: url("../img/blog/Animation/BACKGROUND-IMAGE-2.jpg");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.bg-img-25-1 {
    height: 28rem;
}

@media (max-width: 767px) {
    .bg-img-25-1 {
        height: 25rem;
    }
}

.d-table {
    display: table;
    width: 100%;
    height: 100%;
}

.dt-cell {
    display: table-cell;
    /*  vertical-align: bottom; */
}

.blog-section {
    padding-bottom: 70px;
    padding-top: 5rem;
}

.blog-post {
    margin-bottom: 75px;
}

.blog-post img {
    margin-bottom: 50px;
}

/* Blog Section */
/* blog details */
.bg-img-2 {
    background-image: url("../img/blog/Animation/BACKGROUND-IMAGE-1.jpg");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* .internal-blog-title-img {
    position: absolute;
    top: -10vh;
    left: 0px;
}

.single-post .content p img {
    width: 100%;
}

.single-post .content>p {
    color: #6e6e6e;
    font-size: 1.5em;
    line-height: 32px;
    width: 95%;
}

.single-post .content h1 {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.single-post .content h3 {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.single-post .content p {
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em !important;
    color: #6e6e6e;
    font-weight: 400
}

.single-post .content ul li {
    list-style: disc;
    color: #6e6e6e;
    font-family: Montserrat, sans-serif;
    font-size: 1.3em !important;
    font-weight: 400;
}
 */
blockquote p {
    color: #525252;
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 15px;
}

blockquote.gray-bg i {
    color: #565656;
    font-size: 44px;
    margin-bottom: 10px;
}

blockquote>h4 {
    color: #3e3e3e;
    font-weight: 700;
}

.single-post blockquote {
    border: 0 none;
    padding: 45px 0;
}

blockquote hr.line {
    background-color: #3e3e3e;
    margin: 0 auto;
    width: 40px;
}

.tag-share strong {
    color: #3e3e3e;
    font-size: 16px;
    font-weight: 600;
}

.tag-share a {
    color: #6e6e6e;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding-left: 5px;
    text-decoration: none;
}

.single-post .divider hr.line {
    background-color: #d8d8d8;
}

.tag-share .social-icon {
    position: relative;
    top: 5px;
}

.tag-share .social-icon li a {
    color: #575757;
}

.tag-share .social-icon li a:hover,
.tag-share a:hover {
    color: #ff214f;
}

.social-tag ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.social-tag ul li {
    list-style: outside none none !important;
}

.pull-left {
    float: left;
}

/* end blog details */
.style1 .blog-post {
    -webkit-box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
    box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
}

.blog-area .blog-post {
    border: 1px solid #dddddd;
}

.blog-area .blog-post .content {
    padding: 0px 50px 50px 50px;
}

body#index .blog-area .blog-post .content {
    padding: 30px 50px 50px 50px;
}

.blog-area .blog-post .content a.read-more {
    font-size: 15px;
    font-weight: 700;
    color: #232323;
    text-decoration: none;
}

.blog-area .blog-post .content a.read-more:hover {
    color: #fc345e;
    outline: none;
    text-decoration: none;
}

.blog-area .blog-post .content hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

.blog-area .blog-post .content .title-date,
.blog-area .blog-post .content .title-text {
    display: inline-block;
}

.blog-area .blog-post .content .title-text {
    vertical-align: top;
}

.blog-area .blog-post .content .title-date {
    padding: 10px 20px;
    border: 2px solid #232323;
    border-radius: 10px;
    margin-right: 10px;
}

.blog-area .blog-post .content a {
    text-decoration: none;
}

.blog-area .blog-post .content .title-date h5 {
    margin: 0;
    color: #232323;
}

.blog-area .blog-post .content .title-date h5.day {
    font-size: 32px;
    font-weight: bolder;
}

.blog-area .blog-post .content p {
    color: #232323;
    line-height: 28px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3em !important;
    font-weight: 300;
}

.blog-area .blog-post .post-info {
    display: block;
}

.blog-area .blog-post .post-info .info {
    display: inline-block;
    margin-right: 15px;
}

.blog-area .blog-post .post-info .info span {
    vertical-align: middle;
    font-size: 12px;
    color: #898989;
}

.blog-area .blog-post .post-info .info i {
    font-size: 18px;
    vertical-align: middle;
    margin-right: 2px;
}

.blog-area .blog-post .post-info .info.comments i {
    color: #2BE35A;
}

.blog-area .blog-post .post-info .info.likes i {
    color: #FC009D;
}

.blog-area .blog-post .post-info .info.hour i {
    color: #767475;
}

.blog-post .thumb img {
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.blog-post:hover .thumb img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.blog-post .content>h5 {
    font-size: 14px;
}

.blog-post .content h4 {
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-weight: 700;
    line-height: 26px;
}

.blog-post .content a:hover h4 {
    color: #ff214f;
}

.blog-post .content>p {
    color: #7f7f7f;
    font-size: 14px;
    line-height: 25px;
}

@media (max-width: 991px) {
    .blog-area .blog-post .content .title-date {
        padding: 25px 20px;
        border: 2px solid #232323;
        border-radius: 10px;
        margin-right: 10px;
    }

    .blog-area .blog-post .post-title .title-date {
        display: block;
    }
}

@media (max-width: 768px) {
    .blog-area .blog-post .content .title-date {
        padding: 20px 20px;
        border: 2px solid #232323;
        border-radius: 10px;
        margin-right: 50px;
        margin-left: 50px;
    }

    .blog-area .blog-post .post-title .title-date h5 {
        font-size: 1.2rem;
        margin-left: 4px;
    }
}

@media (max-width: 576px) {
    .blog-section {
        padding-bottom: 70px;
        padding-top: 2rem;
    }

    .blog-area .blog-post .content .title-date {
        padding: 15px 15px;
        border: 2px solid #232323;
        border-radius: 10px;
        margin-right: 20px;
        margin-left: 20px;
    }

    .blog-area .blog-post .content .title-date h5.day {
        font-size: 26px;
        font-weight: bolder;
    }

    .blog-area .blog-post .post-title .title-date h5 {
        font-size: 1.2rem;
        margin-left: 4px;
    }
}

/* end blog */
/* Comment Area */
.admin-box {
    border: 1px solid #d8d8d8;
    padding: 35px 30px 35px 40px;
}

.commenter-img,
.admin-img {
    width: 100px;
}

.comment-list .text h3,
.admin-box .text h3 {
    color: #3e3e3e;
    font-size: 20px;
    font-weight: 700;
}

.admin-box .text>h6 {
    font-weight: 600;
    margin-bottom: 3px;
}

.comment-area .title h4,
.comment-list .text h3,
.admin-box .text h3,
.admin-box .text>h6 {
    font-family: "Poppins", sans-serif;
}

.admin-box .text>h6,
.comment-list .text>p,
.admin-box .text>p {
    color: #6e6e6e;
}

.comment-list .text>p,
.admin-box .text>p {
    font-size: 16px;
    line-height: 26px;
}

.comment-area .title h4 {
    color: #3e3e3e;
    font-weight: 700;
    text-transform: uppercase;
}

.comment-area .title hr.line {
    background-color: #3e3e3e;
    width: 60px;
}

.reply-btn a {
    border: 1px solid #ececec;
    border-radius: 5px;
    color: #343434;
    display: block;
    font-size: 12px;
    font-weight: 600;
    height: 26px;
    line-height: 24px;
    padding: 0 20px;
}

.reply-btn a:hover {
    background-color: #ff214f;
    border-color: #ff214f;
    color: #ffffff;
}

.comment-list li,
.comment-list .text,
.admin-box .text {
    overflow: hidden;
}

.comment-list li {
    margin-bottom: 40px;
}

.comment-list li:last-child {
    margin-bottom: 0px;
}

.comment-list li.reply {
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    margin-left: 125px;
    margin-top: -10px;
    padding: 40px 0;
}

.comment-box .custom-input input,
.comment-box .custom-input textarea {
    background-color: #fcfcfc;
    border-color: #ececec;
    border-radius: 5px;
    color: #707070;
    height: 46px;
    line-height: 46px;
}

.comment-box .custom-input textarea {
    height: 125px;
}

.audio-post iframe {
    width: 100%;
}

/* Pages Pagination */
.rt-pagination ul {
    display: inline-block;
    margin-bottom: -9px;
    list-style: none;
}

.rt-pagination li {
    float: left;
    margin: 0 5px;
    margin-bottom: 10px;
}

.rt-pagination li a {
    border: 1px solid #dddddd;
    border-radius: 5px;
    color: #2e2e2e;
    display: block;
    font-weight: 400;
    height: 43px;
    line-height: 39px;
    width: 50px;
    font-size: 15px;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}

.rt-pagination-disable li a {
    border: 1px solid #dddddd;
    border-radius: 5px;
    color: #2e2e2e;
    display: block;
    font-weight: 400;
    height: 43px;
    line-height: 39px;
    width: 50px;
    font-size: 15px;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}

.rt-pagination-disable a,
.rt-pagination-disable li:hover a {
    border-color: #6c757d !important;
    color: #6c757d !important;
    --bs-pagination-hover-bg: #fff !important;
}

.rt-pagination li#prev a,
.rt-pagination li#next a {
    width: auto;
    padding: 0 15px;
}

.rt-pagination .active>a,
.rt-pagination li:hover a {
    border-color: #ff214f;
    color: #ff214f;
    --bs-pagination-hover-bg: #fff !important;
}

.rt-btn2 {
    background-color: #ff214f !important;
    border: 0 none;
    border-radius: 0px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    height: 48px;
    line-height: 48px;
    padding: 0 40px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: #ffffff;
    letter-spacing: 1px;
    color: #ffffff !important;
}

.add-image {
    background-image: url("../img/bg/services.webp");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
}

.single-service-2 {
    border-radius: 10px;
    border-color: transparent;
}

.mb130 {
    margin-bottom: 130px
}

@media screen and (max-width: 900px) {
    .mb130 {
        margin-bottom: 0px
    }

    .add-image {
        background-image: url("../img/bg/services.webp");
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        width: 100%;
        height: 30vh;
    }
}

.single-service-2 .relative {
    padding: 1rem;
}

.single-service-2 .icon {
    width: 3px;
    float: left;
    margin-right: 5.5rem;
}

.single-service-2 .icon img {
    width: 60px;
}

.single-service-2 .desc {
    overflow: hidden;
    box-sizing: border-box;
}

.single-service-2 .desc h4 {
    font-size: 16px;
    font-weight: 600;
    color: #00add9 !important;
    font-family: "Poppins";
}

.single-service-2 .desc p {
    color: #858585;
    line-height: 26px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.4rem !important;
    font-weight: 500;
}

.section-padding-service {
    padding: 100px 0;
}

.servces-start h1 {
    line-height: 52px;
    text-transform: inherit;
    margin-top: -6px;
}

.servces-start h1 span {
    color: #00add9 !important;
}

/* NEW SERVICES */
.services-new {
    position: relative;
}

.services-new .row {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: 0px !important;
    --bs-gutter-x: 0px !important;
}

.services-new .top {
    width: 2rem;
    position: absolute;
    top: -20px;
    left: 30px;
}

.services-new .description {
    background-color: #1E283C;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3.5rem;
}

.services-new .description img {
    width: 7rem;
    margin-left: 1rem;
}

.services-new .description p {
    font-family: Myriad-Regular;
    color: #00ADD8;
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
    font-weight: 600;
    margin-top: 1rem;
    width: 90%;
}

.services-new .description h3 {
    font-family: Myriad-Regular;
    color: #ffffff;
    font-size: 0.8rem;
    line-height: 1.1rem;
    margin-top: 1rem;
    font-weight: 400;
    text-align: justify;
    width: 79%;
}

/* BOX SERVICES NEW */
.services-new .services .box {
    position: relative;
}

.services-new .services .box img {
    width: 100%;
}

.services-new .services .box-hover img {
    width: 100%;
}

.services-new .services .box .title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    background-color: rgba(30, 40, 60, 0.7);
}

.services-new .services .box .title img {
    width: 2rem;
    margin-right: 1rem;
}

.services-new .services .box .title h6 {
    color: #ffffff;
    font-family: Myriad-Regular;
    font-size: .9rem !important;
    padding-top: 10px;
    z-index: 3;
}

.services-new .services .box .title-hover {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    background-color: rgba(0, 165, 214, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.services-new .services .box .title-hover img {
    width: 3rem;
    margin-right: 0rem;
    padding-bottom: 1rem;
}

.services-new .services .box .title-hover h6 {
    font-family: Myriad-Regular;
    color: #ffffff;
    font-size: 1.2rem !important;
    text-transform: capitalize;
}

.services-new .services .box .title-hover p {
    color: #ffffff;
    font-family: Reboto-Ligth !important;
    font-size: .9rem !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    line-height: 20px;
    padding-bottom: .5rem;
    font-weight: 600;
}

.services-new .services .box .title-hover a {
    color: #FF214F;
    font-family: Reboto-Medium;
    margin-top: .5rem !important;
    text-transform: capitalize;
}

@media (min-width: 1201px) {
    .services-new .description {
        padding-top: 11%;
    }
}

@media (max-width: 1200px) {
    .services-new .description {
        padding-top: 7%;
    }
}

@media (max-width: 992px) {
    .services-new .description {
        text-align: left;
        padding-top: 2rem;
        padding-bottom: 4rem;
        padding-left: 4rem;
        margin-bottom: 1.5rem;
        height: 30rem;
    }

    .services-new .top {
        width: 2rem;
        position: relative;
        top: -11rem;
        left: 0rem !important;
        width: 2rem !important;
    }

    .services-new .description img {
        width: 8rem;
        padding-top: 4rem;
        margin-left: 1rem;
    }

    .services-new .description img {
        width: 6rem;
    }

    .services-new .description p {
        font-size: 1.2rem !important;
        line-height: 1.2rem !important;
        font-weight: 600;
        margin-top: 1rem;
    }

    .services-new .description h3 {
        width: 70%;
        font-size: 1rem;
        line-height: 1.2rem;
        margin-top: 1rem;
        font-weight: 400;
        text-align: justify;
    }

    .services-new .services .box img {
        width: 100%;
    }

    .services-new .services .box .title-hover {
        padding-top: 0rem;
        height: 100%;
    }

    .services-new .services .box .title-hover img {
        width: 3rem;
    }

    .services-new .services .box .title-hover h6 {
        font-size: 1rem !important;
    }

    .services-new .services .box .title-hover p {
        color: #ffffff;
        font-family: Reboto-Ligth !important;
        font-size: .8rem !important;
        padding-left: 2rem;
        padding-right: 2rem;
        line-height: 20px;
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 768px) {
    .services-new .description {
        text-align: left;
        padding-top: 4rem;
        padding-bottom: 4rem;
        padding-left: 4rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        height: 35rem;
    }

    .services-new .top {
        width: 2rem;
        position: relative;
        top: -13rem;
        left: 0rem !important;
        width: 2rem !important;
    }

    .services-new .description img {
        width: 8rem;
        padding-top: 4rem;
        margin-left: -1rem;
    }

    .services-new .description p {
        font-size: 1.2rem !important;
        line-height: 1.2rem;
        font-weight: 600;
        margin-top: 1rem;
    }

    .services-new .description h3 {
        width: 70%;
        font-size: 1rem;
        line-height: 1.3rem;
        margin-top: 1rem;
        font-weight: 400;
        text-align: justify;
    }

    .services-new .services .box img {
        max-height: 30rem;
    }

    .services-new .services .box .title-hover {
        padding-top: 1rem;
        height: 100%;
    }

    .services-new .services .box .title-hover img {
        width: 4rem;
        padding-bottom: 2rem;
    }

    .services-new .services .box .title-hover h6 {
        font-size: 1rem !important;
        padding-bottom: .5rem;
    }

    .services-new .services .box .title-hover p {
        color: #ffffff;
        font-family: Reboto-Ligth !important;
        font-size: .8rem !important;
        padding-left: 2rem;
        padding-right: 2rem;
        line-height: 20px;
        padding-bottom: 2rem;
    }
}

@media (max-width: 576px) {
    .services-new .description {
        text-align: left;
        padding-top: 1rem;
        padding-bottom: 4rem;
        padding-left: 4rem;
    }

    .services-new .top {
        width: 2rem;
        position: relative;
        top: -13rem;
        left: 0rem !important;
        width: 2rem !important;
        text-align: left;
    }

    .services-new .description img {
        width: 8rem;
        padding-top: 4rem;
        margin-left: -1rem;
    }

    .services-new .description p {
        font-size: 1.2rem !important;
        line-height: 1.3rem;
        font-weight: 600;
        margin-top: 1rem;
    }

    .services-new .description h3 {
        width: 100%;
        font-size: 1rem;
        line-height: 1.4rem;
        margin-top: 1rem;
        font-weight: 100;
        text-align: justify;
    }

    .services-new .services .box img {
        max-height: 30rem;
    }

    .services-new .services .box .title-hover {
        padding-top: 0rem;
        height: 100%;
    }

    .services-new .services .box .title-hover img {
        width: 4rem;
        padding-bottom: 1rem;
    }

    .services-new .services .box .title-hover h6 {
        font-size: 1rem !important;
        padding-bottom: 0rem;
    }

    .services-new .services .box .title-hover p {
        color: #ffffff;
        font-family: Reboto-Ligth !important;
        font-size: .8rem !important;
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 20px;
        padding-bottom: .5rem;
    }
}

/* FOOTER */
footer {
    background-image: url("../img/bg/bg-footer.webp");
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
}

.footer .footer-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.footer .colum {
    padding: 2rem;
}

.footer .list {
    margin-top: 2rem;
}

.footer .logo {
    width: 13rem;
}

.footer .list ul {
    margin-left: 1rem;
}

.footer .list ul li {
    text-transform: capitalize;
    font-family: Myriad-Regular;
    text-align: start;
    margin-left: 60px;
    /*     margin-left: 25px;
    margin-right: 30%; */
}

.footer .list ul li a {
    color: #ffffff;
    font-size: .9rem;
    text-transform: capitalize;
    font-family: Myriad-Regular;
    text-align: start;
    /*     margin-left: 20px;
    margin-right: 30%; */
}

.footer .list ul li a:hover {
    color: #ff214f;
}

.footer .newsletter p {
    text-transform: uppercase;
    font-weight: 600;
}

.footer .suscribe {
    margin-top: 0px;
    padding-left: 3rem;
    text-transform: uppercase;
}

.footer .suscribe .box {
    padding-left: 1rem;
}

.footer .suscribe p {
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0px;
    font-size: .8rem !important;
    font-family: Myriad-Semibold;
    color: #ffffff;
}

.footer form input {
    font-family: Myriad-Regular;
    height: 2.5rem;
    border-radius: 10px;
    border: 1px solid #606060;
    background-color: #ffffff;
}

.custom-input-group {
    position: relative;
}

.custom-input-group form input.form-control {
    padding-right: 38px;
    font-size: Myriad-Regular;
    color: #606060;
    margin-bottom: 10px;
}

.custom-input-group .form-control {
    font-family: Myriad-Regular !important;
    color: #606060 !important;
    font-size: 0.8rem !important;
}

.custom-input-group .form-control::placeholder {
    font-family: Myriad-Regular !important;
    color: #606060 !important;
    font-size: 0.8rem !important;
    opacity: 0.5;
}

.custom-input-group {
    border-collapse: separate;
}

.custom-input-group .btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    color: white !important;
    background-color: #00ACD8 !important;
    width: 30%;
    text-transform: uppercase;
    height: 2.5rem;
    border-radius: 10px;
    padding: 0 15px;
    font-family: Myriad-Semibold !important;
    line-height: 0px;
}

.custom-input-group .btn:disabled {
    background-color: #ccc !important;
    color: #666;
    cursor: not-allowed;
    opacity: 1;
}

.footer .dashed-border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1.5px inset #ffffff;
    height: 120px;
    margin: 0 auto;
    margin-top: 2.5rem;
}

.footer .copyrigth {
    background-color: #0D141E;
    height: 50px;
}

.footer .copyrigth p {
    padding: 10px;
    color: white;
    font-family: Myriad-Regular;
    font-size: 15px !important;
}

.footer .social-links {
    width: 100%;
}

.footer .social-links ul li {
    margin-left: .7rem;
}

.footer .social-links ul li a img {
    width: 1.9rem;
}

.footer .social-links ul li a {
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid white;
    color: white;
    background-color: transparent;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer .social-links ul li a:hover {
    border: 1px solid #fc345e;
    color: #fc345e
}

.footer .social-links ul li a svg {
    color: #fff;
}

.footer .social-links ul li a:hover svg {
    color: #fc345e
}

.recaptcha {
    transform: scale(0.8);
    transform-origin: 0 0;
}

.footer .alert p {
    font-size: .5rem !important;
    font-family: Myriad-Semibold;
    color: #000000;
    letter-spacing: 0px !important;
}

.footer .alert button {
    width: 10px;
    background-color: transparent !important;
    color: #000000 !important;
}

@media (max-width: 992px) {
    .footer-container {
        padding-top: 3rem;
    }

    .footer .colum {
        padding: 0rem !important;
        margin-top: 6rem;
    }

    .footer .list ul {
        text-align: center;
        padding-top: 2rem;
    }

    .footer .list ul li {
        text-align: center;
        margin-right: 0px;
        font-size: 1rem;
        margin-left: 0px;
    }

    .footer .list ul li a {
        text-align: center;
    }

    .footer p {
        text-align: center;
    }

    .footer .colum {
        padding: 2rem;
    }

    .footer .dashed-border {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: transparent;
        height: 100px;
        margin: 0 auto;
        margin-top: 0px !important;
    }

    .footer .suscribe {
        margin-top: 10rem;
        padding-left: 0rem;
        text-transform: uppercase;
        width: 50%;
    }

    .footer .suscribe p {
        text-align: left;
    }

    .footer .logo {
        padding-top: 3rem;
        width: 15rem;
    }

    .footer .social-links {
        margin-top: 2rem;
        width: 100%;
        text-align: center;
        padding-bottom: 2rem;
    }
}

@media (max-width: 768px) {
    .footer .footer-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .footer .logo {
        padding-top: 5rem;
        width: 12rem;
    }

    .footer .list ul {
        text-align: center;
        padding-top: 1%;
    }

    .footer .suscribe {
        margin-top: 10rem;
        padding-left: 0rem;
        text-transform: uppercase;
        width: 90%;
    }

    .footer .colum {
        padding: 0rem !important;
        margin-top: 8rem;
    }
}

@media (max-width: 576px) {
    .footer .logo {
        padding-top: 3rem;
        width: 12rem;
    }

    .footer .footer-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .footer .copyrigth {
        background-color: #0D141E;
        height: 77px;
    }
}

/* login */
.form-signin {
    font-size: 13px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    max-width: 430px;
    padding: 15px;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

/*   cms */
.cms {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

aside {
    width: 20%;
    background-color: lightgray;
    padding: 10px;
}

main {
    flex-grow: 1;
    padding: 10px;
}

.cms .menu {
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

.cms .nav-bg {
    background-color: rgba(0, 0, 0, 0.1);
}

/* Social Icons
----------------------*/
.social-icon li {
    float: left;
}

.social-icon li a {
    display: block;
    font-size: 14px;
    margin: 0 10px;
    padding: 0;
    position: relative;
    text-align: center;
    z-index: 2;
    color: #2c2c2c;
}

.social-icon li a:hover {
    color: #ff214f;
}

/* Style One */
.social-icon.style1 li a {
    font-size: 20px;
    height: 42px;
    width: 42px;
    line-height: 42px;
    margin: 0 5px;
    color: #ffffff;
}

.social-icon.style1 li a::after {
    background-color: #ff214f;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    width: 100%;
    z-index: -1;
}

.social-icon.style1 li a:hover::after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.social-icon.style1 li a {
    border-radius: 50%;
}

.social-icon.style1 li a:hover {
    color: #ffffff;
}

.social-icon.style2 li a {
    color: #232323;
    font-size: 16px;
    margin: 0 3px;
    width: 40px;
}

.social-icon.style2 li a:hover {
    color: #ff214f;
}

.social-icon.style2 li:first-child a {
    margin-left: 0;
}

.social-icon.style2 li a::after {
    background-color: #ff214f;
    top: -10px;
    content: "";
    height: 2px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 0px;
}

.social-icon.style2 li a:hover::after {
    width: 11px;
}

/* background image 
==================================*/
.bg-img-0 {
    background-image: url("../img/favicon.png");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.bg-img-1 {
    background-image: url("../img/bg/1.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
}

.bg-img-2 {
    background-image: url("../img/blog/Animation/BACKGROUND-IMAGE-1.jpg");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-3 {
    background-image: url("../img/bg/3.webp");
    background-attachment: scroll;
    background-position: center 70%;
    background-repeat: no-repeat;
}

.bg-img-4 {
    background-image: url("../img/bg/4.webp");
    background-attachment: scroll;
    background-position: left center;
    background-repeat: no-repeat;
}

.bg-img-5 {
    background-image: url("../img/bg/5.webp");
    background-attachment: scroll;
    background-position: center left;
    background-repeat: no-repeat;
}

.bg-img-6 {
    background-image: url("../img/bg/6.webp");
    background-attachment: scroll;
    background-position: top center;
    background-repeat: no-repeat;
}

.bg-img-7 {
    background-image: url("../img/bg/7.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-8 {
    background-image: url("../img/bg/8.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-9 {
    background-image: url("../img/bg/9.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-10 {
    background-image: url("../img/bg/10.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-11 {
    background-image: url("../img/bg/11.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-12 {
    background-image: url("../img/bg/header_home.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 41.9rem;
    width: 100%;
}

@media screen and (max-width: 576px) {
    .bg-img-12 {
        height: 35rem;
        width: 100%;
    }
}

.bg-img-13 {
    background-image: url("../img/bg/best_platforms.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-14 {
    background-image: url("../img/bg/services.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-15 {
    background-image: url("../img/bg/01.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-16 {
    background-image: url("../img/bg/about.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-17 {
    background-image: url("../img/bg/about05.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-18 {
    background-image: url("../img/contactus/Banner--Contac-Us2.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.bg-img-19 {
    background-image: url("../img/bg/header_blog.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-20 {
    background-image: url("../img/bg/video_pattern.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-21 {
    background-image: url("../img/app-development/Banner-Web-Services.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-22 {
    background-image: url("../img/WebServices/Banner-Web-Services.webp");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-23 {
    background-image: url("../img/User-Experience/Banner-User\ Experience.jpg");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-24 {
    background-image: url("../img/Product-Support-and-Maintenance-Services/Banner.jpg");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img-25 {
    background-image: url("../img/blog/Animation/BACKGROUND-IMAGE-2.jpg");
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-pattern-1 {
    background-image: url("../img/bg/pattern/1.png");
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-pattern-2 {
    background-image: url("../img/bg/pattern/2.png");
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-pattern-2 {
    background-image: url("../img/bg/pattern/2.png");
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
}

.add-image {
    background-image: url("../img/bg/services.webp");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
}

.add-image-2 {
    background-image: url("../img/bg/add-2.webp");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
}

.add-image-3 {
    background-image: url("../img/contactus/Call.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position: center;
}

.error-image {
    background-attachment: scroll;
    background-image: url("../img/bg/error.webp");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: auto 100%;
}

/* About Section */
.style3 .image .shape,
.style1 .image .shape {
    bottom: 200px;
    position: absolute;
    left: -40px;
    width: 100%;
}

.image.relative>img {
    position: relative;
    z-index: 2;
}

.video-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-col>div {
    position: relative;
    text-align: center;
}

.video-btn.circle>a {
    /* background-color: #ff214f; */
    background-color: #ffffff;
    border-radius: 50%;
    /* color: #ffffff; */
    color: #ff214f;
    display: block;
    float: left;
    font-size: 18px;
    height: 53px;
    line-height: 53px;
    text-align: center;
    width: 53px;
    -webkit-animation: white-shadow 1s infinite, red-shadow 1s infinite;
    animation: white-shadow 1s infinite, red-shadow 1s infinite;
}

.video-btn>h5 {
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    overflow: hidden;
    padding: 18px 0 0 15px;
}

.video-btn.circle.custom-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-element {
    width: 100%;
    border-radius: 8px;
    box-shadow: 2px 2px 18px gray;
}

@media (max-width: 1200px) {
    .video-element {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .video-element {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .video-element {
        width: 100%;
    }
}

.about-text h1 {
    line-height: 54px;
}

.style1 .about-text {
    padding-left: 50px;
    margin-right: -40px;
}

.style2 .video-btn {
    border-bottom: 3px dashed #ececec;
}

.style2 .video-btn.circle>a {
    -webkit-animation-name: white-shadow, blue-shadow;
    animation-name: white-shadow, blue-shadow;
    background-color: #00add9;
    display: inline-block;
    float: none;
    position: relative;
    top: 26.5px;
}

.style3.about-area .heading>div.shape {
    left: 50px;
}

/* Services */
.single-service .service-bg,
.single-service.overlay::before {
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.single-service {
    border-radius: 10px;
    -webkit-box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
    box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
    overflow: hidden;
    -webkit-transform: perspective(1px) translateZ(0px);
    transform: perspective(1px) translateZ(0px);
}

.single-service-h {
    height: 30rem;
    margin-top: 2rem;
}

@media screen and (min-width: 1200px) {
    .single-service-h {
        height: 25rem;
    }
}

@media screen and (max-width: 992px) {
    .single-service-h {
        height: 25rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .single-service-h {
        height: 20rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 576px) {
    .single-service-h {
        height: 22rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 1200) {
    .single-service {
        border-radius: 10px;
        -webkit-box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
        box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
        overflow: hidden;
        -webkit-transform: perspective(1px) translateZ(0px);
        transform: perspective(1px) translateZ(0px);
        min-height: 130vh;
    }
}

@media screen and (max-width: 900) {
    .single-service {
        border-radius: 10px;
        -webkit-box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
        box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
        overflow: hidden;
        -webkit-transform: perspective(1px) translateZ(0px);
        transform: perspective(1px) translateZ(0px);
        min-height: 20vh;
    }
}

@media screen and (max-width: 767) {
    .single-service {
        border-radius: 10px;
        -webkit-box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
        box-shadow: 0 5px 10px 0 rgba(33, 29, 30, 0.15);
        overflow: hidden;
        -webkit-transform: perspective(1px) translateZ(0px);
        transform: perspective(1px) translateZ(0px);
        max-height: 10vh;
    }
}

.single-service.overlay::before {
    opacity: 0;
    border-radius: 10px;
}

.service-bg.bg-1 {
    background-image: url(../img/service/1.jpg);
}

.service-bg.bg-2 {
    background-image: url(../img/service/2.jpg);
}

.service-bg.bg-3 {
    background-image: url(../img/service/3.jpg);
}

.service-bg.bg-4 {
    background-image: url(../img/service/4.jpg);
}

.single-service .service-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: -1;
}

.single-service:hover .service-bg,
.single-service:hover.overlay::before {
    opacity: 1;
}

.single-service .text {
    padding: 100px 20px;
}

.single-service .relative {
    z-index: 2;
}

.single-service i {
    color: #ff214f;
    font-size: 60px;
    margin-bottom: 15px;
}

.single-service-item h3 {
    font-size: 1.8rem !important;
    color: #00add9 !important;
    text-transform: capitalize !important;
    font-size: 2.2em;
    font-weight: 500;
    line-height: 54px
}

.single-service h4 {
    font-family: "Poppins", sans-serif;
}

.single-service p {
    color: #575656;
    font-size: 1em;
    line-height: 25px;
}

@media screen and (max-width: 900px) {
    .single-service-item h3 {
        font-family: "Montserrat", sans-serif;
        color: #00add9 !important;
        text-transform: capitalize !important;
        font-size: 2.5em;
        font-weight: 500;
        line-height: 54px
    }

    .single-service h4 {
        font-family: "Poppins", sans-serif;
        font-size: 1.5em;
    }

    .single-service p {
        color: #000000;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        font-size: 15px !important;
        font-weight: 400;
    }
}

.single-service:hover i,
.single-service:hover h4,
.single-service:hover p {
    color: #ffffff;
}

.services-area.style2 .row {
    margin: 0 50px;
}

.services-area.style2 .single-service .text {
    padding: 35px 10px 30px 10px;
}

.services-area .circles-service .text {
    padding: 90px 0;
}

.services-area .brand-logo ul li img {
    width: 70px;
    text-align: center;
}

.style2 .single-service {
    border: 2px solid #1d263d;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.style2 .single-service:hover {
    background-color: #030d27;
    border-color: transparent;
}

.style2 .single-service i,
.style2 .single-service h4,
.style2 .single-service p {
    color: #000000;
}

.style2 .single-service.services i,
.style2 .single-service.services h4,
.style2 .single-service.services p {
    color: #ffffff;
}

.theme-bg.style2 .single-service {
    border-color: #5d41a5;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.theme-bg.style2 .single-service:hover {
    background-color: #5d41a5;
}

.bg-style.style2 .single-service {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: 2px 1px 5px 4px rgba(0, 0, 0, 0.07);
}

.bg-style.style2 .single-service {
    background-color: rgba(255, 255, 255, .08);
}

.bg-style.style2 .single-service.services {
    background-color: rgba(255, 255, 255, 0.2);
}

.bg-style.style2 .single-service:hover {
    background-color: rgba(255, 255, 255, .15);
}

.height-600 {
    height: 600px;
}

.equal-style {
    padding: 0 170px 0 120px;
}

.style3 .single-service .icon {
    width: 60px;
    float: left;
    margin-right: 15px;
}

.style3 .single-service .desc {
    overflow: hidden;
}

.style3 .single-service i,
.style3 .single-service h4 {
    color: #ffffff;
}

.style3 .single-service i {
    font-size: 40px;
    margin-bottom: 0;
    line-height: 38px;
}

.style3 .single-service h4 {
    font-size: 16px;
    font-weight: 500;
}

.style3 .single-service p {
    color: #858585;
    font-size: 14px;
    line-height: 26px;
}

/* Default Button Style
------------------------ */
.btn {
    background-color: #09d4de;
    border: 0 none;
    border-radius: 0px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    height: 48px;
    line-height: 48px;
    padding: 0 40px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    color: #ffffff;
    letter-spacing: 1px;
}

.btn-slider {
    background: #ff214f;
}

/* .lg-btn {} */
.btn.lg-btn {
    width: auto;
    background-color: white;
    color: black;
    border: 1px solid #000000;
}

.btn.sm-btn {
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    letter-spacing: 0px;
    line-height: 40px;
    padding: 0 25px;
}

/* .btn.sm-btn:focus,
.btn.sm-btn:hover {}

.btn.white {}

.btn.white:focus {} */
.btn:hover {
    background-color: #ff214f;
}

.btn i.material-icons {
    font-size: 20px;
}

.btn-cms-active {
    color: #ffffff;
    border-color: #ff214f;
    background-color: #ff214f;
    border-color: #ff214f;
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: #ffffff;
    border-color: #ff214f;
}

.btn:focus,
button:focus {
    outline: none !important;
}

.btn.border {
    background-color: transparent;
    border: 2px solid #ffffff;
}

.btn.border:hover {
    background-color: #ff214f;
    border-color: #ff214f;
}

.rt-btn {
    position: relative;
}

.rt-btn:before,
.rt-btn:after {
    position: absolute;
    width: 50%;
    height: 2px;
    background-color: #ff214f;
    content: "";
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.btn-slider:before {
    background-color: #ff214f;
}

.btn-slider:after {
    background-color: #ff214f;
}

.rt-btn:hover:before,
.rt-btn:hover:after {
    background-color: #ff214f;
    width: 100%;
}

.rt-btn:before {
    right: 0;
    top: -6px;
}

.rt-btn:after {
    left: 0;
    bottom: -6px;
}

.rt-btn2 {
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0px);
    transform: perspective(1px) translateZ(0px);
}

.rt-btn2 {
    background-color: #ff214f;
}

.rt-btn2::after {
    background-color: #ce002b;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scale(0.7, 0.5);
    -ms-transform: scale(0.7, 0.5);
    transform: scale(0.7, 0.5);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    width: 100%;
    z-index: -1;
}

.rt-btn2:hover::after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

/* Custom Input 
----------------------*/
select {
    -moz-appearance: none;
    -webkit-appearance: none;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    opacity: 1;
    color: #888888;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
    opacity: 1;
    color: #888888;
}

select:focus,
input:focus,
textarea:focus {
    outline: 0px;
}

.custom-select {
    position: relative;
}

.custom-input input,
.custom-input textarea,
.custom-select select {
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-bottom: 30px;
    padding: 0 20px;
    line-height: 54px;
    height: 54px;
    color: #888888;
    font-weight: 400;
    width: 100%;
    background-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.custom-input input:focus,
.custom-input textarea:focus,
.custom-select select:focus {
    border-color: #ff214f;
}

.custom-input textarea {
    height: 130px;
}

.contact-form .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0.7;
    display: none;
    z-index: 99;
}

.contact-form .loader i {
    top: 30%;
    left: 50%;
    position: absolute;
}

/* Social Links */
.social-link li {
    float: left;
    margin-right: 25px;
}

.social-link li:last-child {
    margin-right: 0;
}

.social-link a {
    /*color: #6c7281;*/
    color: #eeeeee;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}

.social-link a:hover {
    color: #ffffff;
}

/* Social Icons
----------------------*/
.social-icon li {
    float: left;
}

.social-icon li a {
    display: block;
    font-size: 14px;
    margin: 0 10px;
    padding: 0;
    position: relative;
    text-align: center;
    z-index: 2;
    color: #2c2c2c;
}

.social-icon li a:hover {
    color: #ff214f;
}

/* Style One */
.social-icon.style1 li a {
    font-size: 20px;
    height: 42px;
    width: 42px;
    line-height: 42px;
    margin: 0 5px;
    color: #ffffff;
}

.social-icon.style1 li a::after {
    background-color: #ff214f;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    width: 100%;
    z-index: -1;
}

.social-icon.style1 li a:hover::after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.social-icon.style1 li a {
    border-radius: 50%;
}

.social-icon.style1 li a:hover {
    color: #ffffff;
}

.social-icon.style2 li a {
    color: #232323;
    font-size: 16px;
    margin: 0 3px;
    width: 40px;
}

.social-icon.style2 li a:hover {
    color: #ff214f;
}

.social-icon.style2 li:first-child a {
    margin-left: 0;
}

.social-icon.style2 li a::after {
    background-color: #ff214f;
    top: -10px;
    content: "";
    height: 2px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 0px;
}

.social-icon.style2 li a:hover::after {
    width: 11px;
}

/* Horizontal Line 
-------------------*/
.horizontal-line div {
    background: #878787 none repeat scroll 0 0;
    height: 2px;
    margin: 0;
    width: 76px;
}

.horizontal-line div.top {
    margin-bottom: 4px;
    width: 180px;
}

.center .horizontal-line div {
    margin: 0 auto;
}

.center .horizontal-line div.top {
    margin-bottom: 4px;
}

hr.line {
    background-color: #ff214f;
    border: 0 none;
    height: 2px;
    margin: 0;
    width: 20px;
}

.divider hr.line {
    background-color: #ff214f;
    width: 100%;
    height: 1px;
}

.divider hr.line.white {
    background-color: rgba(255, 255, 255, .20);
    width: 100%;
}

/* Vertical Shape */
.v-shape {
    background-color: #222429;
    display: block;
    height: 40px;
    margin: 10px auto;
    position: relative;
    width: 2px;
}

.v-shape::after,
.v-shape::before {
    background-color: #222429;
    bottom: 0;
    content: "";
    height: 24px;
    margin: auto 6px;
    position: absolute;
    top: 0;
    width: 2px;
}

.v-shape::after {
    right: 0;
}

.v-shape::before {
    left: 0;
}

.v-shape.white,
.v-shape.white::after,
.v-shape.white::before {
    background-color: #ffffff;
}

.copyright>p {
    font-size: 14px;
    font-weight: 300;
}

.copyright>p a {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}

.copyright>p a:hover {
    color: #ff214f;
}

/* Small and Tablet Screen Support */
@media (max-width: 767px),
(min-width: 768px) and (max-width: 991px) {
    .navbar-header {
        display: block;
    }

    .mainmenu nav.navbar-collapse {
        background: #000000 none repeat scroll 0 0;
        display: block;
        opacity: 0;
        padding: 60px 0;
        position: absolute;
        right: -15px;
        top: 110%;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        visibility: hidden;
        width: 290px !important;
    }

    .mainmenu nav.navbar-collapse.in {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .mainmenu nav ul li {
        float: none;
        margin-bottom: 10px;
        padding: 0 15px;
    }

    #active-sticky.is-sticky {
        padding: 10px 0;
    }

    .is-sticky .navbar-toggle {
        top: 12px;
    }
}

/* Popup Scale */
.scale-toggle {
    display: block;
    color: #ffffff;
}

.transparent-white .scale-toggle {
    color: #222429;
}

.scale-toggle i {
    display: block;
    font-size: 13px;
    line-height: inherit;
    margin: 0 25px;
}

.scale-toggle i.fa-bars {
    font-size: 17px;
}

.search-box button,
.scale-toggle {
    cursor: pointer;
}

.overlay-scale {
    background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
    bottom: 0;
    height: 100vh;
    left: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
    visibility: hidden;
    width: 100%;
    z-index: 9999999;
}

.open .overlay-scale {
    opacity: 1;
    visibility: visible;
}

.open .wrapper,
body.open {
    height: 100vh;
}

body.open {
    overflow-y: scroll;
}

.open .wrapper {
    overflow: hidden;
}

.close-scale {
    cursor: url(../img/icons/close.png), auto;
    height: 100%;
    position: absolute;
    width: 100%;
}

/* Search Box Popup */
.overlay-scale form.search-box {
    bottom: 0;
    height: 60px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    max-width: 100%;
    width: 600px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    -webkit-transform: scale(1.6);
    -ms-transform: scale(1.6);
    transform: scale(1.6);
}

.open .overlay-scale form.search-box {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.overlay-scale .search-box input {
    border: 0 none;
    font-style: italic;
    line-height: 60px;
    padding: 0 20px;
    width: 100%;
    font-size: 20px;
}

.overlay-scale .search-box button {
    background-color: #ff214f;
    border: 0 none;
    color: #ffffff;
    font-size: 22px;
    line-height: 2.71;
    position: absolute;
    right: 0;
    top: 23px;
    width: 70px;
}

.is-sticky .overlay-scale .search-box button {
    top: 8px;
}

.overlay-scale .search-box button {
    -webkit-padding-after: 0px;
}

/*Screen Support
--------------------*/
@media screen and (max-width: 767px) {
    .overlay-scale form.search-box {
        width: 95%;
    }

    .overlay-scale .search-box button {
        top: 13px;
    }

    .is-sticky .overlay-scale .search-box button {
        top: 3px;
    }
}

/* Sticky Sticky
-----------------*/
.navbar-toggler {
    border: 0 !important;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}

.navbar-light .navbar-toggler {
    background-color: #007bff;
    border-color: rgba(255, 0, 0, 0.85);
}

header.is-sticky {
    line-height: 80px;
}

.is-sticky .logo .visible {
    display: none !important;
}

.is-sticky .logo .hidden {
    display: inline-block !important;
}

#active-sticky.is-sticky {
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    -webkit-box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
    box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
    padding: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.is-sticky {
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    -webkit-box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
    box-shadow: 0 1px 4px rgba(2, 3, 3, 0.15);
    padding: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.header-top.is-sticky .mainmenu a:hover,
.header-top.is-sticky .mainmenu li.active a {
    color: #ff214f;
}

#active-sticky.transparent-white.is-sticky {
    background-color: #ffffff;
}

/* Small and Tablet Screen Support */
@media screen and (max-width: 991px) {
    .navbar-header {
        display: block;
    }

    .mainmenu .navbar-collapse {
        background: #000000 none repeat scroll 0 0;
        display: block;
        opacity: 1;
        padding: 10px 0;
        position: absolute;
        right: -15px;
        top: 110%;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        visibility: visible;
        width: 290px !important;
        padding-bottom: 2rem;
    }

    .mainmenu .navbar-collapse.in {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navbar-collapse-out {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .mainmenu ul li {
        float: none;
        margin-bottom: -10px;
        padding: 0 15px;
    }

    .mb-dropdown ul li {
        float: none;
        margin-bottom: -20px;
        padding: 0 15px;
    }

    .mb-dropdown li a {
        color: #ff214f !important;
    }

    #active-sticky.is-sticky {
        padding: 10px 0;
    }

    .is-sticky .navbar-toggle {
        top: 12px;
    }

    .mainmenu li a {
        display: block;
        font-size: 13px;
        font-weight: 800;
        line-height: inherit;
        padding: 25px 2px;
        letter-spacing: 0.40px;
        position: relative;
        text-transform: uppercase;
        color: #ffffff;
    }
}

@media (max-width: 767px) {
    .navbar-header {
        display: block;
    }

    .mainmenu .navbar-collapse {
        background: #000000 none repeat scroll 0 0;
        display: block;
        opacity: 1;
        padding: 10px 0;
        position: absolute;
        right: -15px;
        top: 110%;
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        visibility: visible;
        width: 290px !important;
        padding-bottom: 2rem;
    }

    .mainmenu .navbar-collapse.in {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navbar-collapse-out {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .mainmenu ul li {
        float: none;
        margin-bottom: -10px;
        padding: 0 15px;
    }

    .mb-dropdown ul li {
        float: none;
        margin-bottom: -20px;
        padding: 0 15px;
    }

    .mb-dropdown li a {
        color: #ff214f !important;
    }

    #active-sticky.is-sticky {
        padding: 10px 0;
    }

    .is-sticky .navbar-toggle {
        top: 12px;
    }

    .mainmenu li a {
        display: block;
        font-size: 13px;
        font-weight: 800;
        line-height: inherit;
        padding: 10px 2px;
        letter-spacing: 0.40px;
        position: relative;
        text-transform: uppercase;
        color: #ffffff;
    }
}

/* Header Section */
header {
    /*  line-height: 110px; */
}

/* header .logo {
    width: 120px;
} */
header.fixed {
    left: 0;
    top: 0;
    width: 100%;
    z-index: 5;
    padding-top: 15px;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.wrapper {
    width: 100%;
}

.wrapper .side-menu {
    height: 100%;
    padding-left: 90px;
    width: 330px;
    z-index: 200;
}

.side-menu header {
    line-height: 1;
}

/*Screen Support
--------------------*/
@media screen and (max-width: 767px) {
    header {
        line-height: 50px;
    }

    header.fixed {
        padding-top: 0;
        z-index: 99;
    }

    header .logo {
        /*  width: 250px; */
    }

    #active-sticky.is-sticky {
        padding: 0;
    }

    header.is-sticky {
        line-height: 70px;
    }
}

/* Small and Tablet Screen Support */
@media (max-width: 767px),
(min-width: 768px) and (max-width: 991px) {

    /* Right Side Menu */
    .side-menu header {
        padding: 25px 15px;
        position: relative;
        z-index: 999;
    }

    .side-menu header .logo {
        padding-top: 0;
    }

    .side-menu .navbar-toggle {
        right: 0px;
        top: 20px;
    }

    .side-menu .mainmenu {
        line-height: 1;
        padding: 0;
    }

    .wrapper .side-menu {
        float: none !important;
        height: auto;
        padding-left: 0;
        position: unset;
        width: 100%;
    }

    .side-menu .mainmenu li a {
        color: #ffffff;
    }

    .side-menu .mainmenu nav.navbar-collapse {
        right: 15px;
    }

    .side-menu .mainmenu li a.active,
    .side-menu .mainmenu li a:hover {
        color: #222429;
    }

    .side-menu .mainmenu .navigation>li {
        line-height: 36px;
    }
}

/* Slider Section */
.order-item-1 {
    order: '1';
}

.order-item-2 {
    order: '2';
}

@media screen and (max-width: 900px) {
    .order-item-1 {
        order: '0';
    }

    .order-item-2 {
        order: '0';
    }

    .order-item-mob-1 {
        order: '1' !important;
    }

    .order-item-mob-2 {
        order: '2' !important;
    }
}

.terms-conditions header {
    background-color: #fff !important;
    height: 10rem;
    width: 100%;
    margin-top: 7rem;
}

.terms-conditions .privacy-policy header h1 {
    color: #00add9;
    font-family: 'Montserrat', sans-serif;
    font-size: 4rem;
    padding-top: 10rem;
}

.terms-conditions .content {
    padding-bottom: 1.5rem;
}

.terms-conditions .content h2 {
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize !important;
    font-size: 1.5em;
    font-weight: 800;
    line-height: 54px;
    color: #232323;
}

.terms-conditions .content ul li {
    list-style: inside;
    font-family: "Montserrat", sans-serif;
    font-size: 1em !important;
    color: #606060;
    font-weight: 300;
}

.terms-conditions .content p {
    font-family: "Montserrat", sans-serif;
    font-size: 1em !important;
    color: #606060;
    font-weight: 300;
}

@media (max-width: 991px) {
    .terms-conditions header {
        background-color: #fff !important;
        height: 12rem;
        width: 100%;
        margin-top: 7rem;
    }

    .terms-conditions .privacy-policy header h1 {
        text-align: center;
    }

    .terms-conditions .content h2 {
        text-align: center;
    }
}

.whatsapp-fixed {
    bottom: 50px;
    position: fixed;
    right: 50px;
    z-index: 10000;
}

.whatsapp-fixed-no {
    bottom: 40px;
    position: fixed;
    right: 15px;
    z-index: 10000;
}

.whatsapp-fixed img {
    width: 4rem;
    position: absolute;
    z-index: 10;
    margin-right: .2rem;
    align-items: end;
    text-align: right;
    margin-top: -2rem;
    transition: all 0.9s ease;
}

.whatsapp img {
    width: 4rem;
    position: absolute;
    z-index: 10;
    margin-right: 0rem;
    align-items: end;
    text-align: right;
    margin-top: -2rem;
    transition: all 0.9s ease;
}

@media (max-width: 991px) {
    .whatsapp-fixed {
        bottom: 100px;
        position: fixed;
        right: 0px;
        bottom: 7rem;
        z-index: 10000;
    }

    .whatsapp-fixed img {
        width: 3.5rem;
        position: absolute;
        z-index: 10;
        margin-right: .2rem;
        align-items: end;
        text-align: right;
        margin-top: -1rem;
        transition: all 1s ease;
    }

    .whatsapp img {
        width: 4rem;
        position: absolute;
        z-index: 10;
        margin-right: 0rem;
        align-items: end;
        text-align: right;
        margin-top: 0rem;
        bottom: -2rem;
        transition: all 1s ease;
    }

    @media screen and (max-width: 767px) {
        .whatsapp-fixed-no {
            bottom: 40px;
            position: fixed;
            right: 15px;
            z-index: 10000;
        }

        .whatsapp-fixed {
            bottom: 100px;
            position: fixed;
            right: 5px;
            bottom: 6rem;
            z-index: 10000;
        }

        .whatsapp-fixed img {
            width: 3rem;
            position: absolute;
            z-index: 10;
            margin-right: 0rem;
            align-items: end;
            text-align: right;
            margin-top: -1rem;
            transition: all 1s ease;
        }

        .whatsapp img {
            width: 4rem;
            position: absolute;
            z-index: 10;
            margin-right: -.5rem;
            align-items: end;
            text-align: right;
            margin-top: 0rem;
            bottom: -1rem;
            transition: all 1s ease;
        }
    }
}

/* NEW SERVICES */
.pt-essb {
    padding-top: 10rem;
    max-width: 1480px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    margin-bottom: 3rem;
}

.services-essb .row {
    --bs-gutter-x: 0rem;
}

.services-essb .img {
    position: relative;
}

.services-essb .img .top {
    position: absolute;
    width: 2rem;
    text-align: end;
    right: 4rem;
    top: -1rem;
}

.services-essb .img-bg {
    width: 100%;
    border-radius: 8px;
    box-shadow: 4px 4px 12px gray;
}

.services-essb .header {
    position: relative;
    display: flex;
}

.services-essb .header .shape {
    position: relative;
}

.services-essb .header .shape img {
    position: absolute;
    width: 10rem;
    margin-left: 7rem;
}

.services-essb .header .title img {
    position: absolute;
    width: 3rem;
    margin-left: 5rem;
    padding-top: 5rem;
}

.services-essb .header .title h6 {
    color: #00ACD8;
    font-family: Montserrat-Regular;
    font-size: 1.3rem;
    margin-left: 11rem;
    padding-top: 1.9rem;
    letter-spacing: 2px;
    margin-bottom: 0.2 rem !important;
    text-transform: capitalize;
}

.services-essb .header .title h4 {
    color: #54565A;
    font-family: Montserrat-Semibold;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-left: 11rem;
    padding-top: 0rem;
    margin-bottom: 3rem;
    text-transform: capitalize;
    font-weight: 100 !important;
}

.services-essb .header .title p {
    color: #606060;
    font-family: Reboto-Ligth !important;
    font-size: 1.2rem !important;
    margin-left: 5rem;
    width: 25rem;
    line-height: 1.2rem;
    margin-bottom: 1.3rem;
}

.services-essb .header .title .btn-essb {
    margin-top: 3rem;
}

.services-essb .header .title a {
    font-family: Reboto-Black;
    font-size: .8rem;
    padding: .5rem;
    margin-left: 7rem;
    color: #ffffff;
    background-color: #FF214F;
}

@media (max-width: 991px) {
    .services-essb .row {
        --bs-gutter-x: 0rem;
    }

    .services-essb .img img {
        width: 100%;
    }

    .services-essb .header .shape img {
        width: 7rem;
    }

    .services-essb .header .title img {
        width: 2rem;
        margin-left: 5.5rem;
        padding-top: 3.2rem;
    }

    .services-essb .header .title h6 {
        font-size: 1.2rem;
        margin-left: 9.2rem;
        padding-top: 1.2rem;
        margin-bottom: 0rem;
    }

    .services-essb .header .title h4 {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-left: 9.2rem;
        padding-top: .2rem;
        margin-bottom: 3rem;
    }

    .services-essb .header .title p {
        color: #606060;
        font-family: Reboto-Ligth !important;
        font-size: 0.9rem !important;
        margin-left: 5rem;
        width: 19rem;
        line-height: 1.4rem;
        margin-bottom: .9rem;
    }

    .services-essb .header .title .btn-essb {
        margin-top: 1rem;
    }

    .services-essb .header .title a {
        font-size: .8rem;
        padding: .4rem;
        margin-left: 5rem;
    }
}

@media (max-width: 768px) {
    .pt-essb {
        padding-top: 7.5rem;
    }

    .services-essb .img img {
        width: 100%;
    }

    .services-essb .img .top {
        position: absolute;
        width: 1.5rem;
        text-align: end;
        right: 1rem;
        top: -1rem;
    }

    .services-essb .img-bg {
        width: 100%;
        height: 100%;
    }

    .services-essb .header {
        text-align: left;
        display: flex;
        padding-top: 2rem;
    }

    .services-essb .header .shape img {
        position: absolute;
        margin-left: 4rem;
        width: 9.5rem;
    }

    .services-essb .header .title {
        width: 100%;
        text-align: left;
        margin-bottom: 3rem;
    }

    .services-essb .header .title img {
        width: 3.5rem;
        margin-left: 1.2rem;
        padding-top: 3rem;
        position: absolute;
        padding-bottom: 1rem;
    }

    .services-essb .header .title h6 {
        font-size: 1.5rem;
        margin-left: 6rem;
        padding-top: 1rem;
        margin-bottom: 0rem;
        font-weight: bold;
    }

    .services-essb .header .title h4 {
        font-size: 1.8rem;
        line-height: 1.9rem;
        margin-left: 6rem;
        padding-top: .2rem;
        margin-bottom: 4rem;
        font-weight: bold;
    }

    .services-essb .header .title p {
        text-align: center;
        color: #606060;
        font-family: Reboto-Ligth !important;
        font-size: 1.1rem !important;
        margin-left: 0rem;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        line-height: 1.4rem;
        margin-bottom: 1rem;
        text-align: left;
    }

    .services-essb .header .title .btn-essb {
        margin-top: 3rem;
    }

    .services-essb .header .title a {
        font-size: 1rem;
        padding: .8rem;
        margin-left: 1.5rem;
    }
}

/* CIRCLES SERVICES NEW */
.services-essb .step-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10rem;
    padding-top: 7rem;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}

.services-essb .circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0px;
}

.services-essb .circle {
    width: 80px;
    height: 80px;
    border: 1px solid #676766;
    color: #676766;
    font-family: Myriad-Regular;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7rem;
}

.services-essb .step-text {
    text-align: center;
}

.services-essb .step-text h5 {
    font-family: Reboto-Medium;
    color: #606060;
    font-size: 1.2rem;
    margin-top: 1.3rem;
}

.services-essb .step-text p {
    font-family: Reboto-Ligth;
    color: #606060;
    font-size: 1rem !important;
    margin-top: .5rem !important;
    width: 15rem;
    line-height: 1.4rem;
}

.services-essb .dotted-line {
    flex-grow: 1;
    border-top: 1px dashed #676766;
    margin: 50px 10px;
}

@media (max-width: 768px) {
    .services-essb .step-container {
        display: block;
        align-items: flex-start;
        margin-bottom: 9rem;
        padding-top: 2rem;
    }

    .services-essb .circle {
        border: 0.5px solid #676766;
        width: 100px;
        height: 100px;
        font-size: 2rem;
    }

    .services-essb .step-text h5 {
        font-size: 1.7rem;
        margin-top: 1.5rem;
        font-weight: 100;
        padding-top: 1rem;
    }

    .services-essb .step-text p {
        font-size: 1.1rem !important;
        width: 100%;
        line-height: 23px;
        font-weight: 100;
    }
}

/* SOFTWARE SECURITY */
.services-essb .security-container {
    margin-bottom: 7rem;
    padding-top: 7rem;
}

.services-essb .security-container .box {
    padding-left: 3rem;
}

.services-essb .security-container .security-header {
    display: flex;
}

.services-essb .security-container .security-header .number h1 {
    color: #1E283C;
    font-family: Myriad-Bold;
    font-size: 3rem;
    margin-bottom: 0px;
}

.services-essb .security-container .security-header .number hr {
    color: #00ADD8;
    opacity: 1;
    margin-top: 0px !important;
    width: 2.5rem;
}

.services-essb .security-container .title h4 {
    font-family: Myriad-Semibold;
    color: #606060;
    font-size: 1.4rem;
    padding-left: 1rem;
    padding-top: 2.6rem;
}

.services-essb .security-container .description p {
    font-family: Reboto-Ligth;
    color: #606060;
    font-size: 1rem !important;
    line-height: 1.4rem;
    width: 90%;
}

.services-essb .security-container hr {
    margin-top: 5rem !important;
    color: #9D9D9C;
    width: 90%;
}

@media (max-width: 991px) {
    .services-essb .security-container .box {
        padding-left: 0rem;
        padding-top: 2rem;
    }

    .services-essb .security-container .security-header {
        display: flex;
        justify-content: center;
    }

    .services-essb .security-container .security-header .number h1 {
        font-size: 2rem;
    }

    .services-essb .security-container .security-header .number hr {
        width: 2rem;
    }

    .services-essb .security-container .title h4 {
        font-size: 1.2rem;
        padding-left: 1rem;
        padding-top: 1.6rem;
        font-weight: 100;
    }

    .services-essb .security-container .description p {
        width: 80%;
    }

    .services-essb .security-container hr {
        width: 80%;
        margin-bottom: 2rem;
    }
}

@media (max-width: 768px) {
    .services-essb .step-container {
        margin-bottom: 5rem;
        padding-top: 5rem;
    }

    .services-essb .security-container {
        margin-bottom: 0rem;
        padding-top: 0rem;
    }

    .services-essb .security-container .security-header {
        justify-content: left !important;
        margin: 0 auto;
    }

    .services-essb .security-container .box {
        padding-left: 0rem;
        text-align: left;
        margin: 0 auto;
    }

    .services-essb .security-container .security-header {
        display: flex;
        justify-content: center;
    }

    .services-essb .security-container .security-header .number h1 {
        font-size: 6rem;
    }

    .services-essb .security-container .security-header .number hr {
        width: 6rem;
    }

    .services-essb .security-container .title h4 {
        font-size: 2rem;
        padding-left: 1rem;
        padding-top: 4.5rem;
        margin: 0 auto;
        font-weight: 100;
    }

    .services-essb .security-container .description p {
        width: 100%;
        font-size: 1.1rem !important;
        line-height: 1.3rem;
    }

    .services-essb .security-container hr {
        width: 100%;
    }
}

/* SYSTEM CLOUD INTEGRATION */
.services-essb .cloud-container {
    margin-bottom: 7rem;
    padding-top: 7rem;
}

.services-essb .cloud-container .box {
    padding-left: 3rem;
}

.services-essb .cloud-container .box-backend {
    padding-left: 2rem !important;
}

.services-essb .cloud-container .box .content {
    display: flex;
}

.services-essb .cloud-container .box hr {
    margin-top: 1rem !important;
    color: #9D9D9C;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 1rem;
}

.services-essb .cloud-container .box .description {
    text-align: center;
}

.services-essb .cloud-container .box .description img {
    width: 3rem;
    padding-bottom: 1rem;
}

.services-essb .cloud-container .box .description h4 {
    font-family: Myriad-Semibold;
    color: #606060;
    font-size: 1.4rem;
    padding-left: 0rem;
}

.services-essb .cloud-container .box .description p {
    font-family: Reboto-Ligth;
    color: #606060;
    font-size: 1.1rem !important;
    line-height: 1.4rem;
    padding-left: 0rem;
    width: 90%;
}

.services-essb .cloud-container .box .description .backend-p {
    font-family: Reboto-Ligth;
    color: #606060;
    font-size: .8rem !important;
    line-height: 1rem;
    padding-left: 1rem;
    width: 100%;
}

@media (max-width: 991px) {
    .services-essb .cloud-container .box {
        padding-left: 0rem;
        text-align: center;
        margin: 0 auto;
    }

    .services-essb .cloud-container .box-backend {
        padding-left: 0rem !important;
    }

    .services-essb .cloud-container .box hr {
        padding-left: 5%;
        padding-right: 5%;
        width: 90%;
    }

    .services-essb .cloud-container .box .content {
        display: block;
        justify-content: center;
    }

    .services-essb .cloud-container .box .description {
        text-align: center;
        margin: 0 auto;
    }

    .services-essb .cloud-container .box .description h4 {
        font-size: 1.4rem;
        padding-left: 0rem;
        padding-top: 1rem;
    }

    .services-essb .cloud-container .box .description p {
        width: 90%;
        padding-left: 0rem;
    }
}

@media (max-width: 768px) {
    .services-essb .cloud-container {
        margin-bottom: 0rem;
        padding-top: 0rem;
    }

    .services-essb .cloud-container-space {
        margin-top: 3rem;
    }

    .services-essb .cloud-container .box {
        padding-left: 0rem;
        text-align: center;
        margin: 0 auto;
    }

    .services-essb .cloud-container .space {
        padding-bottom: 4rem;
    }

    .services-essb .cloud-container .box hr {
        padding-left: 0%;
        padding-right: 0%;
        width: 100%;
    }

    .services-essb .cloud-container .box .content {
        display: block;
        justify-content: center;
    }

    .services-essb .cloud-container .box .description {
        text-align: center;
        margin: 0 auto;
    }

    .services-essb .cloud-container .box .description img {
        padding-top: 3rem;
    }

    .services-essb .cloud-container .box .description h4 {
        font-size: 2rem;
        padding-left: 0rem;
        padding-top: 1rem;
        font-weight: 100;
    }

    .services-essb .cloud-container .box .description p {
        width: 100%;
        padding-left: 0rem;
        font-size: 1.1rem !important;
        line-height: 1.3rem;
    }

    .services-essb .cloud-container .box .description .backend-p {
        padding-left: 0rem;
        font-size: 1rem !important;
        line-height: 1.3rem;
        padding-bottom: 1rem;
    }
}

.lastes-articles {
    padding-bottom: 3rem;
    padding-top: 5rem;
}

.lastes-articles .container {
    width: 100% !important;
    max-width: 1320px;
}

.lastes-articles .shape {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 2rem;
}

.lastes-articles .shape img {
    width: 8rem;
    margin-top: 3rem;
    margin-left: -2rem;
}

.lastes-articles .shape h5 {
    color: #00add9;
    position: absolute;
    padding-top: 4rem;
    font-size: 1.5rem;
    padding-right: 3rem;
}

.lastes-articles .carousel-item img {
    padding: 0rem;
}

.lastes-articles .box {
    padding: 2rem;
}

.lastes-articles .box .header img {
    width: 100%;
    height: 12rem;
}

.lastes-articles .box .header .rounded {
    border-radius: 20px 20px !important;
    box-shadow: 4px 4px 12px gray;
}

.lastes-articles .box .description {
    margin-top: 1rem;
    display: flex;
    z-index: 2;
}

.lastes-articles .box .description .autor-img img {
    width: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
}

.lastes-articles .box .description .titles h5 {
    color: #000000;
    padding-left: 1rem;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.lastes-articles .box .description .titles p {
    padding-left: 1rem;
    font-size: .9rem !important;
    text-transform: capitalize;
}

.lastes-articles .carousel-inner {
    z-index: 2;
}

.lastes-articles .carousel-control-prev {
    padding-left: 20rem;
    padding-top: 22rem;
    z-index: 1;
    width: 3rem;
    height: 3rem;
}

.lastes-articles .carousel-control-prev span i {
    color: #606060;
    ;
    font-size: 1.5rem;
}

.lastes-articles .carousel-control-next {
    padding-right: 20rem;
    padding-top: 22rem;
    z-index: 1;
    width: 3rem;
    height: 3rem;
}

.lastes-articles .carousel-control-next span i {
    color: #606060;
    ;
    font-size: 1.5rem;
}

.lastes-articles .carousel-indicators {
    margin-bottom: -3rem;
    width: 10%;
    margin-left: 45%;
    margin-right: 45%;
}

.lastes-articles .carousel-indicators button {
    background-color: white;
    border: 2px solid #070707 !important;
    border-radius: 50%;
    height: 10px !important;
    margin: 3px 10px 3px 3px;
    max-height: 10px;
    max-width: 10px;
    opacity: .5;
    transition: all .3s;
    width: 10px !important;
}

.lastes-articles .carousel-indicators .active {
    background-color: #00add9 !important;
}

.lastes-articles .view-more {
    padding: 0px;
    margin-top: 10vh;
    text-align: center;
}

.lastes-articles .view-more .btn {
    background-color: #ff214f !important;
    color: white;
    font-size: .8rem !important;
    width: 25%;
    text-transform: uppercase;
}

@media (max-width: 992px) {
    .lastes-articles .container {
        width: 100% !important;
        max-width: 992px;
    }

    .lastes-articles .box {
        padding: 0rem;
        padding-top: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .lastes-articles .box .header img {
        width: 100%;
        height: 50vh;
    }
}

@media (max-width: 768px) {
    .lastes-articles .container {
        width: 100% !important;
        max-width: 768px;
    }

    .lastes-articles .box {
        padding: 0rem;
        padding-top: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .lastes-articles .box .header img {
        width: 100%;
        height: 40vh;
    }

    .lastes-articles .view-more .btn {
        font-size: .8rem !important;
        width: 60%;
    }
}

@media (max-width: 576px) {
    .lastes-articles .container {
        width: 100% !important;
        max-width: 576px;
    }

    .lastes-articles .box {
        padding: 0rem;
        padding-top: 2rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .lastes-articles .box .header img {
        width: 100%;
        height: 22vh;
    }
}

@media (max-width: 768px) {
    .video-home .container {
        width: 100% !important;
        max-width: 768px;
    }
}

/* ABOUT US NEW VERSION */
.mt-div {
    padding-top: 10rem;
}

.about-us-vision .description {
    padding-left: 4rem;
    position: relative;
}

.about-us-vision .description .title img {
    position: absolute;
}

.about-us-vision .description .title h5 {
    color: #00add9;
    text-transform: capitalize;
    font-size: 1.8rem;
    padding-top: 3rem;
    padding-left: 3rem;
    font-weight: 500;
}

.about-us-vision .description .title h3 {
    text-transform: capitalize;
    font-family: Montserrat-Semibold;
    font-size: 2rem;
    font-weight: 600;
    padding-left: 3rem;
}

.about-us-vision .content {
    padding-top: 2rem;
}

.about-us-vision .content p {
    font-size: 1.1rem !important;
}

.about-us-vision .image {
    padding-right: 4rem;
}

.about-us-vision .image img {
    width: 25rem;
}

@media (max-width: 992px) {
    .about-us-vision .description {
        padding-left: 0rem;
        position: relative;
        text-align: center;
    }

    .about-us-vision .description .title img {
        left: 35%;
    }

    .about-us-vision .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0;
    }

    .about-us-vision .image img {
        width: 25rem;
        padding-bottom: 3rem;
    }

    .about-us-vision .description .title h5 {
        padding-left: 0rem;
    }

    .about-us-vision .description .title h3 {
        padding-left: 0rem;
    }

    .about-us-vision .content p {
        line-height: 1.5rem !important;
    }
}

@media (max-width: 768px) {
    .about-us-vision .image img {
        width: 20rem;
        padding-bottom: 3rem;
    }
}

@media (max-width: 576px) {
    .about-us-vision .image img {
        width: 16rem;
        padding-bottom: 3rem;
    }
}

/* MISION */
.about-us-mission {
    padding-top: 7rem;
    padding-bottom: 3rem;
    text-align: center;
    width: 60%;
    margin: 0 auto;
}

.about-us-mission h3 {
    text-transform: capitalize;
    font-family: Montserrat-Semibold;
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

.about-us-mission p {
    font-size: 1.1rem !important;
    line-height: 1.4rem !important;
}

.about-us-mission .btn-request {
    margin-top: 3rem;
}

.about-us-mission .btn-request a {
    background-color: #FF214F !important;
    font-family: Reboto-Black;
    font-size: 1rem;
    padding: .5rem .9rem;
    color: #ffffff;
}

.about-we-create .create-content {
    width: 60%;
    margin: 0 auto;
    text-align: center;
}

.about-we-create .create-content h3 {
    text-transform: capitalize;
    font-family: Montserrat-Semibold;
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 3rem;
}

.about-we-create img {
    width: 100%;
}

@media (max-width: 992px) {
    .about-us-mission {
        padding-top: 5rem;
        width: 100%;
    }

    .about-us-mission .btn-request {
        margin-top: 5rem;
    }

    .about-us-mission .btn-request a {
        font-size: 1.5rem;
        padding: 1rem 2rem;
    }

    .about-we-create .create-content {
        padding-top: 1rem;
        width: 100%;
    }

    .about-us-mission p {
        font-size: 1.1rem !important;
        line-height: 1.5rem !important;
    }
}

@media (max-width: 768px) {}

/* HISTORY */
.about-history {
    padding-top: 5rem;
    background-color: #f2f2f2;
}

.about-history .title {
    text-align: center;
    width: 90%;
    margin: 0 auto;
    padding-bottom: .5rem;
}

.about-history .title h3.title {
    color: #00add9;
    text-transform: capitalize;
    font-family: Montserrat;
    font-size: 1.8rem;
    font-weight: 500;
}

.about-history .title h3.subTitle {
    text-transform: capitalize;
    font-family: Montserrat-Semibold;
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 1rem;
}

.about-history .title p {
    color: #606060;
    text-transform: none;
    font-family: Montserrat;
    font-size: 1.1rem !important;
    font-weight: 400;
    line-height: 1.5rem;
    padding-bottom: 3rem;
}

@media (max-width: 992px) {
    .about-history {
        padding-top: 5rem;
        background-color: white;
    }

    .about-history .title {
        width: 90%;
        padding-bottom: 0rem;
    }

    .about-history .title p {
        line-height: 1.3rem !important;
        font-size: 1.1rem !important;
    }
}

.about-history .founders {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 5rem;
}

.about-history .founders .c-m {
    background-image: url('../img/about/background.webp');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position: center;
    padding: 2.5rem 3rem;
    border-radius: 25px;
    height: 20rem;
}

.about-history .founders .c-m p {
    color: #606060;
    text-transform: none;
    font-family: Montserrat;
    font-size: 1.1rem !important;
    font-weight: 300;
    padding-bottom: 1rem;
    line-height: 1.4rem;
    text-align: center;
    height: 9rem;
}

.about-history .founders .box-shadow {
    border-radius: 8px;
    box-shadow: 4px 4px 20px lightgray;
}

.about-history .founders .c-m .profile {
    display: flex;
}

.about-history .founders .c-m .profile .avatar img {
    width: 6rem;
}

.about-history .founders .c-m .profile .description {
    padding-top: 1rem;
    padding-left: 1rem;
}

.about-history .founders .c-m .profile .description h3 {
    text-transform: capitalize;
    font-family: Montserrat-Semibold;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: .2rem;
}

.about-history .founders .c-m .profile .description h4 {
    color: #00add9;
    text-transform: capitalize;
    font-family: Montserrat;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: .2rem;
}

.about-history .founders .c-m .profile .description img {
    width: 10rem;
}

@media (max-width: 992px) {
    .about-history .founders {
        background-color: #f2f2f2;
    }

    .about-history .founders .row {
        --bs-gutter-x: 0rem;
    }

    .about-history .founders .c-m {
        padding: 3rem 3rem;
        height: 18rem;
        width: 80%;
        margin: 0 auto;
        margin-top: 3rem;
    }

    .about-history .founders .c-m .profile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .about-history .founders .c-m p {
        height: 5rem;
    }
}

@media (max-width: 768px) {
    .about-history .founders .c-m {
        padding: 3rem 3rem;
        height: 20rem;
        width: 90%;
        margin: 0 auto;
        margin-top: 3rem;
    }

    .about-history .founders .c-m .profile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .about-history .founders .c-m p {
        height: 7rem;
    }
}

@media (max-width: 576px) {
    .about-history .founders .c-m {
        padding: 2rem 2rem;
        height: 23rem;
        width: 90%;
        margin: 0 auto;
        margin-top: 3rem;
    }

    .about-history .founders .c-m .profile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .about-history .founders .c-m p {
        height: 12rem;
    }

    .about-history .founders .c-m .profile .description h3 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: .2rem;
    }

    .about-history .founders .c-m .profile .description h4 {
        font-size: .7rem;
        font-weight: 500;
        margin-bottom: .2rem;
    }

    .about-history .founders .c-m .profile .description img {
        width: 7rem;
    }
}

.about-start {
    background-color: white;
    padding-top: 5rem;
    padding-bottom: 7rem;
}

.about-start .content {
    text-align: center;
    width: 54%;
    margin: 0 auto;
}

.about-start .content h3 {
    text-transform: capitalize;
    font-family: Montserrat;
    font-size: 2rem;
    font-weight: 800;
    padding-bottom: 3rem;
}

.about-start .content .btn-request {
    margin-top: 0rem;
}

.about-start .content .btn-request a {
    background-color: #FF214F !important;
    font-family: Reboto-Black;
    font-size: 1rem;
    padding: .5rem .9rem;
    color: #ffffff;
}

@media (max-width: 992px) {
    .about-start .content {
        text-align: center;
        width: 90%;
        margin: 0 auto;
    }

    .about-start .content .btn-request {
        margin-top: 0rem;
    }

    .about-start .content .btn-request a {
        font-size: 1.5rem;
        padding: 1rem 2rem;
    }
}